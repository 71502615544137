import React, { Component } from 'react';
import api from '../../services/api';
import DateTimePicker from 'react-datetime-picker';
import validator from 'validator';
import { Button, Form, Input } from 'reactstrap';
import './CreateTicket.css';
import axios from 'axios';
import Select from 'react-select';
import { ToastConsumer } from 'react-awesome-toasts';
import Switch from 'react-input-switch';
import { ModalAlert } from '../../components';

class CreateTicket extends Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputClient = this.onChangeInputClient.bind(this);
    this.onChangeInputContact = this.onChangeInputContact.bind(this);
    this.onChangeInputUsers = this.onChangeInputUsers.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.toggleModalAlert = this.toggleModalAlert.bind(this);
    
    this.state = {
      cliente: null,
      contato: null,
      tipo: 'Suporte',
      assunto: '',
      descricao: '',
      usuario: null,
      prioridade: 'Baixa',
      dataAgenda: '',
      notificarCliente: 0,
      notificarAberturaTicket: 1,
      situacao: 0,
      clientes: [], //LISTA DE CLIENTES
      usuarios: [], // LISTA DE USUÁRIOS
      contatos: [], // LISTA DE CONTATOS APÓS SELECIONAR O CLIENTE
      date: new Date(),
      agendar: 0,
      modalAlert: false,
      errors: {
        cliente: false,
        contato: false,
        assunto: false,
        descricao: false
      },
      telaProcesso: "",
      modulo: null
    }
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    await this.loadClients();
    await this.loadUsers();
  }

  async loadClients() {
    await axios.get(`${this.props.getBaseUrlApi()}/clientes`, this.props.parameters())
      .then(response => {
        let clientes = response.data.clientes;
        clientes = clientes.map(cliente => {
          cliente.descricao = cliente.fantasia;
          cliente.value = cliente.descricao;
          cliente.label = cliente.descricao;
          return cliente;
        });
        this.setState({ clientes: clientes });
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  async loadUsers() {
    await axios.get(`${this.props.getBaseUrlApi()}/usuarios`, this.props.parameters())
      .then(response => {
        let users = response.data.usuarios;
        users = users.map(user => {
          user.value = user.descricao;
          user.label = user.descricao;
          return user;
        });
        users.unshift({ label: 'NÃO VINCULAR', value: 'NÃO VINCULAR', id: 0 });
        this.setState({ usuarios: users, usuario: users[0] })
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeInputClient(cliente) {
    let client = Object.assign({}, cliente);
    client.contatos = client.contatos.map(contato => {
      contato.value = contato.descricao + ' - ' + contato.fone;
      contato.label = contato.descricao + ' - ' + contato.fone;
      return contato;
    });

    let tipoAtendimento = this.state.tipo;

    if(tipoAtendimento === 'Suporte'){     
      let contatosFranquia = client.contatos.filter(c => c.usaFranquia === true);
      client.contatos = [];
      client.contatos = contatosFranquia;
    }

    this.setState({
      cliente: client,
      contatos: client.contatos,
      contato: client.contatos[0]
    });
  }

  onChangeInputContact(contact) {
    this.setState({
      contato: contact
    });
  }

  onChangeInputUsers(user) {
    this.setState({
      usuario: user
    });
  }

  onChangeInput(e) {
    let value = e.target.value
    if(e.target.id === 'tipo'){
      let tipoAtendimento = e.target.value;
      if(this.state.cliente !== null){
        
        let idCliente = this.state.cliente.id;
        let client = Object.assign({}, this.state.clientes.find((c) => c.id === idCliente));

        if(tipoAtendimento === 'Suporte'){
          client.contatos = client.contatos.filter(contato => contato.usaFranquia === true);
        }

        if(client.contatos.length){
          this.setState({contato : client.contatos[0]});
        }else{
          this.setState({contato : null});
        }
        this.setState({cliente: client, contatos: client.contatos});        
      }  
    }else if(e.target.id === 'modulo'){
      if(value === 'null'){
        value = null;
      }
    }

    this.setState({
      [e.target.id]: value
    });
  }

  async validate() {
    let isValid = true;
    let errors = this.state.errors;

    if (this.state.cliente === null) {
      errors.cliente = true;
      this.setState({errors});
      return false;
    }else{
      errors.cliente = false;
    }

    if (!this.state.cliente.descricao) {
      errors.cliente = true;
      isValid = false;
    }else{
      errors.cliente = false;
    }

    if (!this.state.contato.descricao) {
      errors.contato = true;
      isValid = false;
    }else{
      errors.contato = false;
    }

    if (validator.isEmpty(this.state.assunto, [{ ignore_whitespace: true }])) {
      errors.assunto = true;
      isValid = false;
    }else{
      errors.assunto = false;
    }

    if (validator.isEmpty(this.state.descricao, [{ ignore_whitespace: true }])) {
      errors.descricao = true;
      isValid = false;
    }else{
      errors.descricao = false;
    }

    if (this.state.notificarAberturaTicket === 1) {
      if(this.state.contato.email === ''){
        isValid = false;
        await this.setState({modalAlert : true});
      }      
    }

    await this.setState({ errors });
    return isValid;
  }

  async onSubmit({ action, toast }) {
    const toastProps = {
      text: 'Ticket criado com sucesso',
      actionText: 'Fechar',
      ariaLabel: 'Ticket criado com sucesso, clique para fechar'
    };

    let isValid = await this.validate();
    if (!isValid) return false;

    let localClient = this.state.cliente;
    delete localClient.contatos;
    delete localClient.type;
    delete localClient.cargo;
    delete localClient.login;
    delete localClient.senha;
    delete localClient.status;
    delete localClient.type;

    let localUser = this.state.usuario;
    delete localUser.label;
    delete localUser.value;

    const objectToSend = {
      cliente: localClient,
      contato: this.state.contato,
      tipo: this.state.tipo,
      assunto: this.state.assunto,
      descricao: this.state.descricao,
      usuario: localUser.id !== 0 ? localUser : null,
      prioridade: this.state.prioridade,
      dataAgenda: this.state.agendar === 1 ? this.state.date : null,
      notificarCliente: this.state.notificarCliente == 0 ? false : true,
      notificarAberturaTicket: this.state.notificarAberturaTicket == 0 ? false : true,
      situacao: this.state.agendar === 1 ? 1 : this.state.situacao,
      telaProcesso: this.state.telaProcesso,
      modulo: this.state.modulo      
    };
    
    await api.post('tickets', objectToSend, this.props.parameters()).then(res => {

      toast.show(({ ...toastProps, onActionClick: toast.hide }));

      switch (action) {
        case 'save':
          this.props.history.push('/tickets');
          break;

        case 'saveAndStart':
          this.props.history.push(`/tickets/detail/${res.data.id}`);
          break;

        case 'saveAndNew':
          this.props.history.push(`/tickets/create`);

          this.setState({
            cliente: {},
            contato: {},
            tipo: 'Suporte',
            assunto: '',
            descricao: '',
            usuario: null,
            prioridade: 'Baixa',
            dataAgenda: '',
            notificarCliente: 1,
            notificarAberturaTicket: 1,
            situacao: 0,
            clientes: [], //LISTA DE CLIENTES
            usuarios: [], // LISTA DE USUÁRIOS
            contatos: [], // LISTA DE CONTATOS APÓS SELECIONAR O CLIENTE
            date: new Date(),
            errors: {
              cliente: false,
              contato: false,
              assunto: false,
              descricao: false
            },
            agendar: 0
          })

          this.loadData();

          break;

        default:
          break;
      }
    });
  }

  onChange = date => this.setState({ date });

  toggleModalAlert() {
    this.setState({
      modalAlert: !this.state.modalAlert
    });
  }

  render() {
    return (
        <div id="contentSite">
          <div className="container">

            <div className="Example_box">
              <div className="Example_box-content">

            <div className="offset-sm-1 col-sm-9" style={{paddingTop: '15px'}}>
              <Form>

                <div className="form-row">
                  
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4" className="label-form">
                      Cliente:
                    </label>
                    <Select
                      value={this.state.cliente}
                      onChange={this.onChangeInputClient}
                      options={this.state.clientes}
                      focus={false}
                    />
                    {this.state.errors.cliente === true && 
                      <div className="is-invalid-feedback">
                        Por favor selecione um cliente.
                      </div>
                    }
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4" className="label-form">
                      Tipo:
                    </label>
                    <select className="form-control" id="tipo" value={this.state.tipo} onChange={this.onChangeInput}>
                      <option value="Suporte">Suporte</option>
                      <option value="Atendimento Presencial">Atendimento Presencial</option>
                      <option value="Atendimento Remoto">Atendimento Remoto</option>
                      <option value="Atendimento Não Franqueado">Atendimento Não Franqueado</option>
                      <option value="Atendimento Horário Especial">Atendimento Horário Especial</option>
                      <option value="Desenvolvimento Sob Medida">Desenvolvimento Sob Medida</option>
                    </select>
                  </div>
                
                </div>

                <div className="form-row">

                  <div className="form-group col-md-6">
                    <label htmlFor="exampleEmail" className="label-form">
                      Contato:
                    </label>

                    {this.state.contatos.length ?
                      <Select
                        value={this.state.contato}
                        onChange={this.onChangeInputContact}
                        options={this.state.contatos}
                      />
                      
                    :
                      <select className="form-control" id="contato" disabled={true}>

                      </select>
                    }
                    {this.state.errors.contato && 
                      <div className="is-invalid-feedback">
                        Por favor selecione um contato.
                      </div>
                    }
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label htmlFor="exampleEmail" className="label-form">
                      Assunto:
                    </label>
                    
                    <Input
                      type="text"
                      id="assunto"
                      value={this.state.assunto}
                      onChange={this.onChangeInput}
                      className={this.state.errors.assunto ? 'is-invalid': ''}
                    />

                    {this.state.errors.assunto && 
                      <div className="is-invalid-feedback">
                        Por favor digite um assunto.
                      </div>
                    }
                  </div>

                </div>

                <div className="form-row">
                
                  <div className="form-group col-md-6">
                    <label htmlFor="exampleEmail" className="label-form">
                      Usuário Atendimento:
                    </label>                        
                    <Select
                      value={this.state.usuario}
                      onChange={this.onChangeInputUsers}
                      options={this.state.usuarios}
                    />                        
                  </div>

                  {/* <div className="form-group col-md-6">
                    <label htmlFor="exampleEmail" className="label-form">
                      Tela/Processo:
                    </label>                        
                    <Input
                      type="text"
                      id="telaProcesso"
                      value={this.state.telaProcesso}
                      onChange={this.onChangeInput}
                    />                     
                  </div> */}

                  {/* <div className="form-group col-md-6">
                    <label htmlFor="exampleEmail" className="label-form">
                      Prioridade:
                    </label>                         
                    <select className="form-control" id="prioridade" 
                      value={this.state.prioridade} 
                      onChange={this.onChangeInput}
                    >
                      <option value="Baixa">Baixa</option>
                      <option value="Média">Média</option>
                      <option value="Alta">Alta</option>
                    </select>
                  </div> */}

                </div>

                {/* <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="exampleEmail" className="label-form">
                    Módulo Priorize: 
                    </label>                        
                    <select
                              className="form-control"
                              id="modulo"
                              value={this.state.modulo}
                              onChange={this.onChangeInput}
                            >
                              <option value="null">Selecione</option>
                              <option value="Básico">Básico</option>
                              <option value="Comercial">Comercial</option>
                              <option value="CRM">CRM</option>
                              <option value="Estoque">Estoque</option>
                              <option value="Faturamento">Faturamento</option>
                              <option value="Fiscal">Fiscal</option>
                              <option value="Financeiro">Financeiro</option>
                              <option value="PCP">PCP</option>
                              <option value="Suprimentos">Suprimentos</option>
                              <option value="WMS">WMS</option>

                            </select>                 
                  </div>

                </div> */}

                <div className="form-row">

                  <div className="form-group  col-md-6">

                    <div className="col-md-6 pull-left">
                      <label htmlFor="exampleEmail" className="label-form">Notificar Abertura:</label>
                      <br/>
                      <Switch
                        value={this.state.notificarAberturaTicket}
                        onChange={notificarAberturaTicket => this.setState({ notificarAberturaTicket })}
                      />
                    </div>

                    <div className="col-md-6 pull-left">
                      <label htmlFor="exampleEmail" className="label-form">Agendar:</label>   
                      <br/>                  
                      <Switch
                        value={this.state.agendar}
                        onChange={agendar => this.setState({ agendar : parseInt(agendar) })}
                      />
                    </div>
                  
                  </div>
                    

                  {this.state.agendar === 1 &&

                    <div className="form-group">
                      <label htmlFor="exampleEmail" className="label-form">Agendar:</label>
                      <br/>
                      <DateTimePicker
                        onChange={this.onChange}
                        value={this.state.date}
                        style={{ margin: '10px!important' }}
                      />
                    </div>
                  }

                </div>

                <div className="form-row">                      
                  <div className="form-group col-md-12">
                    <label htmlFor="exampleEmail" className="label-form">
                      Descrição:
                    </label>                         
                    <textarea
                      id="descricao"
                      value={this.state.descricao}
                      onChange={this.onChangeInput}
                      rows={6}
                      className={this.state.errors.descricao ? 'form-control is-invalid' : 'form-control'}
                    />
                    {this.state.errors.descricao && 
                      <div className="is-invalid-feedback">
                        Por favor digite uma descrição.
                      </div>
                    }
                  </div>
                </div>


                  <div className="form-group col-sm-12" className="div-center">
                    <ToastConsumer>
                      {
                        ({ show, hide }) => (
                          <Button 
                            color="success" 
                            className="button-novo-ticket" 
                            type="button" 
                            style={{ margin: '5px' }} 
                            onClick={() => this.onSubmit({ action: 'save', toast: { show, hide } })}
                          >
                            <i className="fa fa-floppy-o" aria-hidden="true"></i> {' '} 
                            Salvar
                          </Button>
                        )
                      }
                    </ToastConsumer>

                    <ToastConsumer>
                      {
                        ({ show, hide }) => (
                          <Button 
                            color="success" 
                            className="button-novo-ticket" 
                            type="button" style={{ margin: '5px' }} 
                            onClick={() => this.onSubmit({ action: 'saveAndStart', toast: { show, hide } })}
                          >
                            <i className="fa fa-floppy-o" aria-hidden="true"></i>{' '} 
                            Salvar/Iniciar
                          </Button>
                        )
                      }
                    </ToastConsumer>

                    <ToastConsumer>
                      {
                        ({ show, hide }) => (
                          <Button 
                            color="success" 
                            className="button-novo-ticket" 
                            type="button" 
                            style={{ margin: '5px' }} 
                            onClick={() => this.onSubmit({ action: 'saveAndNew', toast: { show, hide } })}
                          >
                            <i className="fa fa-floppy-o" aria-hidden="true"></i> {' '} 
                            Salvar/Novo
                          </Button>
                        )
                      }
                    </ToastConsumer>

                    <Button 
                      className="button-novo-ticket" 
                      type="button" 
                      style={{ margin: '5px' }} 
                      onClick={() => this.props.history.push('/home')}
                    >
                      <i className="fa fa-arrow-circle-o-left" aria-hidden="true"></i> {' '} 
                      Voltar
                    </Button>
                  </div>
              </Form>

              <ModalAlert
                modal={this.state.modalAlert}
                toggle={this.toggleModalAlert}
                text={'Ao escolher a opção "Notificar Abertura de Ticket" o contato do ticket deverá possuir um email cadastrado'}
              />
            </div>
            </div>
            </div>
          </div>
        </div>
    )
  }
}

export default CreateTicket;