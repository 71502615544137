import React from 'react';
import { Button, FormGroup, Label, Form, Input, Col, Row } from 'reactstrap';
import TimeField from 'react-simple-timefield';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const AdicionarApontamento = ({ onChangeDate, onSubmitApontamento, apontamento, onChangeEstoria, estorias, onChangeInputNewApontamento, onChangeDateTime, contatos, onChangeContatoNovo, ...props }) => {
  let now = new Date();
  if (apontamento.horaInicial === "") {
    apontamento.horaInicial = now.toLocaleTimeString();
    onChangeDateTime(apontamento.horaInicial, 'horaInicial');
  }
  if (apontamento.horaFinal === "") {
    apontamento.horaFinal = now.toLocaleTimeString();
    onChangeDateTime(apontamento.horaFinal, 'horaFinal');
  }

  estorias = estorias.map(estoria => {
    estoria.value = `${estoria.descricao}`;
    estoria.label = `${estoria.descricao}`;
    return estoria;
  })

  return (
    <div>
      <Form onSubmit={onSubmitApontamento}>

        <FormGroup>
          <Row>
            <Col sm={4}>
              <Label className="label-form">Data:</Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                customInput={<Input />}
                selected={apontamento.data}
                onChange={onChangeDate}
              />
             
            </Col>
            <Col sm={4}>
              <Label className="label-form">Início:</Label>
              <TimeField
                value={apontamento.horaInicial}
                onChange={(date) => onChangeDateTime(date, 'horaInicial')}
                input={<Input />}
                colon=":"
                showSeconds={false}
                className={props.errors.horaInicial ? 'is-invalid' : ''}
              />
              {props.errors.horaInicial && 
                <div className="is-invalid-feedback">
                  Data inválida.
                </div>
              }
            </Col>
            <Col sm={4}>
              <Label className="label-form">Fim:</Label>
              <TimeField
                value={apontamento.horaFinal}
                onChange={(date) => onChangeDateTime(date, 'horaFinal')}
                input={<Input />}
                className={props.errors.horaFinal ? 'is-invalid' : ''}
              />
              {props.errors.horaFinal && 
                <div className="is-invalid-feedback">
                  Data inválida.
                </div>
              }
            </Col>
          </Row>
        </FormGroup>
        
        <FormGroup>
          <Label className="label-form">Tipo de Estória</Label>
          <Select
            value={apontamento.tipoEstoria}
            onChange={onChangeEstoria}
            options={estorias}
          />
          {props.errors.tipoEstoria && 
            <div className="is-invalid-feedback">
              Selecione o tipo de estória.
            </div>
          }
        </FormGroup>

        <FormGroup>
          <Label className="label-form">Contatos</Label>
          <Select
            value={apontamento.contato}
            onChange={onChangeContatoNovo}
            options={contatos}
          />
          {props.errors.contato && 
            <div className="is-invalid-feedback">
              Selecione o contato.
            </div>
          }
        </FormGroup>

        <FormGroup>
          <Label className="label-form">Descrição</Label>
          <textarea 
            onChange={onChangeInputNewApontamento} 
            rows={5} 
            id="descricao" 
            className={props.errors.descricao ? 'form-control is-invalid' : 'form-control'}
            value={apontamento.descricao}>
            

          </textarea>
          {props.errors.descricao && 
            <div className="is-invalid-feedback">
              Informe uma descrição.
            </div>
          }
        </FormGroup>


        <FormGroup>
          <Button color="success" type="submit"><i className="fa fa-floppy-o" aria-hidden="true"></i> Salvar</Button>
        </FormGroup>
      </Form>
    </div>
  );
}

export default AdicionarApontamento;