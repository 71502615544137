import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Cronograma, CreateTicket, DetailTicket, Login, Home, EditTicket, Requisito, Home1, Historico, Notificacao, Config, DetailPreTicket, EditPreTicket } from './pages';
import { isAuthenticated, getToken, logout, getUser } from './auth';
import Header from './components/Header';
import { ToastProvider } from 'react-awesome-toasts';
require('dotenv').config();

const getParametersApi = () => {
  return {
    headers: {
      'x-access-token': getToken()
    }
  };
}

const getBaseUrlApi = () => { 
  if(window.location.href.search('sac.priorize') !== -1) {     
    return process.env.REACT_APP_DEV_API_URL;
  }else{
    return process.env.REACT_APP_DEV_API_URL_LOCAL;
  } 
}

const handlingErrorsApi = (error, props) => {
  if(error.response){
    if(error.response.status === 401){
      logout();
      props.history.push('/login');
    }else if(error.response.status === 422){
      alert(error.response.data.erro);
    }else if(error.response.status === 500){
      alert(error.response.data.erro);
    }
  }
}

const isAtendimento = () => {
  const usuarioString = getUser();
  const usuario = JSON.parse(usuarioString);
  let isAtendimento = usuario.isAtendimento;
  return isAtendimento;
}

const DashboardComponent = (props) => {
  return (
    isAtendimento() ? (
      <div>
        <Home {...props} />
      </div>
    ) : (
      <Home1 {...props} />
        )    
    
  )
}

const PrivateRoute = ({ component: Component, itemActive, titulo, icone = null, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <div>

            <Header
              icone={icone}
              titulo={titulo}
              itemActive={itemActive}
              getBaseUrlApi={getBaseUrlApi}
              parameters={getParametersApi}
              handlingErrorsApi={handlingErrorsApi}            
            />

            <Component {...props}
              parameters={getParametersApi}
              user={getUser}
              getBaseUrlApi={getBaseUrlApi}
              handlingErrorsApi={handlingErrorsApi}
            />
          </div>
        ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          )
      }
    />
  )
}

const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
        ) : (
            <Component {...props} parameters={getParametersApi} getBaseUrlApi={getBaseUrlApi} />
          )
      }
    />
  )
}

const LogoutRoute = ({ component: Component, ...rest }) => {
  logout();
  return (
    <Route
      {...rest}
      render={props =>
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      }
    />
  )
}

const NoMatch = ({ location }) => (
  <div>
    <center>
      <h3>404! <br />essa página não existe</h3>
    </center>
  </div>
);

const App = (props) => {
  return (
    <ToastProvider timeout={3000} position={'bottom-right'}>
      <BrowserRouter>
        <Switch>
          <LoginRoute path="/login" exact component={Login}/>
          <LogoutRoute path="/logout" exact component={Login} />
          <PrivateRoute path="/" exact component={DashboardComponent}  itemActive="home"/>
          <PrivateRoute path="/home" exact component={DashboardComponent}  itemActive="home"/>
          <PrivateRoute path="/consultoria" exact component={Home1}  itemActive="home"/>
          <PrivateRoute path="/tickets" exact component={Cronograma}  itemActive="cronograma"/>
          <PrivateRoute path="/tickets/historico" exact component={Historico}  itemActive="fa-history"/>
          <PrivateRoute path="/tickets/create" exact component={CreateTicket}  itemActive="ticketNovo"/>
          <PrivateRoute path="/tickets/detail/:id" exact component={DetailTicket} />
          <PrivateRoute path="/pre-tickets/detail/:id" exact component={DetailPreTicket} />
          <PrivateRoute path="/tickets/edit/:id" exact component={EditTicket} />
          <PrivateRoute path="/pre-tickets/edit/:id" exact component={EditPreTicket} />
          <PrivateRoute path="/requisitos/novo" exact component={Requisito}  itemActive="requisitoNovo"/>
          <PrivateRoute path="/notificacoes" exact component={Notificacao}  itemActive="notificacao"/>
          <PrivateRoute path="/config1" exact component={Config}  itemActive="config"/>
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
