import React, { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";

const Header = props => {
  const defineTipoIcone = tipo => {
    let icone = "";

    if (tipo === "novoComentario") {
      icone = "fa-commenting";
    } else if (tipo === "contestacaoAvaliada") {
      icone = "fa-exclamation-circle";
    } else if (
      tipo === "requisitoAprovacao" ||
      tipo === "requisitoCancelamento"
    ) {
      icone = "fa-list-ul";
    } else {
      icone = "fa-ticket";
    }
    return icone;
  };

  let notificacoesStorage = localStorage.getItem("notificacoes");
  let existeNotificacaoNovaStorage = localStorage.getItem("existeNotificacaoNova");

  if (notificacoesStorage === null) {
    notificacoesStorage = [];
    localStorage.setItem("notificacoes", JSON.stringify([]));
  } else {
    notificacoesStorage = JSON.parse(notificacoesStorage);
  }

  if (existeNotificacaoNovaStorage === null) {
    existeNotificacaoNovaStorage = false;
    localStorage.setItem("existeNotificacaoNova", false);
  } else {
    existeNotificacaoNovaStorage = JSON.parse(existeNotificacaoNovaStorage);
  }

  const [notificacoes, setNotificacoes] = useState(notificacoesStorage);
  const [existeNotificacaoNova, setExisteNotificacaoNova] = useState(
    existeNotificacaoNovaStorage
  );

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(`${props.getBaseUrlApi()}/notificacoes/novas`, props.parameters())
        .then(response => {
          let notificacoes = response.data.notificacoes;
          let existeNotificacaoNova = response.data.existeNotificacaoNova;

          for (let notificacao of notificacoes) {
            notificacao.icone = defineTipoIcone(notificacao.tipo);
          }

          setNotificacoes(notificacoes);
          setExisteNotificacaoNova(existeNotificacaoNova);
          localStorage.setItem("notificacoes", JSON.stringify(notificacoes));
          localStorage.setItem(
            "existeNotificacaoNova",
            JSON.stringify(existeNotificacaoNova)
          );
        })

        .catch(err => props.handlingErrorsApi(err, this.props));
    }, 60000); // 1minuto
    return () => clearInterval(interval);
  }, []);

  const setNotificacoesVisualizadas = () => {
    setExisteNotificacaoNova(false);
    localStorage.setItem(
      "existeNotificacaoNova",
      JSON.stringify(false)
    );
  }

  return (
    <React.Fragment>
      <NavBar
        icone={props.icone}
        titulo={props.titulo}
        itemActive={props.itemActive}
        getBaseUrlApi={props.getBaseUrlApi}
        parameters={props.getParametersApi}
        handlingErrorsApi={props.handlingErrorsApi}
        existeNotificacao={props.existeNotificacao}
        notificacoes={notificacoes}
        existeNotificacaoNova={existeNotificacaoNova}
        setNotificacoesVisualizadas={setNotificacoesVisualizadas}
      ></NavBar>
      <SideBar
        icone={props.icone}
        titulo={props.titulo}
        itemActive={props.itemActive}
        getBaseUrlApi={props.getBaseUrlApi}
        parameters={props.getParametersApi}
        handlingErrorsApi={props.handlingErrorsApi}
        existeNotificacao={props.existeNotificacao}
        existeNotificacaoNova={existeNotificacaoNova}
        setNotificacoesVisualizadas={setNotificacoesVisualizadas}
      ></SideBar>
    </React.Fragment>
  );
};

export default Header;
