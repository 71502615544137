import React, { Component } from 'react';
import { Button, Alert } from 'reactstrap';
import axios from 'axios';
import Switch from 'react-input-switch';
import '../../login.css';

class Login extends Component {
  constructor() {
    super();
    this.onChangeInput = this.onChangeInput.bind(this);
    this.login = this.login.bind(this);
    this.state = {
      login: '',
      password: '',
      keepLogged: 0,
      error: false
    }
  }

  onChangeInput(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [e.target.id]: value
    });
  }

  async login(e) {
    let localLogin = this.state.login;
    localLogin = localLogin.toUpperCase();
    let toSend = {
      login: localLogin,
      password: this.state.password,
      keepLogged: true
    };

    toSend.keepLogged = true;

    await axios.post(`${this.props.getBaseUrlApi()}/login`, toSend).then(async (res) => {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      this.props.history.push('/home')
    }).catch((err) => {
      err = JSON.stringify(err);
      let response = JSON.parse(err);
      response = response.response;
      if (response.status === 401 || response.status === 422) {
        this.setState({ error: true });
        alert('Usuário ou senha inválido!');
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div id="login_container" style={ {   backgroundImage: `url("./images/inicial.jpg")`,   }}>
          <div className="filter" style={{ "position": "absolute", "backgroundColor": "black", "width": "100%", "height": "100%", "opacity": "0.8" }}></div>          
          <div className="container">
            <div className="card card-container background-card" style={{ backgroundColor: 'transparent' }}>
              <center>
            	  <img className="img-responsive" src="./images/logo.png" width="250" alt="logo"/>
              </center>
              <p id="profile-name" className="profile-name-card"></p>
              <form className="form-signin" onSubmit={(e) => {
                e.preventDefault();
                this.login();
              }}>               
                <input type="text" id="login" className="form-control" placeholder="LOGIN" onChange={this.onChangeInput} value={this.state.login} required autofocus style={{backgroundColor: 'transparent'}}/>
                <input type="password" id="password" className="form-control" placeholder="SENHA" onChange={this.onChangeInput} value={this.state.password} required style={{backgroundColor: 'transparent'}}/>
                <button 
                  className="btn btn-signin-sac btn-lg btn-primary btn-block btn-signin" 
                  style={{"background-color" : "#d40e0e!important", "borderRadius": "50px", 
                    "background-image": "linear-gradient(to right, #fecd00, #fbba00, #f59d00)", 
                    "cursor": "pointer", "height": "50px", color:'#333'}}                  
                  type="submit"
                >ACESSAR</button>
              </form>            
            </div>
          </div>
        </div>
      
       
      </React.Fragment>
    );
  }
}

export default Login;