import React, { Component } from 'react';
import api from '../../services/api';
import { AdicionarApontamento, EditarApontamento, ListaApontamentos, ModalConfirm } from '../../components';
import validator from 'validator';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { ToastConsumer } from 'react-awesome-toasts';

class Apontamento extends Component {
  constructor(props) {
    super(props);
    this.loadEstorias = this.loadEstorias.bind(this);
    this.onSubmitApontamento = this.onSubmitApontamento.bind(this);
    this.onChangeEstoria = this.onChangeEstoria.bind(this);
    this.editarApontamento = this.editarApontamento.bind(this);
    this.excluirApontamento = this.excluirApontamento.bind(this);
    this.onChangeInputNewApontamento = this.onChangeInputNewApontamento.bind(this);
    this.toggleEditar = this.toggleEditar.bind(this);
    this.onChangeEditarApontamento = this.onChangeEditarApontamento.bind(this);
    this.onChangeDateTime = this.onChangeDateTime.bind(this);
    this.onChangeDateTimeEditar = this.onChangeDateTimeEditar.bind(this);
    this.onChangeContatoEditar = this.onChangeContatoEditar.bind(this);
    this.onChangeEstoriaEditar = this.onChangeEstoriaEditar.bind(this);
    this.onChangeContatoNovo = this.onChangeContatoNovo.bind(this);
    this.validarApontamento = this.validarApontamento.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeDateEditar = this.onChangeDateEditar.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);

    this.state = {
      id: props.id,
      estorias: [],
      apontamentos: this.props.apontamentos,
      apontamentoNovo: {
        data: new Date(),
        horaInicial: '',
        horaFinal: '',
        descricao: '',
        tipoEstoria: null,
        usuarioCancelamento: null,
        status: 0
      },
      apontamentoEditarNovo: null,
      apontamentoExcluir: '',
      modal: this.props.modal,
      modalEditar: false,
      contatos: [],
      modalConfirm: false,
      descricaoAtual: 'Clique sobre o apontamento para visualizar a descrição.',
      fkTipoEstoriaSemRetorno: null,
      errors:{
        data: false,
        horaInicial: false,
        horaFinal: false,
        tipoEstoria: false,
        contato: false,
        descricao: false,

      }
    }
  }

  async componentDidMount() {
    await this.loadEstorias();
  }

  async loadEstorias() {
    await axios.get(`${this.props.getBaseUrlApi()}/tipos-estoria`, this.props.parameters())
      .then(response => {
        let estorias = response.data.estorias;
        const fkTipoEstoriaSemRetorno = response.data.fkTipoEstoriaSemRetorno;
        estorias = estorias.map(estoria => {
          delete estoria.cobrado;
          delete estoria.faturado;
          delete estoria.status;
          delete estoria.tipoApontamento;
          delete estoria.type;
          delete estoria._id;
          delete estoria._rev;
          return estoria;
        })
        this.setState({ estorias: estorias, fkTipoEstoriaSemRetorno });
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  async onChangeInputNewApontamento(e) {
    let localApontamento = this.state.apontamentoNovo;
    localApontamento[e.target.id] = e.target.value;
    this.setState({
      apontamentoNovo: localApontamento
    });
  }

  async onChangeDateTime(date, type) {
    let localApontamento = this.state.apontamentoNovo;
    localApontamento[type] = date;
    this.setState({
      apontamentoNovo: localApontamento
    });
  }

  async onChangeDate(date) {
    let localApontamento = this.state.apontamentoNovo;
    localApontamento.data = date;
    this.setState({
      apontamentoNovo: localApontamento
    });
  }

  async onChangeDateEditar(date) {
    let localApontamento = this.state.apontamentoEditarNovo;
    localApontamento.data = date;
    this.setState({
      apontamentoEditarNovo: localApontamento
    });
  }

  onChangeEstoria(estoria) {   
    let localApontamento = this.state.apontamentoNovo;
    if(estoria.id === this.state.fkTipoEstoriaSemRetorno && localApontamento.descricao === ''){
      localApontamento.descricao = 'Tentativa de contato sem sucesso.';
    }
    localApontamento.tipoEstoria = estoria
    this.setState({ apontamentoNovo: localApontamento });
  }

  onChangeEstoriaEditar(tipoEstoria) {
    let localApontamento = this.state.apontamentoEditarNovo;
    localApontamento.tipoEstoria = tipoEstoria
    this.setState({ apontamentoEditarNovo: localApontamento });
  }

  async onSubmitApontamento({ e, toast }) {
    e.preventDefault();
    if (! await this.validarApontamento('new')) return false;

    let localApontamento = Object.assign({}, this.state.apontamentoNovo);
    let date = new Date(localApontamento.data);
    let horaInicial = localApontamento.horaInicial;
    let horaFinal = localApontamento.horaFinal;
    horaInicial = horaInicial.split(':');
    horaFinal = horaFinal.split(':');
    let dateInicial = date.setHours(horaInicial[0], horaInicial[1], '00');
    dateInicial = new Date(dateInicial);
    let dateFinal = date.setHours(horaFinal[0], horaFinal[1], '00');
    dateFinal = new Date(dateFinal);
    localApontamento.horaInicial = dateInicial;
    localApontamento.horaFinal = dateFinal;
    var ms = moment(dateFinal, "YYYY-MM-DD HH:mm:ss").diff(moment(dateInicial, "YYYY-MM-DD HH:mm:ss"));
    var duration = moment.duration(ms);
    localApontamento.tempoTotal = { minutos: Math.floor(duration.asMinutes()), segundos: parseInt(moment.utc(ms).format('ss')) };
    delete localApontamento.data;
    let contato = Object.assign({}, localApontamento.contato);
    delete localApontamento.contato;
    localApontamento.contato = { id: contato.id, descricao: contato.descricao, setor: contato.setor, fone: contato.fone, email: contato.email };
    let tipoEstoria = Object.assign({}, localApontamento.tipoEstoria);
    delete localApontamento.tipoEstoria;
    localApontamento.tipoEstoria = { id: tipoEstoria.id, codigo: tipoEstoria.codigo, descricao: tipoEstoria.descricao };

    const toastProps = {
      text: 'Apontamento adicionado com sucesso',
      actionText: 'Fechar',
      ariaLabel: 'Ticket criado com sucesso, clique para fechar'
    };


    api.post(`apontamento/adicionar/${this.state.id}`, localApontamento, this.props.parameters()).then(response => {
      localApontamento.data = new Date();
      localApontamento.horaInicial = '';
      localApontamento.horaFinal = '';
      localApontamento.descricao = '';
      localApontamento.usuarioCancelamento = null;
      localApontamento.status = 0;
      this.setState({ apontamentoNovo: localApontamento }, () => {
        this.props.atualiza(response.data.ticket);
        toast.show(({ ...toastProps, onActionClick: toast.hide }));
      });
    });
  }

  async editarApontamento({ toast }) {
    let localApontamento = Object.assign({}, this.state.apontamentoEditarNovo);

    let data = localApontamento.data;
    let horaInicial = localApontamento.horaInicial;
    let horaFinal = localApontamento.horaFinal;

    horaInicial = horaInicial.split(':');
    horaFinal = horaFinal.split(':');

    let dateInicial = data.setHours(horaInicial[0], horaInicial[1], '00');
    dateInicial = new Date(dateInicial);

    let dateFinal = data.setHours(horaFinal[0], horaFinal[1], '00');
    dateFinal = new Date(dateFinal);

    localApontamento.horaInicial = dateInicial;
    localApontamento.horaFinal = dateFinal;

    var ms = moment(dateFinal, "YYYY-MM-DD HH:mm:ss").diff(moment(dateInicial, "YYYY-MM-DD HH:mm:ss"));
    var duration = moment.duration(ms);
    localApontamento.tempoTotal = { minutos: Math.floor(duration.asMinutes()), segundos: parseInt(moment.utc(ms).format('ss')) };
    let isValid = await this.validarApontamento('edit');

    if (!isValid) {
      alert('Preencha todos os dados corretamente para editar o apontamento.');
      return false;
    } else {

      await api.post(`apontamento/editar/${this.state.id}/${localApontamento.ordem}`, localApontamento, this.props.parameters()).then((response) => {
        this.setState({ modalEditar: !this.state.modalEditar }, () => {
          this.props.atualiza(response.data.ticket);
          const toastProps = {
            text: 'Apontamento editado com sucesso',
            actionText: 'Fechar',
            ariaLabel: 'Ticket criado com sucesso, clique para fechar'
          };
          toast.show(({ ...toastProps, onActionClick: toast.hide }));
        });
      })
    }
  }

  async excluirApontamento({ toast }) {
    let ordem = this.state.apontamentoExcluir;
    await api.post(`apontamento/excluir/${this.state.id}/${ordem}`, {}, this.props.parameters()).then(async (response) => {
      await this.props.atualiza(response.data.ticket);
      this.setState({ modalConfirm: false }, () => {
        const toastProps = {
          text: 'Apontamento excluído com sucesso',
          actionText: 'Fechar',
          ariaLabel: 'Ticket criado com sucesso, clique para fechar'
        };
        toast.show(({ ...toastProps, onActionClick: toast.hide }));
      });
    });
  }

  toggleEditar() {
    this.setState({
      modalEditar: !this.state.modalEditar
    });
  }

  onChangeEditarApontamento(e) {
    let localApontamento = this.state.apontamentoEditarNovo;
    localApontamento[e.target.id] = e.target.value;
    this.setState({ apontamentoEditarNovo: localApontamento });
  }

  async onChangeDateTimeEditar(date, type) {
    let localApontamento = this.state.apontamentoEditarNovo;
    localApontamento[type] = date;
    this.setState({
      apontamentoEditarNovo: localApontamento
    });
  }

  onChangeContatoEditar(contact) {
    let localApontamento = this.state.apontamentoEditarNovo;
    localApontamento.contato = contact;
    this.setState({ apontamentoEditarNovo: localApontamento });
  }

  onChangeContatoNovo(contact) {
    let localApontamento = this.state.apontamentoNovo;
    localApontamento.contato = contact;
    this.setState({ apontamentoNovo: localApontamento });
  }

  async validarApontamento(type) {
    let apontamento = {};

    if (type === 'new') {
      apontamento = this.state.apontamentoNovo;
    } else if (type === 'edit') {
      apontamento = this.state.apontamentoEditarNovo;
    }

    if (!apontamento.descricao) {
      apontamento.descricao = '';
    }

    if (!apontamento.tipoEstoria) {
      apontamento.tipoEstoria = { codigo: '', descricao: '' }
    }

    let isValid = true;
    let errors = this.state.errors;

    let date = new Date(apontamento.data);
    let horaInicial = apontamento.horaInicial;
    let horaFinal = apontamento.horaFinal;
    horaInicial = horaInicial.split(':');
    horaFinal = horaFinal.split(':');
    let dateInicial = date.setHours(horaInicial[0], horaInicial[1], '00');
    dateInicial = new Date(dateInicial);
    let dateFinal = date.setHours(horaFinal[0], horaFinal[1], '00');
    dateFinal = new Date(dateFinal);

    if (dateInicial >= dateFinal) {
      alert('A hora inicial deve ser menor que a hora final.');
      isValid = false;
      errors.horaInicial = true;
      errors.horaFinal = true;
    }else{
      errors.horaInicial = false;
      errors.horaFinal = false;
    }

    if (validator.isEmpty(apontamento.tipoEstoria.codigo, [{ ignore_whitespace: true }]) || validator.isEmpty(apontamento.tipoEstoria.descricao, [{ ignore_whitespace: true }])) {
   
      isValid = false;
      errors.tipoEstoria = true;
    }else{
      errors.tipoEstoria = false;
    }

    if(!apontamento.hasOwnProperty('contato') || apontamento.contato === null){
      isValid = false;
      errors.contato = true;
    }else{
      errors.contato = false;
    }

    if (validator.isEmpty(apontamento.descricao, [{ ignore_whitespace: true }])) {
      isValid = false;
      errors.descricao = true;
    }else{
      errors.descricao = false;
    }

    if (apontamento.descricao.length < 7) {
      isValid = false;
      errors.descricao = true;
    }else{
      errors.descricao = false;
    }

    this.setState({errors});
    return isValid;
  }

  toggleConfirm() {
    this.setState({
      modalConfirm: !this.state.modalConfirm
    });
  }

  render() {
    return (
      <div className="col-sm-12" style={{paddingTop: '15px'}}>
        <Row>
          {this.props.exibeCadastro &&
            <Col sm={6}>
              <Col sm={12}>
                <ToastConsumer>
                  {
                    ({ show, hide }) => (
                      <AdicionarApontamento
                        onSubmitApontamento={(e) => this.onSubmitApontamento({ e, toast: { show, hide } })}
                        onChangeEstoria={this.onChangeEstoria}
                        apontamento={this.state.apontamentoNovo}
                        estorias={this.state.estorias}
                        onChangeInputNewApontamento={this.onChangeInputNewApontamento}
                        id={this.state.id}
                        parameters={this.props.parameters}
                        getBaseUrlApi={this.props.getBaseUrlApi}
                        atualiza={this.props.atualiza}
                        onChangeDateTime={this.onChangeDateTime}
                        contatos={this.props.contatos}
                        contatoTicket={this.props.contatoTicket}
                        onChangeContatoNovo={this.onChangeContatoNovo}
                        onChangeDate={this.onChangeDate}
                        errors={this.state.errors}
                      />
                    )
                  }
                </ToastConsumer>

              </Col>
            </Col>
          }

          <Col sm={6}>
            <h5 style={{ padding: '20px 0px' }}>Lista de Apontamentos</h5>
            {this.props.apontamentos.length > 0 &&
              <ListaApontamentos
                apontamentos={this.props.apontamentos}
                onClickEditar={(apontamento1) => {
                  let apontamento = Object.assign({}, apontamento1);
                  apontamento.data = new Date(apontamento.horaInicial);
                  apontamento.horaInicial = moment(new Date(apontamento.horaInicial)).format('HH:mm');
                  apontamento.horaFinal = moment(new Date(apontamento.horaFinal)).format('HH:mm');
                  this.setState({ apontamentoEditarNovo: apontamento, modalEditar: true });
                }}
                onClickDeletar={(apontamento) => this.setState({ apontamentoExcluir: apontamento.ordem, modalConfirm: true })}
                alteraDescricao={(descricao) => this.setState({ descricaoAtual: descricao.replace(/(?:\r\n|\r|\n)/g, '<br />') })}
                descricao={this.state.descricaoAtual}
                createMarkup={this.props.createMarkup}
                tempoTotal={this.props.tempoTotal}
              />
            }
          </Col>
        </Row>

        {this.props.exibeCadastro &&
          <ToastConsumer>
            {
              ({ show, hide }) => (
                <EditarApontamento
                  apontamento={this.state.apontamentoEditarNovo}
                  modal={this.state.modalEditar}
                  toggle={this.toggleEditar}
                  onChangeInput={this.onChangeEditarApontamento}
                  onSubmit={() => this.editarApontamento({ toast: { show, hide } })}
                  onChangeDateTimeEditar={this.onChangeDateTimeEditar}
                  onChangeContatoEditar={this.onChangeContatoEditar}
                  contatos={this.props.contatos}
                  contatoTicket={this.props.contatoTicket}
                  estorias={this.state.estorias}
                  onChangeEstoriaEditar={this.onChangeEstoriaEditar}
                  onChangeDateEditar={this.onChangeDateEditar}
                  errors={this.state.errors}
                />
              )
            }
          </ToastConsumer>

        }


        <ToastConsumer>
          {
            ({ show, hide }) => (
              <ModalConfirm
                modal={this.state.modalConfirm}
                toggle={this.toggleConfirm}
                text={'Tem certeza que deseja excluir o apontamento?'}
                onClickYes={() => this.excluirApontamento({ toast: { show, hide } })}
                onClickNo={() => this.toggleConfirm()}
              />
            )
          }
        </ToastConsumer>




      </div>
    );
  }
}

export default Apontamento;