import React, { Fragment } from 'react';
import { Button, Table } from 'reactstrap';
import moment from 'moment';

const ListaApontamentos = (props) => {

  const formatDate = (data) => {
    return moment(new Date(data)).format('DD/MM/YYYY');
  }

  const formatDateTime = (data) => {
    return moment(new Date(data)).format('HH:mm');
  }

  let descricao = props.descricao.replace(/(?:\r\n|\r|\n)/g, '<br />');

  let totalHoras = '00:00:00';

  if (props.apontamentos.length) {
    
    let tempoTotal = { horas: 0, minutos: 0, segundos: 0 };

    for (let apontamento of props.apontamentos) {
      if (apontamento.status === 0 && apontamento.horaFinal !== '') {
        let dateFinal = apontamento.horaFinal;
        let dateInicial = apontamento.horaInicial;
        let ms = moment(dateFinal, "YYYY-MM-DD HH:mm:ss").diff(moment(dateInicial, "YYYY-MM-DD HH:mm:ss"));
        let duration = moment.duration(ms);
        tempoTotal.minutos = tempoTotal.minutos + Math.floor(duration.asMinutes());
        tempoTotal.segundos = tempoTotal.segundos + parseInt(moment.utc(ms).format('ss'));
      }
    }

    if (tempoTotal.segundos > 59) {
      let calculo = parseInt(tempoTotal.segundos / 60);
      tempoTotal.segundos = tempoTotal.segundos - calculo * 60;
      tempoTotal.minutos += calculo;
    }

    if (tempoTotal.minutos > 59) {
      let calculo = parseInt(tempoTotal.minutos / 60);
      tempoTotal.minutos = tempoTotal.minutos - calculo * 60;
      tempoTotal.horas += calculo;
    }

    totalHoras = tempoTotal.horas.toString().padStart(2, '0') + 
    ':' + 
    tempoTotal.minutos.toString().padStart(2, '0') + 
    ':' + 
    tempoTotal.segundos.toString().padStart(2, '0');
  }

  return (
    <Fragment>
      <Table striped hover bordered>
        <thead>
          <tr>
            <th>Data</th>
            <th>Hora Inicial</th>
            <th>Hora Final</th>
            <th>Usuário</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {props.apontamentos.map((apontamento, index) => {

            return apontamento.status === 0 && apontamento.descricao !== '' &&

              <tr key={index} style={{ color: '#FFF!important', cursor: 'pointer' }} onClick={() => props.alteraDescricao(apontamento.descricao)}>
                <td>{formatDate(apontamento.horaInicial).toString()}</td>
                <td>{formatDateTime(apontamento.horaInicial).toString()}</td>
                <td>{formatDateTime(apontamento.horaFinal).toString()}</td>
                <td>{apontamento.usuarioCriacao.descricao.indexOf(" ") !== -1 ? apontamento.usuarioCriacao.descricao.substring(0, apontamento.usuarioCriacao.descricao.indexOf(" ")) : apontamento.usuarioCriacao.descricao}</td>
                <td>
                  <Button color="warning" size={"sm"} onClick={() => props.onClickEditar(apontamento)} style={{ margin: '5px', color: '#FFF!important' }}>Editar</Button>
                  <Button color="danger" size={"sm"} onClick={() => props.onClickDeletar(apontamento)}>Excluir</Button>
                </td>
              </tr>
          }
          )}
          <tr>
            <td colSpan={5}>Tempo Total: {totalHoras}</td>
          </tr>
        </tbody>
      </Table>

      <div style={{ paddingTop: '20px' }}>
        <Table striped hover bordered>
          <thead>
            <tr>
              <th>Descrição do Apontamento</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ color: '#FFF!important' }}>
              <td style={{ maxWidth: '456px' }}><div dangerouslySetInnerHTML={props.createMarkup(descricao)} /></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
}

export default ListaApontamentos;