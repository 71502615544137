import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeField from 'react-simple-timefield';
import Select from 'react-select';

const EditarApontamento = ({ onChangeDateEditar, modal, toggle, apontamento, onChangeInput, onSubmit, onChangeDateTimeEditar, contatos, onChangeContatoEditar, estorias, onChangeEstoriaEditar, ...props }) => {

  if (modal) {

    apontamento.tipoEstoria.value = apontamento.tipoEstoria.codigo + 
    ' - ' + apontamento.tipoEstoria.descricao;
    apontamento.tipoEstoria.label = apontamento.tipoEstoria.codigo + ' - ' 
    + apontamento.tipoEstoria.descricao;
    apontamento.contato.label = apontamento.contato.descricao;
    apontamento.contato.value = apontamento.contato.descricao;

    return (
      <Modal isOpen={modal} toggle={toggle} className={props.className}>
        <ModalHeader toggle={toggle}>Editar Apontamento</ModalHeader>
        <ModalBody>

          <div className="form-group">
            <label>Data:</label>
            <br />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              customInput={<Input />}
              selected={apontamento.data}
              onChange={onChangeDateEditar}
            />
          </div>

          <div className="form-group">
            <label>Hora Inicial:</label>
            <TimeField
              value={apontamento.horaInicial}
              onChange={(date) => onChangeDateTimeEditar(date, 'horaInicial')}
              input={<Input className={props.errors.horaInicial ? 'is-invalid' : ''}/>}
              colon=":"
              showSeconds={false}
            />
            {props.errors.horaInicial  && 
              <div className="is-invalid-feedback">
                Data inválida.
              </div>
            }
          </div>

          <div className="form-group">
            <label>Hora Final:</label>
            <TimeField
              value={apontamento.horaFinal}
              onChange={(date) => onChangeDateTimeEditar(date, 'horaFinal')}
              input={<Input className={props.errors.horaInicial ? 'is-invalid' : ''}/>}
            />
            {props.errors.horaFinal  && 
              <div className="is-invalid-feedback">
                Data inválida.
              </div>
            }
          </div>

          <div className="form-group">
            <label>Descrição: </label>
            <textarea id="descricao" className="form-control" value={apontamento.descricao} onChange={onChangeInput} />
            {props.errors.descricao  && 
              <div className="is-invalid-feedback">
                Por favor digite uma descrição.
              </div>
            }

          </div>

          <div className="form-group">
            <label>Tipo de Estória:</label>


            <Select
              value={apontamento.tipoEstoria}
              onChange={onChangeEstoriaEditar}
              options={estorias}
            />
            {props.errors.tipoEstoria  && 
              <div className="is-invalid-feedback">
                Por favor selecione um tipo de estória.
              </div>
            }
          </div>

          <div className="form-group">
            <label>Contato:</label>
            <Select
              value={apontamento.contato}
              onChange={onChangeContatoEditar}
              options={contatos}
            />
            {props.errors.contato  && 
              <div className="is-invalid-feedback">
                Por favor selecione um contato.
              </div>
            }
          </div>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSubmit()}>Salvar</Button>
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    );
  } else {
    return null;
  }
}

export default EditarApontamento;