import React, { Component } from "react";
import api from "../../services/api";
import {
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import Apontamento from "../Apontamento";
import validator from "validator";
import moment from "moment";
import axios from "axios";
import {
  CommentList,
  ListaPendencias,
  ApontamentoAtual,
  ModalAvaliar,
  ModalAvaliacao,
  EditarPendencia,
  ModalConfirm,
} from "../../components";
import CreatePendencia from "../CreatePendencia";
import { ToastConsumer } from "react-awesome-toasts";
import Select from "react-select";
import queryString from "query-string";
import AdicionarComentario from "../../components/AdicionarComentario";
import CreateTicket from "../../pages/CreateTicket";
import FileDownload from "js-file-download";
const {
  getStatusName,
  atualizaActions,
  getStatusClassName,
  getStatusClassNamePreTicket,
} = require("../../utils");

class DetailPreTicket extends Component {
  constructor(props) {
    super(props);
    this.onClickStartService = this.onClickStartService.bind(this);
    this.onClickEndService = this.onClickEndService.bind(this);
    this.onClickCancelService = this.onClickCancelService.bind(this);
    this.getDetailsTicket = this.getDetailsTicket.bind(this);
    this.updateActions = this.updateActions.bind(this);
    this.toggle = this.toggle.bind(this);
    this.validarFinalizaAtendimento =
      this.validarFinalizaAtendimento.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.toggleModalAlert = this.toggleModalAlert.bind(this);
    this.loadProjetos = this.loadProjetos.bind(this);
    this.aprovarPreTicket = this.aprovarPreTicket.bind(this);
    this.cancelarPreTicket = this.cancelarPreTicket.bind(this);
    this.toggleModalAprovarPreTicket =
      this.toggleModalAprovarPreTicket.bind(this);
    this.handleClickAprovarTicket = this.handleClickAprovarTicket.bind(this);
    this.toggleModalCancelarPreTicket =
      this.toggleModalCancelarPreTicket.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickCancelarTicket = this.handleClickCancelarTicket.bind(this);
    this.handleClickAlterarStatus = this.handleClickAlterarStatus.bind(this);
    this.toggleModalAlterarStatus = this.toggleModalAlterarStatus.bind(this);
    this.alterarStatusTicket = this.alterarStatusTicket.bind(this);
    this.baixarAnexo = this.baixarAnexo.bind(this);
    this.toggleModalAbrirRequisito = this.toggleModalAbrirRequisito.bind(this);
    this.handleClickAbrirRequisito = this.handleClickAbrirRequisito.bind(this);

    this.state = {
      user: JSON.parse(this.props.user()),
      id: props.match.params.id,
      ticket: {
        cliente: {
          id: "",
          descricao: "",
          telefone: "",
          cnpj: "",
        },
        contato: {
          email: "",
          telefone: "",
        },
        tipo: "",
        assunto: "",
        descricao: "",
        prioridade: "",
        dataAgenda: "",
        notificarCliente: "",
        situacao: "",
        apontamentos: [{}],
        comentarios: [],
        logs: [],
        anexos: [],
      },
      actionsButtons: {
        iniciarAtendimento: false,
        finalizarAtendimento: false,
        pausarAtendimento: false,
        criarApontamento: false,
        cancelarAtendimento: false,
      },
      usuarioIniciouAtendimento: false,
      modal: false,
      actionModal: null,
      estorias: [],
      contatos: [],
      activeTab: "resumo",
      comentario: "",
      comentarioEditar: { comentario: "" },
      modalEditarComentario: false,
      pendencias: [],
      apontamentoAtual: { descricao: "", tipoEstoria: null },
      modalAvaliacao: false,
      rating: 1,
      comentarioAvaliacao: "",
      avaliacaoRequerida: false,
      modalAvaliar: false,
      pendenciaEditar: null,
      modalPendenciaEditar: false,
      refreshStatePendencia: false,
      modalConfirm: false,
      titleModal: "",
      modalApp: {
        alert: false,
      },
      buttonEditComment: false,
      fkTipoEstoriaSemRetorno: null,
      tempoTotal: null,
      finalizacaoSucesso: true,
      modalImpedimento: false,
      errors: {
        tipoEstoria: false,
        descricao: false,
        contato: false,
        descricaoComentario: false,
        descricaoComentarioEditar: false,
        projeto: false,
        obsCancelamento: false,
      },
      projetos: [],
      projeto: null,
      observacaoInterna: "",
      modalAprovarPreTicket: false,
      modalCancelarTicket: false,
      obsCancelamento: "",
      modalAlterarStatus: false,
      modalAbrirRequisito: false,
    };
  }

  async componentDidMount() {
    await this.getDetailsTicket();
    // await this.loadProjetos();
  }

  async getDetailsTicket() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/pre-tickets/${this.state.id}`,
        this.props.parameters()
      )
      .then(async (response) => {
        let ticket = response.data.ticket;

        if (ticket === null) {
          return this.props.history.push("/tickets");
        }

        ticket.descricao = ticket.descricao.replace(
          /(?:\r\n|\r|\n)/g,
          "<br />"
        );

        // ticket.anexos = [
        //   { desc: "Erro", dtGravacao: "2023-08-23T10:00:00.000Z" },
        // ];

        this.setState({
          ticket: ticket,
        });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  async onClickStartService({ toast }) {
    let localApontamento = {
      horaInicial: new Date().toJSON(),
      horaFinal: "",
      descricao: "",
      tipoEstoria: {
        codigo: "",
        descricao: "",
      },
      usuarioCriacao: null,
      usuarioCancelamento: null,
      status: 0,
    };

    await api
      .post(
        `atendimento/iniciar/${this.state.id}`,
        localApontamento,
        await this.props.parameters()
      )
      .then(async (res) => {
        const toastProps = {
          text: "Atendimento iniciado",
          actionText: "Fechar",
          ariaLabel: "Ticket criado com sucesso, clique para fechar",
        };

        let ticket = res.data.ticket;
        let usuarioIniciouAtendimento = false;
        let apontamentoAtual = this.state.apontamentoAtual;

        if (ticket.situacao === 2) {
          for (let i = 0; i < ticket.apontamentos.length; i++) {
            if (ticket.apontamentos[i].horaFinal === "") {
              apontamentoAtual = Object.assign({}, ticket.apontamentos[i]);
              apontamentoAtual.contato = ticket.contato;
              apontamentoAtual.horaInicial = new Date(
                apontamentoAtual.horaInicial
              );

              if (
                ticket.apontamentos[i].usuarioCriacao._id ===
                this.state.user._id
              ) {
                usuarioIniciouAtendimento = true;
              }
            }
          }
        }
        this.setState(
          {
            ticket: ticket,
            usuarioIniciouAtendimento: usuarioIniciouAtendimento,
            apontamentoAtual: apontamentoAtual,
          },
          async () => {
            await this.updateActions();
            toast.show({ ...toastProps, onActionClick: toast.hide });
          }
        );
      });
  }

  async validarFinalizaAtendimento(tipo = null) {
    let isValid = true;
    let errors = this.state.errors;

    if (
      validator.isEmpty(this.state.apontamentoAtual.tipoEstoria.codigo, [
        { ignore_whitespace: true },
      ])
    ) {
      isValid = false;
      errors.tipoEstoria = true;
      this.setState({ errors });
      return false;
    } else {
      errors.tipoEstoria = false;
    }

    if (
      validator.isEmpty(this.state.apontamentoAtual.tipoEstoria.descricao, [
        { ignore_whitespace: true },
      ])
    ) {
      isValid = false;
      errors.tipoEstoria = true;
      this.setState({ errors });
      return false;
    } else {
      errors.tipoEstoria = false;
    }

    if (
      validator.isEmpty(this.state.apontamentoAtual.descricao, [
        { ignore_whitespace: true },
      ])
    ) {
      isValid = false;
      errors.descricao = true;
      this.setState({ errors });
      return false;
    } else {
      errors.descricao = false;
    }

    if (this.state.apontamentoAtual.descricao.length < 7) {
      isValid = false;
      errors.descricao = true;
      this.setState({ errors });
      return false;
    } else {
      errors.descricao = false;
    }

    if (
      validator.isEmpty(this.state.apontamentoAtual.contato.descricao, [
        { ignore_whitespace: true },
      ])
    ) {
      isValid = false;
      errors.contato = true;
      this.setState({ errors });
      return false;
    } else {
      errors.contato = false;
    }

    if (tipo !== null) {
      if (tipo === "impedimento") {
        if (this.state.projeto === null) {
          isValid = false;
          errors.projeto = true;
          this.setState({ errors });
          return false;
        } else {
          errors.projeto = false;
          this.setState({ errors });
        }
      } else {
        errors.projeto = false;
        this.setState({ errors });
      }
    } else {
      errors.projeto = false;
      this.setState({ errors });
    }

    return isValid;
  }

  async onClickEndService({ action, toast, tipo }) {
    if (action === "pausar") {
      const toastProps = {
        text: "Atendimento parado",
        actionText: "Fechar",
        ariaLabel: "Ticket criado com sucesso, clique para fechar",
      };

      let isValid = await this.validarFinalizaAtendimento();

      if (isValid) {
        let localApontamento = this.state.apontamentoAtual;
        let url = "";
        localApontamento.horaFinal = new Date();
        let dateFinal = localApontamento.horaFinal;
        let dateInicial = localApontamento.horaInicial;
        let ms = moment(dateFinal, "YYYY-MM-DD HH:mm:ss").diff(
          moment(dateInicial, "YYYY-MM-DD HH:mm:ss")
        );
        let duration = moment.duration(ms);
        localApontamento.tempoTotal = {
          minutos: Math.floor(duration.asMinutes()),
          segundos: parseInt(moment.utc(ms).format("ss")),
        };

        let tipoEstoria = Object.assign({}, localApontamento.tipoEstoria);
        delete localApontamento.tipoEstoria;
        localApontamento.tipoEstoria = {
          id: tipoEstoria.id,
          codigo: tipoEstoria.codigo,
          descricao: tipoEstoria.descricao,
        };

        delete localApontamento.contato.value;
        delete localApontamento.contato.label;

        url = `atendimento/parar/${this.state.id}/${localApontamento.ordem}`;

        await api
          .post(url, localApontamento, this.props.parameters())
          .then(async (res) => {
            let ticket = res.data.ticket;
            let usuarioIniciouAtendimento = false;
            this.setState(
              {
                ticket: ticket,
                usuarioIniciouAtendimento: usuarioIniciouAtendimento,
                apontamentoAtual: { usuarioCriacao: { _id: null } },
                modal: !this.state.modal,
              },
              async () => {
                await this.updateActions();

                toast.show({ ...toastProps, onActionClick: toast.hide });
              }
            );
          });
      }
    } else if (action === "finalizar") {
      let isValid = await this.validarFinalizaAtendimento(tipo);

      if (isValid) {
        if (tipo === "impedimento") {
          this.setState({ finalizacaoSucesso: false });
        }

        let localApontamento = this.state.apontamentoAtual;
        localApontamento.horaFinal = new Date();
        let dateFinal = localApontamento.horaFinal;
        let dateInicial = localApontamento.horaInicial;
        let ms = moment(dateFinal, "YYYY-MM-DD HH:mm:ss").diff(
          moment(dateInicial, "YYYY-MM-DD HH:mm:ss")
        );
        let duration = moment.duration(ms);
        localApontamento.tempoTotal = {
          minutos: Math.floor(duration.asMinutes()),
          segundos: parseInt(moment.utc(ms).format("ss")),
        };

        delete localApontamento.tipoEstoria._id;
        delete localApontamento.tipoEstoria._rev;
        delete localApontamento.tipoEstoria.value;
        delete localApontamento.tipoEstoria.type;
        delete localApontamento.tipoEstoria.label;
        delete localApontamento.tipoEstoria.status;
        delete localApontamento.tipoEstoria.tipoApontamento;
        delete localApontamento.tipoEstoria.cobrado;
        delete localApontamento.tipoEstoria.faturado;
        delete localApontamento.contato.value;
        delete localApontamento.contato.label;

        if (this.state.avaliacaoRequerida) {
          this.setState({ modalAvaliar: !this.state.modalAvaliar });
        } else {
          let finalizacaoSucesso = this.state.finalizacaoSucesso;
          if (tipo === "impedimento") {
            finalizacaoSucesso = false;
          }
          this.setState({
            modalAvaliacao: !this.state.modalAvaliacao,
            finalizacaoSucesso,
          });
        }
      }
    }
  }

  onClickCancelService({ toast }) {
    const toastProps = {
      text: "Ticket cancelado",
      actionText: "Fechar",
      ariaLabel: "Ticket criado com sucesso, clique para fechar",
    };

    api
      .post(
        `atendimento/cancelar/${this.state.id}`,
        {},
        this.props.parameters()
      )
      .then(async (response) => {
        let ticket = response.data.ticket;
        await this.setState(
          { ticket: ticket, modalConfirm: !this.state.modalConfirm },
          async () => {
            await this.updateActions();
            toast.show({ ...toastProps, onActionClick: toast.hide });
          }
        );
      });
  }

  async updateActions() {
    let localActions = atualizaActions(
      this.state.ticket.situacao,
      this.state.usuarioIniciouAtendimento
    );
    await this.setState({ actionsButtons: localActions });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleConfirm() {
    this.setState({
      modalConfirm: !this.state.modalConfirm,
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  toggleModalAlert() {
    let localModal = this.state.modalApp;
    localModal.alert = !localModal.alert;
    this.setState({ modalApp: localModal });
  }

  async loadProjetos() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/projetos`, this.props.parameters())
      .then((response) => {
        let projetos = response.data.projetos;
        projetos = projetos.map((projeto) => {
          projeto.value = projeto.id;
          projeto.label = projeto.nome;
          return projeto;
        });
        this.setState({ projetos });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  async aprovarPreTicket({ toast }) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/pre-tickets-aprovar`,
        { idTicket: this.state.id },
        this.props.parameters()
      )
      .then(async (response) => {
        this.toggleModalAprovarPreTicket();
        const toastProps = {
          text: "Ticket aprovado com sucesso",
          actionText: "Fechar",
          ariaLabel: "Ticket aprovado com sucesso, clique para fechar",
        };

        toast.show({ ...toastProps, onActionClick: toast.hide });

        if (this.state.ticket.tipoPortalCliente === "Suporte") {
          setTimeout(() => {
            return this.props.history.push(`/tickets/detail/${this.state.id}`);
          }, 1500);
        } else {
          this.toggleModalAbrirRequisito();
        }

        // setTimeout(() => {
        //   if (
        //     this.state.ticket.tipoPortalCliente === "Desenvolvimento" ||
        //     this.state.ticket.tipoPortalCliente === "Consultoria"
        //   ) {
        //     localStorage.setItem(
        //       "preTicket",
        //       JSON.stringify(this.state.ticket)
        //     );
        //     return this.props.history.push(`/requisitos/novo`);
        //   } else {
        //     return this.props.history.push(`/tickets/detail/${this.state.id}`);
        //   }
        // }, 1500);
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  async cancelarPreTicket({ toast }) {
    let obsCancelamento = this.state.obsCancelamento;

    if (obsCancelamento === "") {
      this.setState({
        errors: { ...this.state.errors, obsCancelamento: true },
      });
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/pre-tickets-cancelar`,
        { idTicket: this.state.id, obsCancelamento },
        this.props.parameters()
      )
      .then(async (response) => {
        const toastProps = {
          text: "Ticket cancelado",
          actionText: "Fechar",
          ariaLabel: "Ticket cancelado, clique para fechar",
        };

        toast.show({ ...toastProps, onActionClick: toast.hide });

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  toggleModalAprovarPreTicket() {
    this.setState({ modalAprovarPreTicket: !this.state.modalAprovarPreTicket });
  }

  handleClickAprovarTicket() {
    this.toggleModalAprovarPreTicket();
  }

  toggleModalCancelarPreTicket() {
    this.setState({ modalCancelarTicket: !this.state.modalCancelarTicket });
  }

  handleChangeInput(value, type) {
    this.setState({ [type]: value });
  }

  handleClickCancelarTicket() {
    this.toggleModalCancelarPreTicket();
  }

  handleClickAlterarStatus() {
    this.toggleModalAlterarStatus();
  }

  toggleModalAlterarStatus() {
    this.setState({ modalAlterarStatus: !this.state.modalAlterarStatus });
  }

  async alterarStatusTicket({ toast }) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/pre-tickets-status`,
        { idTicket: this.state.id },
        this.props.parameters()
      )
      .then(async (response) => {
        const toastProps = {
          text: "Status do ticket alterado com sucesso",
          actionText: "Fechar",
          ariaLabel:
            "Status do ticket alterado com sucesso, clique para fechar",
        };

        toast.show({ ...toastProps, onActionClick: toast.hide });

        this.toggleModalAlterarStatus();

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  async baixarAnexo(url) {
    setTimeout(() => {
      const newWindow = window.open(`${url}`, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }, 1000);
  }

  toggleModalAbrirRequisito() {
    this.setState({ modalAbrirRequisito: !this.state.modalAbrirRequisito });
  }

  handleClickAbrirRequisito(abrirRequisito) {
    if (abrirRequisito) {
      localStorage.setItem("preTicket", JSON.stringify(this.state.ticket));
      return this.props.history.push(`/requisitos/novo`);  
        
    }else{
      return this.props.history.push(`/tickets/detail/${this.state.id}`);
    }
  }

  render() {
    return (
      <div id="contentSite">
        <div className="container">
          <div className="Example_box">
            <div className="Example_box-header">
              <span>Detalhe do Pré-Ticket</span>
            </div>
            <div className="Example_box-content">
              <Col sm={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "resumo" }}
                      onClick={() => {
                        this.toggleTab("resumo");
                      }}
                    >
                      Resumo
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "anexos" }}
                      onClick={() => {
                        this.toggleTab("anexos");
                      }}
                    >
                      Anexos
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "logs" }}
                      onClick={() => {
                        this.toggleTab("logs");
                      }}
                    >
                      Logs
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="resumo">
                    <div style={{ paddingTop: "15px" }}>
                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Código: </b>
                            {this.state.ticket.codigo}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Data Emissão: </b>
                            {moment(this.state.ticket.dataCriacao).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Situação: </b>
                            <span
                              style={{
                                width: "100%",
                                fontWeight: "bold",
                                paddingLeft: "3px",
                              }}
                            >
                              <span
                                className={
                                  "tag " +
                                  getStatusClassNamePreTicket(
                                    this.state.ticket.situacaoPortalCliente
                                  )
                                }
                              >
                                {this.state.ticket.situacaoPortalCliente}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Cliente: </b>
                            {this.state.ticket.cliente.descricao}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Contato: </b>
                            {this.state.ticket.contato.descricao} (
                            {this.state.ticket.contato.email})
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Telefone: </b>
                            {this.state.ticket.contato.fone}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tipo Portal: </b>
                            {this.state.ticket.tipoPortalCliente}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tipo: </b>
                            {this.state.ticket.tipo}
                          </div>
                        </div>
                        {/* <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Prioridade: </b>
                            {this.state.ticket.prioridade}
                          </div>
                        </div> */}
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Usuário Atendimento: </b>
                            {this.state.ticket.usuario
                              ? this.state.ticket.usuario.descricao
                              : "Não Vinculado"}
                          </div>
                        </div>

                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tela/Processo: </b>
                            {this.state.ticket.telaProcesso !== undefined
                              ? this.state.ticket.telaProcesso
                              : ""}
                          </div>
                        </div>

                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Módulo: </b>
                            {this.state.ticket.modulo !== undefined &&
                            this.state.ticket.modulo !== null
                              ? this.state.ticket.modulo
                              : ""}
                          </div>
                        </div>


                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Assunto:</b>
                            {this.state.ticket.assunto}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Descrição:</b>
                            <div
                              className=""
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.ticket.descricao
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      {this.state.ticket.situacaoPortalCliente ===
                        "Cancelado" && (

                      <div className="row">
                        <div className="col-md-12 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Obs Cancelamento:</b>
                            <div
                              className=""
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.ticket.obsCancelamentoPortalCliente
                              )}
                            />
                          </div>
                        </div>
                      </div>
                        )}

                      <br />

                      <FormGroup style={{ paddingTop: "20px" }}>
                        <Button
                          color="success"
                          onClick={() => this.handleClickAprovarTicket()}
                          style={{ margin: "3px", minWidth: "105px" }}
                          disabled={
                            this.state.ticket.situacaoPortalCliente ===
                            "Cancelado"
                          }
                        >
                          <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          Aprovar
                        </Button>

                        <Button
                          color="danger"
                          onClick={() => this.handleClickCancelarTicket()}
                          style={{ margin: "3px", minWidth: "105px" }}
                          disabled={
                            this.state.ticket.situacaoPortalCliente ===
                            "Cancelado"
                          }
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>{" "}
                          Cancelar
                        </Button>

                        {/* <Button
                          color="warning"
                          onClick={() => this.handleClickAlterarStatus()}
                          style={{
                            margin: "3px",
                            minWidth: "105px",
                            color: "white",
                          }}
                          disabled={
                            this.state.ticket.situacaoPortalCliente ===
                              "Cancelado" ||
                            this.state.ticket.situacaoPortalCliente ===
                              "Em análise"
                          }
                        >
                          <i className="fa fa-refresh" aria-hidden="true"></i>{" "}
                          Alterar status
                        </Button> */}

                        <Button
                          color="info"
                          onClick={() =>
                            this.props.history.push(
                              `/pre-tickets/edit/${this.state.id}`
                            )
                          }
                          disabled={
                            this.state.ticket.situacaoPortalCliente ===
                            "Cancelado"
                          }
                          style={{ margin: "3px", minWidth: "105px" }}
                        >
                          {" "}
                          <i
                            className="fa fa-pencil"
                            aria-hidden="true"
                          ></i>{" "}
                          Editar
                        </Button>
                      </FormGroup>
                    </div>
                  </TabPane>

                  <TabPane tabId="anexos">
                    <div style={{ paddingTop: "15px" }}>
                      <table className="table table-bordered table-striped table-hover">
                        <thead className="thead-dark-custom thead-site">
                          <th style={{ borderTop: "none" }}>Descrição</th>
                          <th
                            style={{
                              borderTop: "none",
                              textAlign: "center",
                            }}
                          >
                            Data
                          </th>
                          <th className="text-center">Ações</th>
                        </thead>
                        <tbody>
                          {this.state.ticket.anexos.length > 0 &&
                            this.state.ticket.anexos.map((anexo, key) => (
                              <tr key={key}>
                                <td>{anexo.desc}</td>
                                <td className="text-center">
                                  {moment(anexo.dtGravacao).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                                <td className="text-center">
                                  <div style={{ margin: "3px" }}>
                                    <i
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                        color: "#28a745",
                                      }}
                                      className="fa fa-download"
                                      onClick={() => {
                                        this.baixarAnexo(anexo.url);
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                  <TabPane tabId="logs">
                    <div style={{ paddingTop: "15px" }}>
                      <table className="table table-bordered table-striped table-hover">
                        <thead className="thead-dark-custom thead-site">
                          <th style={{ borderTop: "none" }}>Descrição</th>
                          <th
                            style={{
                              borderTop: "none",
                              textAlign: "center",
                            }}
                          >
                            Data
                          </th>
                        </thead>
                        <tbody>
                          {this.state.ticket.logs.length > 0 &&
                            this.state.ticket.logs.map((log, key) => (
                              <tr key={key}>
                                <td>{log.desc}</td>
                                <td className="text-center">
                                  {moment(log.dtGravacao).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                </TabContent>

                <ToastConsumer>
                  {({ show, hide }) => (
                    <ModalConfirm
                      modal={this.state.modalConfirm}
                      toggle={this.toggleConfirm}
                      text={"Tem certeza que deseja cancelar o Ticket?"}
                      onClickYes={() =>
                        this.onClickCancelService({ toast: { show, hide } })
                      }
                      onClickNo={() => this.toggleConfirm()}
                    />
                  )}
                </ToastConsumer>

                <Modal
                  isOpen={this.state.modalApp.alert}
                  toggle={this.toggleModalAlert}
                >
                  <ModalHeader toggle={this.toggleModalAlert}>
                    Atenção
                  </ModalHeader>
                  <ModalBody>
                    <div>
                      Para Finalizar o atendimento, o ticket deve ter pelo menos
                      um apontamento.{" "}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={this.toggleModalAlert}>
                      Fechar
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </div>
          </div>
        </div>

        <ToastConsumer>
          {({ show, hide }) => (
            <Modal
              isOpen={this.state.modalAprovarPreTicket}
              toggle={this.toggleModalAprovarPreTicket}
            >
              <ModalBody>
                <p style={{ fontSize: "18px" }}>
                  Tem certeza que deseja aprovar este ticket?
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={this.toggleModalAprovarPreTicket}
                >
                  Não
                </Button>
                <Button
                  color="danger"
                  onClick={() =>
                    this.aprovarPreTicket({ toast: { show, hide } })
                  }
                >
                  Sim
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </ToastConsumer>

        <ToastConsumer>
          {({ show, hide }) => (
            <Modal
              isOpen={this.state.modalCancelarTicket}
              // isOpen={true}
              toggle={this.toggleModalCancelarPreTicket}
            >
              <ModalBody>
                <p style={{ fontSize: "18px" }}>
                  Tem certeza que deseja cancelar este ticket?
                </p>

                <div className="form-group">
                  <label>Obs Cancelamento:</label>
                  <textarea
                    value={this.state.obsCancelamento}
                    onChange={(e) =>
                      this.handleChangeInput(e.target.value, "obsCancelamento")
                    }
                    rows={4}
                    className={
                      this.state.errors.obsCancelamento
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {this.state.errors.obsCancelamento && (
                    <div className="is-invalid-feedback">
                      Por favor digite uma obs.
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={this.toggleModalCancelarPreTicket}
                >
                  Não
                </Button>
                <Button
                  color="danger"
                  onClick={() =>
                    this.cancelarPreTicket({
                      toast: { show, hide },
                    })
                  }
                >
                  Sim
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </ToastConsumer>

        <ToastConsumer>
          {({ show, hide }) => (
            <Modal
              isOpen={this.state.modalAlterarStatus}
              // isOpen={true}
              toggle={this.toggleModalAlterarStatus}
            >
              <ModalBody>
                <p style={{ fontSize: "18px" }}>
                  Tem certeza que deseja alterar o status deste ticket para "Em
                  análise" ?
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={this.toggleModalAlterarStatus}
                >
                  Não
                </Button>
                <Button
                  color="danger"
                  onClick={() =>
                    this.alterarStatusTicket({
                      toast: { show, hide },
                    })
                  }
                >
                  Sim
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </ToastConsumer>



        <ToastConsumer>
          {({ show, hide }) => (
            <Modal
              isOpen={this.state.modalAbrirRequisito}
              toggle={this.toggleModalAbrirRequisito}
            >
              <ModalBody>
                <p style={{ fontSize: "18px" }}>
                  Deseja abrir um requisito para este ticket?
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => this.handleClickAbrirRequisito(false)}
                >
                  Não
                </Button>
                <Button
                  color="danger"
                  onClick={() => this.handleClickAbrirRequisito(true)}
                >
                  Sim
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </ToastConsumer>




      </div>
    );
  }
}

export default DetailPreTicket;
