import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ModalConfirm = (props) => {
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalBody>

        <p style={{ fontSize: '22px' }}>{props.text}</p>

      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={props.onClickYes}>Sim</Button>{' '}
        <Button color="info" onClick={props.onClickNo}>Não</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalConfirm;