import React, { Component } from "react";
import { Row, Col, Progress } from "reactstrap";
import axios from "axios";
import "./Home.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.loadIndicadoresAtendimento = this.loadIndicadoresAtendimento.bind(this);
    this.loadAcompanhamentoUsuario = this.loadAcompanhamentoUsuario.bind(this);
    this.loadIndicadorApontamentos = this.loadIndicadorApontamentos.bind(this);

    this.state = {
      indicadoresAtendimento: {
        emAberto: 0,
        parados: 0,
        finalizados: 0,
        cancelados: 0,
        pendencias: 0
      },
      acompanhamentoPorUsuario: null,
      acompanhamentoDia: true,
      indicadorDia: true,
      usuariosPorcentagem: null
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    this.loadIndicadoresAtendimento();
    this.loadAcompanhamentoUsuario();
    this.loadIndicadorApontamentos()
  }

  async loadIndicadoresAtendimento(){
    await axios
      .get(`${this.props.getBaseUrlApi()}/atendimento/indicadores`, this.props.parameters())
      .then(response => {
        const data = response.data;
        this.setState({
          indicadoresAtendimento: data
        });
      })
      .catch(err =>
        this.props.handlingErrorsApi(err, this.props)
      );
  }

  async loadAcompanhamentoUsuario(){
    await axios
      .get(`${this.props.getBaseUrlApi()}/atendimento/acompanhamento`, this.props.parameters())
      .then(response => {
        const data = response.data;
        this.setState({
          acompanhamentoPorUsuario: data
        });
      })
      .catch(err =>
        this.props.handlingErrorsApi(err, this.props)
      );
  }

  async loadIndicadorApontamentos() {    
    await axios
      .get(`${this.props.getBaseUrlApi()}/atendimento/apontamentos`, this.props.parameters())
      .then(response => {
        const porcentagem = response.data;

        for(let porc of porcentagem){
          let horas = 0;
          let minutos = porc.totalMinutosDia;

          if(minutos > 59){
            horas = parseInt(minutos / 60);
            minutos = minutos - horas * 60;
          }

          let horasMes = 0;
          let minutosMes = porc.totalMinutosMes;

          if(minutosMes > 59){
            horasMes = parseInt(minutosMes /60);
            minutosMes = minutosMes - horasMes * 60;
          }
          let tempo = horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0');
          let tempoMes = horasMes.toString().padStart(2, '0') + ':' + minutosMes.toString().padStart(2, '0');
          porc.tempo = tempo;
          porc.tempoMes = tempoMes;
        }
        this.setState({
          usuariosPorcentagem: porcentagem,
        });
      })
      .catch(err =>
        this.props.handlingErrorsApi(err, this.props)
      );      
  }

  render() {
    const ItemIndicadores = props => {
      return (
        <Col sm={12} style={{ padding: "0px 18px 20px 20px" }}>
          <div className="title-dashboard">
            {props.titulo}{" "}
            {props.tooltip ? (
              <React.Fragment>
                <i
                  className="fa fa-question-circle"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Cobrança no mês subsequente"
                  id={`tooltip-${props.id}`}
                ></i>
              </React.Fragment>
            ) : (
              ""
            )}{" "}
            <br />
            <span className="subtitle-dashboard">{props.subtitulo}</span>
          </div>
          <div className={`value-dashboard dashboard-${props.color}`}>
            <span>{props.valor}</span>{" "}
            {props.utilizaDescricaoValor ? (
              <span className="text-horas">Horas</span>
            ) : (
              ""
            )}
          </div>
        </Col>
      );
    };

    return (
      <div id="contentSite">
        <div className="Example_container-home" style={{paddingTop: "15px"}}>
          <div className="container">
            <Row>

            <Col sm="6">
                <div className="card card-home" style={{minHeight:"437px"}}>
                  <span
                    className="title-dashboard"
                    style={{
                      textAlign: "center",
                      float: "left",
                      width: "100%"
                    }}
                  >
                    Indicadores de Atendimento
                  </span>
                  
                  <ItemIndicadores
                    titulo="Pendentes/Andamento"
                    subtitulo="Tickets pendentes e em andamento"
                    valor={this.state.indicadoresAtendimento.emAberto}
                    color="green"
                    tooltip={false}
                    utilizaDescricaoValor={false}
                  />

                  <ItemIndicadores
                    titulo="Parados"
                    subtitulo="Tickets parados"
                    valor={this.state.indicadoresAtendimento.parados}
                    color="green"
                    tooltip={false}
                    utilizaDescricaoValor={false}
                  />

                  <ItemIndicadores
                    titulo="Finalizados"
                    subtitulo="Tickets finalizados no mês"
                    valor={this.state.indicadoresAtendimento.finalizados}
                    color="green"
                    tooltip={false}
                    utilizaDescricaoValor={false}
                  />

                  <ItemIndicadores
                    titulo="Cancelados"
                    subtitulo="Tickets cancelados no mês"
                    valor={this.state.indicadoresAtendimento.cancelados}
                    color="green"
                    tooltip={false}
                    utilizaDescricaoValor={false}
                  />

                  <ItemIndicadores
                    titulo="Pendências"
                    subtitulo="Pendências em aberto"
                    valor={this.state.indicadoresAtendimento.pendencias}
                    color="green"
                    tooltip={false}
                    utilizaDescricaoValor={false}
                  />

                </div>
              </Col>

              <Col sm="6">
                <div className="card card-home" style={{minHeight:"437px"}}>
                  <div
                    className="title-dashboard"
                    style={{
                      float: "left",
                      width: "100%"
                    }}
                  >
                    Acompanhamento por Usuario
                    <div className="pull-right">
                    <button className={this.state.acompanhamentoDia ? "btn btn-grid-inicial-active" : "btn btn-grid-inicial"} onClick={() => this.setState({acompanhamentoDia : true})}>Diário</button>
                   <button className={this.state.acompanhamentoDia === false ? "btn btn-grid-inicial-active" : "btn btn-grid-inicial"} onClick={() => this.setState({acompanhamentoDia : false})}>Mensal</button>
                 
                      </div>
                   </div>
                  <table
                    className="table table-hover table-striped"
                    style={{ fontSize: "14px" }}
                  >
                    <thead className="thead-dark-custom thead-site">
                      <tr>
                        <td>Usuário</td>
                        <td>Fazer</td>
                        <td>Andamento</td>
                        <td>Parado</td>
                        <td>Finalizado</td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.acompanhamentoPorUsuario &&
                        this.state.acompanhamentoPorUsuario.map(
                          (acompanhamento, key) => (
                            <tr key={key}>
                              <td className="text-center-td">{acompanhamento.usuario}</td>

                              <td className="text-center-td">{acompanhamento.fazer}</td>
                              <td className="text-center-td">{acompanhamento.andamento}</td>
                              <td className="text-center-td">{acompanhamento.parado}</td>
                              <td className="text-center-td">
                                {this.state.acompanhamentoDia
                                  ? acompanhamento.finalizado
                                  : acompanhamento.finalizadoMes}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </Col>

              
            </Row>

            <Row>
              <Col sm="6">
                <div className="card card-home" style={{minHeight:"437px"}}>
                  <div
                    className="title-dashboard"
                    style={{
                      float: "left",
                      width: "100%"
                    }}
                  >
                    Apontamentos
                    <div className="pull-right">
                      <button className={this.state.indicadorDia ? "btn btn-grid-inicial-active" : "btn btn-grid-inicial"} onClick={() => this.setState({indicadorDia : true})}>Diário</button>
                      <button className={this.state.indicadorDia === false ? "btn btn-grid-inicial-active" : "btn btn-grid-inicial"} onClick={() => this.setState({indicadorDia : false})}>Mensal</button>
                 
                      </div>
                   </div>

                  <div style={{float: "left", width: "100%", paddingTop: "15px"}}>
                    {this.state.usuariosPorcentagem &&
                      this.state.usuariosPorcentagem.map((item, key) => (
                        <React.Fragment>
                          <Row style={{padding: "5px 0px"}}>
                            <Col sm={4}>{item.usuario}</Col>

                            <Col sm={8}>
                              <Progress color="danger" value={this.state.indicadorDia ? item.porcentagemDia : item.porcentagemMes} style={{minHeight: "30px"}}>
                                {this.state.indicadorDia ? item.porcentagemDia : item.porcentagemMes} %
                              </Progress>
                              {this.state.indicadorDia ? 
                              (<span style={{float: "right", fontSize: "13px"}}>{item.tempo} de 8:48</span>)
                              :
                              (<span style={{float: "right", fontSize: "13px"}}>{item.tempoMes} de 166:00</span>)
                              }
                            </Col>
                          </Row>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
