import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import axios from "axios";
import { Doughnut, Bar, Pie } from "react-chartjs-2";
import "./Home1.css";

const dataOptions = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ["#EF3F5C", "#514C90","#7b0000", "#309A85","#FBA026", "#514C90"],
      hoverBackgroundColor: ["#EF3F5C", "#514C90","#7b0000", "#309A85","#FBA026", "#514C90"]
    }
  ]
};

const dataGrafico = {
  labels: [],
  datasets: [
    {
      label: "Total de Horas",
      backgroundColor: "#7b0000",
      borderColor: "#7b0000",
      borderWidth: 1,
      hoverBackgroundColor: "#7b0000",
      hoverBorderColor: "#7b0000",
      data: []
    }
  ],
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};

const dataGraficoConsultoria6Mesess = {
  labels: [],
  datasets: [
    {
      label: "Total de Horas",
      backgroundColor: "#309A85",
      borderColor: "#309A85",
      borderWidth: 1,
      hoverBackgroundColor: "#309A85",
      hoverBorderColor: "#309A85",
      data: []
    }
  ],
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};

const clientesMaisAtendidosOptions = {
  labels: [],
  datasets: [
    {
      label: "Total de Tickets",
      backgroundColor: "#262e73",
      borderColor: "#262e73",
      borderWidth: 1,
      hoverBackgroundColor: "#262e73",
      hoverBorderColor: "#262e73",
      data: []
    }
  ],
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};


const options = {
  maintainAspectRatio: false,
  responsive: false,
  legend: {
    position: 'left',
    labels: {
      boxWidth: 10
    }
  }
}

const optionsDataHorasCobradas = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ["#EF3F5C", "#514C90"],
      hoverBackgroundColor: ["#EF3F5C", "#514C90"]
    }
  ]
};

class Home1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: dataOptions,
      dataGraficoConsultoria: dataGraficoConsultoria6Mesess,
      dataGrafico:dataGrafico,
      clientesMaisAtendidos: clientesMaisAtendidosOptions,
      indicadoresAtendimento: {
        pendentes: 0,
        parados: 0,
        finalizados: 0,
        cancelados: 0,
        totalApontamentos: 0
      },
      acompanhamentoPorUsuario: null,
      acompanhamentoDia: true,
      dataHorasCobradas: optionsDataHorasCobradas
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    await this.loadDashboard();
  }

  async loadDashboard() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/dashboard1`, this.props.parameters())
      .then(response => {

        let graficoHorasCobradas = this.state.dataHorasCobradas;

        graficoHorasCobradas.datasets[0].data = [
          response.data.porcentagemNaoCobrada,
          response.data.porcentagemCobrada
        ];
        graficoHorasCobradas.labels = [];
        graficoHorasCobradas.labels.push(
          `Horas não cobradas (${response.data.porcentagemNaoCobrada})`
        );
        graficoHorasCobradas.labels.push(
          `Horas cobradas (${response.data.porcentagemCobrada})`
        );


        let graficoAtendimentos = this.state.data;

        // graficoAtendimentos.datasets[0].data = [
        //   response.data.porcentagemNaoCobrada,
        //   response.data.porcentagemCobrada
          

        // ];

        graficoAtendimentos.datasets[0].data = [];
        graficoAtendimentos.labels = [];


        for (let item of response.data.clientesMaisAtendidos) {
          graficoAtendimentos.datasets[0].data.push(item.quantidade)
          graficoAtendimentos.labels.push(item.descricao);
        
        }


        let graficoConsultoria = this.state.dataGrafico;

        graficoConsultoria.labels = [];
        graficoConsultoria.datasets[0].data = [];

        for (let item of response.data.listaUsuarios) {
          graficoConsultoria.labels.push(item.usuario);
          graficoConsultoria.datasets[0].data.push(item.totalHorasCobrada);
        }

        //

        let graficoConsultoria6Meses = this.state.dataGraficoConsultoria;

        graficoConsultoria6Meses.labels = [];
        graficoConsultoria6Meses.datasets[0].data = [];

        for (let item of response.data.historicoAtendimento) {
          graficoConsultoria6Meses.labels.push(item.mes);
          graficoConsultoria6Meses.datasets[0].data.push(item.tempoTotal);
        }

        //

        ///

        let maisAtendidos = this.state.clientesMaisAtendidos;

        maisAtendidos.labels = [];
        maisAtendidos.datasets[0].data = [];

        for (let item of response.data.clientesMaisAtendidos) {
          maisAtendidos.labels.push(item.descricao);
          maisAtendidos.datasets[0].data.push(item.quantidade);
        }

        ///



         this.setState({
          data: graficoAtendimentos,
          dataGrafico: graficoConsultoria,
          dataGraficoConsultoria: graficoConsultoria6Meses,
          clientesMaisAtendidos : maisAtendidos,
          dataHorasCobradas: graficoHorasCobradas
        });


        // this.setState({
        //   indicadoresAtendimento: response.data.indicadoresAtendimento,
        //   acompanhamentoPorUsuario: response.data.acompanhamentoPorUsuario
        // });

        ///this.setState({ totalPendencias: response.data.totalPendencias, totalTickets : response.data.totalTickets, acompanhamentoPorUsuario : response.data.acompanhamentoPorUsuario });
      })
      .catch(err =>
        this.props.handlingErrorsApi(err, this.props)
      );
  }

  render() {

    return (
      <div id="contentSite">
        <div className="Example_container-home" style={{paddingTop: "15px"}}>
          <div className="container">

            <Row>
            <Col sm={6}>
                <div className="card card-home-consultoria">
                  <div
                    style={{
                      float: "left",
                      width: "100%",
                      position: "relative",
                      minHeight: "20px"
                    }}
                  >
                    <span
                      className="title-dashboard"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        position: "absolute"
                      }}
                    >
                      Horas Cobradas
                    </span>
                  </div>
                  <span
                    className="subtitle-dashboard"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingBottom: "12px"
                    }}
                  >
                    Horas cobradas x horas não cobradas
                  </span>
                  <Doughnut
                    data={this.state.dataHorasCobradas}
                    width={95}
                    height={45}
                    options={{
                      legend: {
                        display: true,
                        position: "bottom"
                      }
                    }}
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="card card-home-consultoria">
                  <span
                    className="title-dashboard"
                    style={{
                      textAlign: "center",
                      float: "left",
                      width: "100%"
                    }}
                  >
                    Desempenho
                  </span>
                  <span
                    className="subtitle-dashboard"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingBottom: "12px"
                    }}
                  >
                    Total de horas apontadas no mês atual
                  </span>

                  <div>
                    <Bar
                      data={this.state.dataGrafico}
                      width={70}
                      height={220}
                      options={{
                        maintainAspectRatio: false,
                        legend: {
                          position: "bottom"
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true
                              }
                            }
                          ]
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>  

              </Row>

              <Row>
              <Col sm={6}>
                <div className="card card-home-consultoria">
                  <span
                    className="title-dashboard"
                    style={{
                      textAlign: "center",
                      float: "left",
                      width: "100%"
                    }}
                  >
                    Consultoria
                  </span>
                  <span
                    className="subtitle-dashboard"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingBottom: "12px"
                    }}
                  >
                    Total de horas dos ultimos 6 meses
                  </span>

                  <div>
                    <Bar
                      data={this.state.dataGraficoConsultoria}
                      width={70}
                      height={220}
                      options={{
                        maintainAspectRatio: false,
                        legend: {
                          position: "bottom"
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true
                              }
                            }
                          ]
                        }
                      }}
                    />
                  </div>
                </div>
              </Col> 

              <Col sm={6}>
                <div className="card card-home-consultoria">
                  <div
                    style={{
                      float: "left",
                      width: "100%",
                      position: "relative",
                      minHeight: "20px"
                    }}
                  >
                    <span
                      className="title-dashboard"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        position: "absolute"
                      }}
                    >
                      Clientes mais Atendidos
                    </span>
                  </div>
                  <span
                    className="subtitle-dashboard"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingBottom: "12px"
                    }}
                  >
                    Os cinco clientes mais atendidos
                  </span>
                  <Doughnut
                    data={this.state.data}
                    width={95}
                    height={45}
                    options={{
                      legend: {
                        display: true,
                        position: "bottom"
                      }
                    }}
                  />
                </div>
              </Col>


               {/* <Col sm={6}>
                <div className="card card-home-consultoria">
                  <span
                    className="title-dashboard"
                    style={{
                      textAlign: "center",
                      float: "left",
                      width: "100%"
                    }}
                  >
                    Clientes
                  </span>
                  <span
                    className="subtitle-dashboard"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingBottom: "12px"
                    }}
                  >
                    Clientes mais atendidos no mês atual
                  </span>

                  <div>
                    <Bar
                      data={this.state.clientesMaisAtendidos}
                      width={70}
                      height={220}
                      options={{
                        maintainAspectRatio: false,
                        legend: {
                          position: "bottom"
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true
                              }
                            }
                          ]
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>  */}
                      
              </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Home1;
