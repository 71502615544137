import React, { useState, useEffect } from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';
import {  Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';

const NavBar = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);


  return (
    <nav className="navbar navbar-expand-lg navbar-light Example_navbar" style={{ backgroundColor: '#FFF!important' }}>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* <div className="teste">
        <Link to={'/home'} className="navbar-brand Example_navbar-brand">
          SAC Portal
          </Link>
        <div className="">
          <div className="teste1"></div>
          <div className="teste1"></div>
          <div className="teste1"></div>
        </div></div> */}
      <div className="collapse navbar-collapse" id="navbarTogglerDemo01" style={{ minHeight: '70px', backgroundColor: '#FFF!important' }}>
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0 Example_navbar-itens-acesso">
          <li className="nav-item Example_nav-item">
            <Link to={'/home'} style={{ textDecoration: 'none', color: '#212529' }} className="nav-link Example_nav-link">
              <i className={props.itemActive === 'home' ? "fa fa-home navbar-item-active" : "fa fa-home"} aria-hidden="true" style={{ fontSize: '25px' }}></i><span className={props.itemActive === 'home' ? "navbar-item-active" : ""}>Home</span>
              <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item Example_nav-item">
            <Link to={'/tickets/create'} style={{ textDecoration: 'none', color: '#212529' }} className="nav-link Example_nav-link">
              <i className={props.itemActive === 'ticketNovo' ? "fa fa-ticket navbar-item-active" : "fa fa-ticket"} aria-hidden="true" style={{ fontSize: '25px' }}></i><span className={props.itemActive === 'ticketNovo' ? "navbar-item-active" : ""}>Novo Ticket</span>
            </Link>
          </li>
          <li className="nav-item Example_nav-item">
            <Link to={'/tickets'} style={{ textDecoration: 'none', color: '#212529' }} className="nav-link Example_nav-link">
              <i className={props.itemActive === 'cronograma' ? "fa fa-calendar navbar-item-active" : "fa fa-calendar"} aria-hidden="true" style={{ fontSize: '25px' }}></i><span className={props.itemActive === 'cronograma' ? "navbar-item-active" : ""}>Cronograma</span>
            </Link>
          </li>
          <li className="nav-item Example_nav-item">
            <Link to={'/requisitos/novo'} style={{ textDecoration: 'none', color: '#212529' }} className="nav-link Example_nav-link">
              <i className={props.itemActive === 'requisitoNovo' ? "fa fa-list-ul navbar-item-active" : "fa fa-list-ul"} aria-hidden="true" style={{ fontSize: '25px' }}></i><span className={props.itemActive === 'requisitoNovo' ? "navbar-item-active" : ""}>Novo Requisito</span>
            </Link>
          </li>

          <li className="nav-item Example_nav-item">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <DropdownToggle tag="div" caret>
                {" "}
                <i
                  className={
                    props.itemActive === "notificacao"
                      ? "fa fa-bell navbar-item-active"
                      : "fa fa-bell"
                  }
                  aria-hidden="true"
                  style={{ fontSize: "25px" }}
                ></i>
                <span
                  className={
                    props.itemActive === "notificacao"
                      ? "navbar-item-active"
                      : ""
                  }
                >
                  &nbsp;Notificações {props.existeNotificacaoNova ? (<i className="fa fa-circle icon-alert-notificacao" aria-hidden="true"></i>): ''}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                {props.notificacoes.length > 0 &&
                  props.notificacoes.map(notificacao => (
                    <Link
                      to={"/notificacoes"}
                      style={{ textDecoration: "none", color: "#212529" }}
                      className="nav-link Example_nav-link"
                      key={notificacao._id}
                    >
                      <DropdownItem>
                        <i className={`fa ${notificacao.icone}`}></i>&nbsp;{notificacao.titulo}
                      </DropdownItem>
                    </Link>
                  ))}

                <Link
                  to={"/notificacoes"}
                  style={{ textDecoration: "none", color: "#212529" }}
                  className="nav-link Example_nav-link"
                >
                  <DropdownItem>
                    <span
                      style={{
                        textAlign: "center",
                        float: "left",
                        width: "100%"
                      }}
                    >
                      Ver todas
                    </span>
                  </DropdownItem>
                </Link>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
        <div className="form-inline my-2 my-lg-0">          
          <div className="Example_item-menu">
            <Link to={'/logout'} className="nav-link Example_nav-link" style={{ textDecoration: 'none', color:'inherit' }}>
            <div style={{fontSize:'15px', cursor: "pointer"}}> <i className="fa fa-sign-out" aria-hidden="true" style={{ fontSize: '25px' }}></i>Sair</div>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;