import React, { Component } from "react";
import api from "../../services/api";
import DateTimePicker from "react-datetime-picker";
import validator from "validator";
import axios from "axios";
import Select from "react-select";
import { ToastConsumer } from "react-awesome-toasts";
import Switch from "react-input-switch";
import {
  Alert,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

class EditPreTicket extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputCliente = this.onChangeInputCliente.bind(this);
    this.onChangeInputContato = this.onChangeInputContato.bind(this);
    this.onChangeInputUsuarios = this.onChangeInputUsuarios.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validar = this.validar.bind(this);
    this.getDetailsTicket = this.getDetailsTicket.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);

    this.state = {
      id: props.match.params.id,
      cliente: {},
      contato: null,
      tipo: "",
      assunto: "",
      descricao: "",
      usuario: null,
      prioridade: "Baixa",
      dataAgenda: "",
      notificarCliente: 1,
      situacao: 0,
      clientes: [], //LISTA DE CLIENTES
      usuarios: [], // LISTA DE USUÁRIOS
      contatos: [], // LISTA DE CONTATOS APÓS SELECIONAR O CLIENTE
      action: null,
      date: new Date(),
      errors: false,
      agendar: 0,
      tipoPortalCliente: "Suporte",
      telaProcesso: "",
      modulo: null
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    await this.loadClients();
    await this.loadUsers();
    await this.getDetailsTicket();
  }

  async getDetailsTicket() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/pre-tickets/${this.state.id}`,
        this.props.parameters()
      )
      .then(async (response) => {
        let ticket = response.data.ticket;

        if (ticket === null) {
          return this.props.history.push("/tickets");
        }

        let contatos = [];

        for (let i = 0; i < this.state.clientes.length; i++) {
          let cliente = this.state.clientes[i];
          if (cliente.descricao === ticket.cliente.descricao) {
            contatos = cliente.contatos;
          }
        }

        let exibir = false;

        if (ticket.situacao === 0 || ticket.situacao === 1) {
          exibir = true;
        }

        contatos = contatos.map((contact) => {
          contact.value = contact.descricao + " - " + contact.fone;
          contact.label = contact.descricao + " - " + contact.fone;
          return contact;
        });

        let usuario = {};

        if (ticket.usuario === null) {
          usuario = { id: 0, label: "NÃO VINCULAR", value: "NÃO VINCULAR" };
        } else {
          usuario = ticket.usuario;
          usuario.label = usuario.descricao;
          usuario.value = usuario.descricao;
        }

        let contatosTicket = [...contatos];

        if (ticket.tipo === "Suporte") {
          contatosTicket = contatosTicket.filter((c) => c.usaFranquia === true);
        }

        ticket.tipoPortalCliente = "Desenvolvimento";

        this.setState({
          cliente: ticket.cliente,
          contato: ticket.contato,
          tipo: ticket.tipo,
          assunto: ticket.assunto,
          descricao: ticket.descricao,
          usuario: usuario,
          prioridade: ticket.prioridade,
          dataAgenda: ticket.dataAgenda ? new Date(ticket.dataAgenda) : "",
          notificarCliente: ticket.notificarCliente ? 1 : 0,
          action: null,
          date: ticket.dataAgenda ? new Date(ticket.dataAgenda) : "",
          errors: false,
          agendar: ticket.dataAgenda ? 1 : 0,
          ticket: ticket,
          contatos: contatosTicket,
          exibir: exibir,
          tipoPortalCliente: ticket.tipoPortalCliente,
          telaProcesso: ticket.telaProcesso !== undefined ? ticket.telaProcesso : "",
          modulo: ticket.modulo !== undefined ? ticket.modulo : null
        });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  async loadClients() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/clientes`, this.props.parameters())
      .then((response) => {
        let clientes = response.data.clientes;
        clientes = clientes.map((cliente) => {
          cliente.descricao = cliente.fantasia;
          return cliente;
        });
        this.setState({ clientes });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  async loadUsers() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/usuarios`, this.props.parameters())
      .then((response) => {
        let users = response.data.usuarios;
        users = users.map((user) => {
          user.value = user.descricao;
          user.label = user.descricao;
          return user;
        });
        users.unshift({ id: 0, label: "NÃO VINCULAR", value: "NÃO VINCULAR" });
        this.setState({ usuarios: users });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeInputCliente(e) {
    this.state.clientes.forEach((client) => {
      if (client.descricao === e.target.value) {
        this.setState({
          cliente: client,
          contatos: client.contatos,
          contato: client.contatos[0],
        });
      }
    });
  }

  onChangeInputContato(contact) {
    this.setState({
      contato: contact,
    });
  }

  onChangeInputUsuarios(user) {
    this.setState({
      usuario: user,
    });
  }

  onChangeInput(e) {
    let value = e.target.value
    if (e.target.id === "tipo") {
      let tipoAtendimento = e.target.value;

      let client = Object.assign({}, this.state.cliente);

      if (tipoAtendimento === "Suporte") {
        client.contatos = client.contatos.filter(
          (contato) => contato.usaFranquia === true
        );

        if (client.contatos.length) {
          this.setState({ contato: client.contatos[0] });
        } else {
          this.setState({ contato: null });
        }
        this.setState({ contatos: client.contatos });
      }
    }else if(e.target.id === 'modulo'){
      if(value === 'null'){
        value = null;
      }
    }

    this.setState({
      [e.target.id]: value,
    });
  }

  async validar() {
    let valido = true;

    if (!this.state.cliente.descricao) {
      valido = false;
    }
    if (!this.state.contato.descricao) {
      valido = false;
    }
    if (validator.isEmpty(this.state.assunto, [{ ignore_whitespace: true }])) {
      valido = false;
    }
    if (
      validator.isEmpty(this.state.descricao, [{ ignore_whitespace: true }])
    ) {
      valido = false;
    }

    await this.setState({ errors: true });
    return valido;
  }

  async onSubmit({ toast }) {
    let teste = await this.validar();

    if (!teste) {
      return false;
    }

    let localCliente = this.state.cliente;
    delete localCliente.contatos;
    delete localCliente.type;
    delete localCliente.cargo;
    delete localCliente.login;
    delete localCliente.senha;
    delete localCliente.stsatus;
    delete localCliente.type;

    let situacao = null;

    if (this.state.ticket.situacao === 0 || this.state.ticket.situacao === 1) {
      if (this.state.agendar == 1) {
        situacao = 1;
      } else {
        situacao = 0;
      }
    } else {
      situacao = this.state.ticket.situacao;
    }

    let localUser = this.state.usuario;
    delete localUser.label;
    delete localUser.value;

    const obj = {
      _id: this.state.id,
      cliente: localCliente,
      contato: this.state.contato,
      tipo: this.state.tipo,
      assunto: this.state.assunto,
      descricao: this.state.descricao,
      usuario: localUser.id !== 0 ? localUser : null,
      prioridade: this.state.prioridade,
      dataAgenda: this.state.agendar == 1 ? this.state.date : null,
      notificarCliente: this.state.notificarCliente == 1 ? true : false,
      situacao: situacao,
      apontamentos: [],
      usuarioCriacao: null,
      usuarioFinalizacao: null,
      usuarioCancelamento: null,
      type: "ticket",
      sync: this.state.ticket.sync,
      tipoPortalCliente: this.state.tipoPortalCliente,
      telaProcesso: this.state.telaProcesso,
      modulo: this.state.modulo
    };

    await api
      .put("pre-tickets", { ticket: obj }, this.props.parameters())
      .then((res) => {
        this.props.history.push(`/pre-tickets/detail/${this.state.id}`);

        const toastProps = {
          text: "Ticket editado com sucesso",
          actionText: "Fechar",
          ariaLabel: "Ticket criado com sucesso, clique para fechar",
        };
        toast.show({ ...toastProps, onActionClick: toast.hide });
      });
  }

  onChange = (date) => this.setState({ date });

  handleChangeInput(value, type) {
    this.setState({ [type]: value });
  }

  render() {
    return (
      <div id="contentSite">
        <div className="container">
          <div className="Example_box">
            {/* <div className="Example_box-header"><span>Editar Ticket</span></div> */}
            <div className="Example_box-content">
              <div className="col-sm-12">
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Editar Ticket
                </div>

                <Form>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4" className="label-form">
                        Cliente:
                      </label>
                      <Input
                        value={this.state.cliente.descricao}
                        disabled={true}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="exampleEmail" className="label-form">
                        Contato:
                      </label>

                      {this.state.contatos.length ? (
                        <Select
                          value={this.state.contato}
                          onChange={this.onChangeInputContato}
                          options={this.state.contatos}
                        />
                      ) : (
                        <select
                          className="form-control"
                          id="contato"
                          disabled={true}
                        ></select>
                      )}
                      {this.state.errors.contato && (
                        <div className="is-invalid-feedback">
                          Por favor selecione um contato.
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="inputEmail4" className="label-form">
                        Tipo:
                      </label>
                      <select
                        className="form-control"
                        id="tipo"
                        value={this.state.tipo}
                        onChange={this.onChangeInput}
                      >
                        <option
                          value="Suporte"
                          selected={
                            this.state.tipo === "Suporte" ? true : false
                          }
                        >
                          Suporte
                        </option>
                        <option
                          value="Help Desk"
                          selected={
                            this.state.tipo === "Help Desk" ? true : false
                          }
                        >
                          Help Desk
                        </option>
                        <option
                          value="Atendimento Presencial"
                          selected={
                            this.state.tipo === "Atendimento Presencial"
                              ? true
                              : false
                          }
                        >
                          Atendimento Presencial
                        </option>
                        <option
                          value="Atendimento Remoto"
                          selected={
                            this.state.tipo === "Atendimento Remoto"
                              ? true
                              : false
                          }
                        >
                          Atendimento Remoto
                        </option>
                        <option
                          value="Atendimento Não Franqueado"
                          selected={
                            this.state.tipo === "Atendimento Não Franqueado"
                              ? true
                              : false
                          }
                        >
                          Atendimento Não Franqueado
                        </option>
                        <option
                          value="Atendimento Horário Especial"
                          selected={
                            this.state.tipo === "Atendimento Horário Especial"
                              ? true
                              : false
                          }
                        >
                          Atendimento Horário Especial
                        </option>
                        <option
                          value="Desenvolvimento Sob Medida"
                          selected={
                            this.state.tipo === "Desenvolvimento Sob Medida"
                              ? true
                              : false
                          }
                        >
                          Desenvolvimento Sob Medida
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="exampleEmail" className="label-form">
                        Tipo Portal Cliente:
                      </label>

                      <select
                        className="form-control"
                        value={this.state.tipoPortalCliente}
                        onChange={(e) =>
                          this.handleChangeInput(
                            e.target.value,
                            "tipoPortalCliente"
                          )
                        }
                      >
                        <option value="Suporte">Suporte</option>
                        <option value="Consultoria">Consultoria</option>
                        <option value="Desenvolvimento">Desenvolvimento</option>
                      </select>
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="exampleEmail" className="label-form">
                        Assunto:
                      </label>

                      <Input
                        type="text"
                        id="assunto"
                        value={this.state.assunto}
                        onChange={this.onChangeInput}
                        className={
                          this.state.errors.assunto ? "is-invalid" : ""
                        }
                      />

                      {this.state.errors.assunto && (
                        <div className="is-invalid-feedback">
                          Por favor digite um assunto.
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="exampleEmail" className="label-form">
                        Usuário Atendimento:
                      </label>
                      <Select
                        value={this.state.usuario}
                        onChange={this.onChangeInputUsuarios}
                        options={this.state.usuarios}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    {/* <div className="form-group col-md-4">
                      <label htmlFor="exampleEmail" className="label-form">
                        Prioridade:
                      </label>
                      <select
                        className="form-control"
                        id="prioridade"
                        value={this.state.prioridade}
                        onChange={this.onChangeInput}
                      >
                        <option
                          value="Baixa"
                          selected={
                            this.state.prioridade === "Baixa" ? true : false
                          }
                        >
                          Baixa
                        </option>
                        <option
                          value="Média"
                          selected={
                            this.state.prioridade === "Média" ? true : false
                          }
                        >
                          Média
                        </option>
                        <option
                          value="Alta"
                          selected={
                            this.state.prioridade === "Alta" ? true : false
                          }
                        >
                          Alta
                        </option>
                      </select>
                    </div> */}

                    <div className="form-group  col-md-4">
                      {/* <div className="col-md-12"> */}
                      <label htmlFor="exampleEmail" className="label-form">
                        Agendar:
                      </label>
                      {/* <br />
                      <Switch
                        value={this.state.agendar}
                        onChange={(agendar) =>
                          this.setState({ agendar: parseInt(agendar) })
                        }
                      /> */}
                      <select
                        className="form-control"
                        value={this.state.agendar}
                        onChange={(e) => {
                          this.setState({ agendar: parseInt(e.target.value) });
                        }}
                      >
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </select>
                    </div>

                    <div className="form-group  col-md-4">
                      {/* <div className="col-md-12"> */}
                      <label htmlFor="exampleEmail" className="label-form">
                        Tela/Processo:
                      </label>
                      <Input
                        type="text"
                        id="telaProcesso"
                        value={this.state.telaProcesso}
                        onChange={this.onChangeInput}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="exampleEmail" className="label-form">
                        Módulo Priorize:
                      </label>
                      <select
                        className="form-control"
                        id="modulo"
                        value={this.state.modulo}
                        onChange={this.onChangeInput}
                      >
                        <option value="null">Selecione</option>
                        <option value="Básico">Básico</option>
                        <option value="Comercial">Comercial</option>
                        <option value="CRM">CRM</option>
                        <option value="Estoque">Estoque</option>
                        <option value="Faturamento">Faturamento</option>
                        <option value="Fiscal">Fiscal</option>
                        <option value="Financeiro">Financeiro</option>
                        <option value="PCP">PCP</option>
                        <option value="Suprimentos">Suprimentos</option>
                        <option value="WMS">WMS</option>
                      </select>
                    </div>

                 
                  </div>

                  {this.state.exibir && this.state.agendar === 1 && (
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleEmail" className="label-form">
                            Data Agenda:
                          </label>
                          {/* <br />
                          <DateTimePicker
                            onChange={this.onChange}
                            value={this.state.date}
                            style={{ margin: "10px!important" }}
                          /> */}

                          <DatePicker
                            selected={this.state.date}
                            withPortal
                            customInput={
                              <input className="form-control font-size-13" />
                            }
                            dateFormat="dd/MM/yyyy HH:mm"
                            onChange={this.onChange}
                            showTimeSelect
                            locale="pt-BR"
                            onFocus={(e) => (e.target.readOnly = true)}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="exampleEmail" className="label-form">
                        Descrição:
                      </label>
                      <textarea
                        id="descricao"
                        value={this.state.descricao}
                        onChange={this.onChangeInput}
                        rows={6}
                        className={
                          this.state.errors.descricao
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {this.state.errors.descricao && (
                        <div className="is-invalid-feedback">
                          Por favor digite uma descrição.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group col-sm-12" className="div-center">
                    <ToastConsumer>
                      {({ show, hide }) => (
                        <Button
                          color="success"
                          className="button-novo-ticket"
                          type="button"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            this.onSubmit({ toast: { show, hide } })
                          }
                        >
                          <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}
                          Salvar
                        </Button>
                      )}
                    </ToastConsumer>

                    <Button
                      className="button-novo-ticket"
                      type="button"
                      style={{ margin: "5px" }}
                      onClick={() => this.props.history.goBack()}
                    >
                      <i
                        className="fa fa-arrow-circle-o-left"
                        aria-hidden="true"
                      ></i>{" "}
                      Voltar
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPreTicket;
