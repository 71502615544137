import React, { Fragment } from 'react';
import { Button, Table } from 'reactstrap';
import moment from 'moment';

const ListaPendencias = (props) => {
  return (
    <Fragment>
      <h4 style={{ padding: '20px 0px', marginTop:"-18px" }}>Lista de Pendências</h4>
      <Table striped hover bordered>
        <thead className="thead-dark-custom thead-site">
          <tr>
            <th style={{ maxWidth: "170px" }}>Assunto</th>
            <th>Usuário responsável</th>
            <th>Emissão</th>
            <th>Tipo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody style={{color:'black!important'}}>
          {props.pendencias.length > 0 && props.pendencias.map((pendencia, index) =>
            <tr key={index} style={{ color: '#FFF!important' }}>
              <td style={{ maxWidth: "170px" }}>{pendencia.assunto}</td>
              <td>{pendencia.usuario !== null ? pendencia.usuario.descricao : 'NÃO VINCULAR'}</td>
              <td>{moment(new Date(pendencia.dataCriacao)).format('DD/MM/YYYY')}</td>
              <td>{pendencia.pendencia}</td>
              <td>
                <a href={`/tickets/detail/${pendencia._id}`}>
                  <Button color="info" style={{ margin: '5px', color: '#FFF!important' }}> <i className="fa fa-eye" aria-hidden="true"></i></Button>
                </a>
                <Button className="btn-pendencia" color="warning" style={{ margin: '5px', color: '#FFF!important' }} onClick={() => props.setPendenciaEditar(pendencia)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
              </td>
            </tr>
          )}         
        </tbody>
      </Table>
      {props.pendencias.length === 0 &&
        <div className="mensagem-sem-registros">No momento este ticket não possui nenhuma pendência</div>
      }
    </Fragment>
  );
}

export default ListaPendencias;