import React, { Component } from 'react';
import api from '../../services/api';
import validator from 'validator';
import { Alert, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { ToastConsumer } from 'react-awesome-toasts';
import axios from 'axios';

class CreatePendencia extends Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputContact = this.onChangeInputContact.bind(this);
    this.validate = this.validate.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.onChangeInputUsers = this.onChangeInputUsers.bind(this);

    let contacts = props.contatos.map(contact => {
      contact.value = contact.descricao;
      contact.label = contact.descricao;
      return contact;
    });

    this.state = {
      cliente: this.props.cliente,
      contato: null,
      pendencia: 'Pendência Priori',
      assunto: '',
      descricao: '',
      situacao: 0,
      idTicketOriginal: this.props.id,
      contatos: contacts,
      value: false,
      errors:{
        contato:false,
        assunto: false,
        descricao: false
      },
      usuarios: [],
      usuario: null
    }
  }

  async componentDidMount() {
    this.setState({ cliente: this.props.cliente });
    await this.loadUsers();
  }

  async loadUsers() {
    await axios.get(`${this.props.getBaseUrlApi()}/usuarios`, this.props.parameters())
      .then(response => {
        let users = response.data.usuarios;
        users = users.map(user => {
          user.value = user.descricao;
          user.label = user.descricao;
          return user;
        });
        users.unshift({ label: 'NÃO VINCULAR', value: 'NÃO VINCULAR', id: 0 });
        this.setState({ usuarios: users, usuario: users[0] })
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeInputContact(contact) {
    this.setState({
      contato: contact
    });
  }

  onChangeInput(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  async validate() {
    let isValid = true;
    let errors = this.state.errors;

    if(!this.state.contato){
      isValid = false;
      errors.contato = true;
    }else{
      errors.contato = false;
    }

    if (isValid && validator.isEmpty(this.state.assunto, [{ ignore_whitespace: true }])) {
      isValid = false;
      errors.assunto = true
    }else{
      errors.assunto = false;
    }
    if (isValid && validator.isEmpty(this.state.descricao, [{ ignore_whitespace: true }])) {
      isValid = false;
      errors.descricao = true;
    }else{
      errors.descricao = false;
    }

    await this.setState({ errors });

    return isValid;
  }

  async onSubmit({ e, toast }) {
    e.preventDefault();
    let isValid = await this.validate();
    if (!isValid) return false;

    let localClient = this.state.cliente;
    delete localClient.contatos;
    delete localClient.type;
    delete localClient.cargo;
    delete localClient.login;
    delete localClient.senha;
    delete localClient.stsatus;
    delete localClient.type;

    let localUser = Object.assign({}, this.state.usuario);
    delete localUser.label;
    delete localUser.value;

    const objectToSend = {
      cliente: localClient,
      contato: this.state.contato,
      pendencia: this.state.pendencia,
      tipo: 'Pendência',
      assunto: this.state.assunto,
      descricao: this.state.descricao,
      idTicketOriginal: this.state.idTicketOriginal,
      prioridade: 'Baixa',
      dataAgenda: null,
      notificarCliente: true,
      situacao: this.state.situacao,
      apontamentos: [],
      comentarios: [],
      usuario: localUser.id !== 0 ? localUser : null,
      usuarioCriacao: null,
      usuarioFinalizacao: null,
      usuarioCancelamento: null,
      type: 'ticket'
    };

    await api.post('pendencias', objectToSend, this.props.parameters()).then(res => {

      const toastProps = {
        text: 'Pendência criada com sucesso',
        actionText: 'Fechar',
        ariaLabel: 'Ticket criado com sucesso, clique para fechar'
      };

      this.setState({
        contato: null,
        pendencia: 'Pendência Priori',
        assunto: '',
        descricao: '',
        usuario: this.state.usuarios[0],
        errors:{
          contato: false,
          assunto: false,
          descricao: false
        }
      }, () => {
        this.props.atualiza(res.data.ticket, res.data.pendencias)
        toast.show(({ ...toastProps, onActionClick: toast.hide }));
      });
    });
  }

  onChangeInputUsers(user) {
    this.setState({
      usuario: user
    });
  }

  render() {
    return (
      <div>
        <h4 style={{ padding: '20px 35px 15px' }}>Criar Pendência</h4>

        <ToastConsumer>
          {
            ({ show, hide }) => (
              <div className="col-sm-10" style={{ marginTop:"-20px" }} >
              <Form className="col-sm-12"  onSubmit={(e) => this.onSubmit({ e, toast: { show, hide } })}>
                {/* <FormGroup row>
                  <Label for="exampleEmail" sm={3}>Cliente</Label>
                  <Col sm={9}>
                    <Input readOnly value={this.props.cliente.descricao} />
                  </Col>
                </FormGroup> */}

                {this.props.contatos.length ?

                  <FormGroup className="col-sm-6 pull-right">
                    <Label for="exampleEmail" sm={12} style={{ paddingLeft:"0", paddingBottom:"0" }}>Contato</Label>
                    <Col sm={12} style={{ paddingLeft:"0" }} >
                      <Select
                        value={this.state.contato}
                        onChange={this.onChangeInputContact}
                        options={this.state.contatos}
                      />
                      {this.state.errors.contato === true && 
                        <div className="is-invalid-feedback">
                          Por favor selecione um contato.
                        </div>
                      }
                    </Col>
                  </FormGroup>
                  :

                  <FormGroup className="col-sm-6 pull-right">
                    <Label for="exampleEmail" style={{ paddingBottom:"0" }} sm={12}>Contato</Label>
                    <Col sm={12} style={{ paddingLeft:"0" }} >
                      <select className="form-control" id="contato" disabled={true}>

                      </select>
                    </Col>
                  </FormGroup>
                }

                <FormGroup style={{ paddingRight:"0" }} className="col-sm-6">
                  <Label for="exampleEmail" style={{ paddingBottom:"0" }} sm={12}>Pendência</Label>
                  <Col sm={12} style={{ paddingRight:"0" }}>
                    <select className="form-control" id="pendencia" value={this.state.pendencia} onChange={this.onChangeInput}>
                      <option value="Pendência Priori">Pendência Priori</option>
                      <option value="Pendência Cliente">Pendência Cliente</option>
                      <option value="Pendência Terceiros">Pendência Terceiros</option>
                    </select>
                  </Col>
                </FormGroup>

                <FormGroup style={{ paddingLeft:"0" }} className="col-sm-6  pull-right">
                  <Label for="exampleEmail" style={{ paddingBottom:"0" }} sm={12}>Usuário Atendimento</Label>
                  <Col sm={12} >
                    <Select
                      value={this.state.usuario}
                      onChange={this.onChangeInputUsers}
                      options={this.state.usuarios}
                    />
                  </Col>
                </FormGroup>


                <FormGroup className="col-sm-6" style={{ paddingRight:"0" }}>
                  <Label for="exampleEmail" style={{ paddingBottom:"0" }} sm={12}>Assunto</Label>
                  <Col sm={12} style={{ paddingRight:"0" }}>
                    <Input
                      type="text"
                      id="assunto"
                      value={this.state.assunto}
                      onChange={this.onChangeInput}
                      className={this.state.errors.assunto ? 'is-invalid': ''}
                    />
                    {this.state.errors.assunto === true && 
                      <div className="is-invalid-feedback">
                        Por favor digite um assunto.
                      </div>
                    }
                  </Col>
                </FormGroup>

                <FormGroup className="col-sm-12" >
                  <Label for="exampleEmail" style={{ paddingBottom:"0" }} sm={12}>Descrição</Label>
                  <Col sm={12}>
                    <Input type="textarea"
                      id="descricao"
                      value={this.state.descricao}
                      onChange={this.onChangeInput}
                      rows={4}
                      className={this.state.errors.descricao ? 'is-invalid': ''}
                    />
                    {this.state.errors.descricao === true && 
                      <div className="is-invalid-feedback">
                        Por favor digite uma descrição.
                      </div>
                    }
                  </Col>
                </FormGroup>

                <FormGroup row className="col-sm-10" style={{ marginLeft: '10px' }}>
                    <div className="form-group pull-right">
                      <Button type="submit" style={{ margin: '5px' }}> Adicionar</Button>
                    </div>
                </FormGroup>
              </Form>
              </div>
            )
          }
        </ToastConsumer>

      </div>
    )
  }
}

export default CreatePendencia;