import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./SideBar.css";

const style = {
  padding: "10px",
  fontSize: "16px"
};


const SideBar = (props) => {
  const [exibirPaginaConfig, setExibirPaginaConfig] = useState(false)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))

    if(user.id === 18 || user.id === 131 || user.id === 135 || user.id === 154){
      setExibirPaginaConfig(true);
    }

  }, []);


  return (
    <div id="Example_sidebar">

      <div className="teste">
        <Link to={'/home'} className="navbar-brand Example_navbar-brand" style={{ position: "relative", top: "-3px"}}>
          <img src="/images/logo-small.png" className="navbar-logo"/> SAC Online
        </Link>
        <div className="">
          <div className="teste1"></div>
          <div className="teste1"></div>
          <div className="teste1"></div>
        </div>
      </div>


      <ul style={{ color: "#FFF", paddingTop: "70px" }}>
        <Link to={"/home"} style={{ textDecoration: "none", color: "inherit" }}>
          <li className={`item-menu-li ${props.itemActive === 'home' ? 'item-menu-li-active' : ''}`} style={style}>
            <i className="fa fa-home" aria-hidden="true" /> Home
          </li>
        </Link>
        <Link
          to={"/tickets/create"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'requisitos' ? 'item-menu-li-active' : ''}`} style={style}>
            <i className="fa fa-ticket" aria-hidden="true" /> Novo Ticket
          </li>
        </Link>
        <Link
          to={"/tickets"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'admin' ? 'item-menu-li-active' : ''}`} style={style}>
            <i className="fa fa-calendar" aria-hidden="true" /> Cronograma
          </li>
        </Link>

        <Link
          to={"/requisitos/novo"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'admin' ? 'item-menu-li-active' : ''}`} style={style}>
            <i className="fa fa-list-ul" aria-hidden="true" /> Novo Requisito
          </li>
        </Link>
        <Link
          to={"/tickets/historico"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'fa-history' ? 'item-menu-li-active' : ''}`} style={style}>
            <i className="fa fa-history" aria-hidden="true" /> Tickets
          </li>
        </Link>
        <Link
          to={"/notificacoes"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'notificacao' ? 'item-menu-li-active' : ''}`} style={style}>
            <i className="fa fa-bell" aria-hidden="true" /> Notificações&nbsp; {props.existeNotificacaoNova ? (<i className="fa fa-circle icon-alert-notificacao" aria-hidden="true"></i>): ''}
          </li>
        </Link>

        {exibirPaginaConfig && (
          <Link
            to={"/config1"}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <li className="item-menu-li" style={style}>
              <i className="fa fa-cog" aria-hidden="true" /> Configurações
            </li>
          </Link>
        )}
        <Link
          to={"/logout"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className="item-menu-li" style={style}>
            <i className="fa fa-sign-out" aria-hidden="true" /> Sair
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default SideBar;
