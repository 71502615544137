import React, {Fragment} from 'react';
import { Button, Modal, ModalHeader, FormGroup, Label, Input, ModalBody, ModalFooter } from 'reactstrap';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';

const ApontamentoAtual = (props) => {
  const estorias = props.estorias;
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>{props.titleModal}</ModalHeader>
      <ModalBody>

        <FormGroup>
          <Label className="label-form">Tipo de Estória:</Label>
          <Select
            value={props.apontamento.tipoEstoria}
            onChange={props.onChangeTipoEstoria}
            options={estorias}
          />
          {props.errors.tipoEstoria && 
            <div className="is-invalid-feedback">
              Por favor selecione um tipo de estória.
            </div>
          }
        </FormGroup>
        <FormGroup>
          <Label className="label-form">Contato:</Label>
          <Select
            value={props.apontamento.contato}
            onChange={props.onChangeContato}
            options={props.contatos}
          />
          {props.errors.contato && 
            <div className="is-invalid-feedback">
              Por favor selecione um contato.
            </div>
          }
        </FormGroup>
        <FormGroup>
          <Label className="label-form">Descrição:</Label>
          <textarea
            onChange={(e) => {
              let apontamentoAtual = props.apontamento;
              apontamentoAtual.descricao = e.target.value;
              props.onChangeDescricao(apontamentoAtual);
            }}
            rows={3}
            value={props.apontamento.descricao}
            className={props.errors.descricao ? 'form-control is-invalid' : 'form-control'}
          />
          {props.errors.descricao && 
            <div className="is-invalid-feedback">
              Por favor digite uma descrição.
            </div>
          }
        </FormGroup>
        {props.tipoTicket === 'Suporte' && props.titleModal === 'Finalizar Ticket' &&
          <Fragment>
            <FormGroup className="">
              <Label>Projeto: </Label>
              <Select
                value={props.projeto}
                onChange={props.onChangeProjeto}
                options={props.projetos}
              />
              {props.errors.projeto && 
                <div className="is-invalid-feedback">
                  Por favor selecione um projeto.
                </div>
              }
            </FormGroup>
          </Fragment>
      }
      </ModalBody>
      <ModalFooter>
        {props.titleModal === 'Finalizar Ticket' ? 
          (
            <React.Fragment>
              {props.tipoTicket === 'Suporte' && <Button className="button-impedimento" onClick={() => props.onClickEnd('impedimento')}><i className="fa fa-hospital-o" aria-hidden="true"></i> Impedimento</Button>}
              <Button onClick={() => props.onClickEnd('finalizar')}><i className="fa fa-stop" aria-hidden="true"></i> Finalizar</Button>
            </React.Fragment>
            
          )
          :
          (
            // <Button color="success" onClick={() => props.onClickEnd('finalizar')}>Salvar</Button>
            // 
            <Button
              color="warning"
              onClick={() => props.onClickEnd('finalizar')}
              style={{ margin: "3px", color: '#FFF', minWidth: '105px' }}
            >
              <i className="fa fa-pause" aria-hidden="true"></i> Pausar
            </Button>
          )
        
        }

        <Button color="danger" onClick={props.toggle}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ApontamentoAtual;