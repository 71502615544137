import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import Rater from 'react-rater';
import './ModalAvaliar.css';

const ModalAvaliar = (props) => {
  return (
    <Modal isOpen={props.modalAvaliar} toggle={props.toggleModalAvaliar}>
      <ModalHeader toggle={props.toggleModalAvaliar}>Avaliar Atendimento</ModalHeader>
      <ModalBody>

        <div style={{ fontSize: '70px', display:'flex', justifyContent: 'center' }}>
          <Rater rating={props.rating} total={4} interactive={true} onRate={({ rating }) => props.handleRating(rating)} />
        </div>

        <FormGroup>
          <Label>Comentário</Label>
          <Input type="textarea" value={props.comentario} onChange={(e) => props.handleChangeComentario(e.target.value)}></Input>
        </FormGroup>

      </ModalBody>
      <ModalFooter>
      <Button color="primary" onClick={props.handleSaveRating}>Avaliar</Button>
        {!props.avaliacaoRequerida && <Button color="danger" onClick={props.cancelRating}>Cancelar</Button>}
      </ModalFooter>
    </Modal>
  );
}

export default ModalAvaliar;