import React, { Fragment } from 'react';
import { Button, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import moment from 'moment';

const CommentList = ({ modalEditarComentario, toggleModalEditarComentario, onClickEditComment, comentarios, comentarioEditar, editarComentario, onChangeCommentModalEditar, user, buttonEditComment, errors }) => {

  return (
    <Fragment>
      <div style={{ float: "left", width: "100%", padding: "0px 0px 20px 0px", margin: "15px 0px 30px", maxHeight: "300px", overflowY: "scroll" }}>
        {comentarios.map((comentario1, index) =>
          <div key={index} style={{ position: "relative", padding: "15px 0px", float:"left", width: "100%" }}>

            {user.id === comentario1.usuarioCriacao.id &&
              <div style={{ position: "absolute", right: "50px", zIndex: "998" }}>
                <UncontrolledButtonDropdown>
                  <DropdownToggle size="sm" caret></DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => onClickEditComment(comentario1)}>Editar</DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>

              </div>
            }

            <div><span style={{fontWeight: "bold", fontSize: "15px"}}>{comentario1.usuarioCriacao.descricao}</span> <span style={{ color: "rgb(156, 166, 175)", display: "inline", fontSize: "12px" }}>
              {moment(new Date(comentario1.dataCriacao)).format('DD/MM/YYYY HH:mm:ss')}</span></div>
            <div style={{float:"left", width:"100%"}}><div style={{fontSize: "15px", float:"left", width:"85%"}}>{comentario1.comentario} </div></div>
          </div>
        )}

      </div>

      <Modal isOpen={modalEditarComentario} toggle={toggleModalEditarComentario}>
        <ModalHeader toggle={toggleModalEditarComentario}>Editar Comentário</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Comentário:</Label>
            <textarea rows={5} className={errors.descricaoComentarioEditar ? "form-control is-invalid" : "form-control"} type="text" value={comentarioEditar.comentario} onChange={onChangeCommentModalEditar} />
            {errors.descricaoComentarioEditar  && 
              <div className="is-invalid-feedback">
                Por favor digite um comentário com no mínimo 7 caracteres.
              </div>
            }
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={editarComentario}>Salvar</Button>
          <Button color="secondary" onClick={toggleModalEditarComentario}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

export default CommentList;