import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, FormGroup, Label, Input, ModalBody, ModalFooter, Col, Form } from 'reactstrap';
import Select from 'react-select';
import validator from 'validator';
import api from '../../services/api';
import { ToastConsumer } from 'react-awesome-toasts';
import axios from 'axios';

class EditarPendencia extends Component {

  constructor(props) {

    super(props);

    let pendencia = this.props.pendencia;

    if(pendencia.usuario !== null){
      pendencia.usuario.value = pendencia.usuario.descricao;
      pendencia.usuario.label = pendencia.usuario.descricao;
    }     

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputContact = this.onChangeInputContact.bind(this);
    this.validate = this.validate.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.onChangeResponsavel = this.onChangeResponsavel.bind(this);

    let contacts = this.props.contatos.map(contact => {
      contact.value = contact.descricao;
      contact.label = contact.descricao;
      return contact;
    });

    pendencia.contato.value = pendencia.contato.descricao;
    pendencia.contato.label = pendencia.contato.descricao;

    this.state = {
      cliente: pendencia.cliente.descricao,
      contato: pendencia.contato,
      pendencia: pendencia.pendencia,
      assunto: pendencia.assunto,
      descricao: pendencia.descricao,
      errors: false,
      contatos: contacts,
      usuarios: [],
      usuario: pendencia.usuario
    }
  }

  async componentDidMount() {
    console.log('EditarPendencia.js componentDidMount()');
    await this.loadUsers();
  }

  async componentWillReceiveProps(props) {
    console.log('EditarPendencia.js componentWillReceiveProps()', props);
    let pendencia = props.pendencia;
    let contacts = this.props.contatos.map(contact => {
      contact.value = contact.descricao;
      contact.label = contact.descricao;
      return contact;
    });
    pendencia.contato.value = pendencia.contato.descricao;
    pendencia.contato.label = pendencia.contato.descricao;

    this.setState({
      cliente: pendencia.cliente.descricao,
      contato: pendencia.contato,
      pendencia: pendencia.pendencia,
      assunto: pendencia.assunto,
      descricao: pendencia.descricao,
      errors: false,
      contatos: contacts
    });
  }

  onChangeInputContact(contact) {
    this.setState({
      contato: contact
    });
  }

  onChangeInput(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  async validate() {
    let isValid = true;

    if (validator.isEmpty(this.state.assunto, [{ ignore_whitespace: true }])) {
      isValid = false;
    }
    if (validator.isEmpty(this.state.descricao, [{ ignore_whitespace: true }])) {
      isValid = false;
    }
    await this.setState({ errors: true });
    return isValid;
  }

  async onSubmit({toast}) {
    let isValid = await this.validate();
    if (!isValid) return false;

    let contato = this.state.contato;
    delete contato.label;
    delete contato.value;

    let usuario = Object.assign({}, this.state.usuario);
    delete usuario.label;
    delete usuario.value;

    const objectToSend = {
      contato: contato,
      pendencia: this.state.pendencia,
      assunto: this.state.assunto,
      descricao: this.state.descricao,
      usuario: usuario.id !== 0 ? usuario : null,
    };

    await api.put(`pendencias/${this.props.pendencia._id}`, objectToSend, this.props.parameters()).then(res => {
      this.props.atualiza(res.data.pendencia, res.data.pendencias);
      this.props.toggleModalPendenciaEditar();
      const toastProps = {
        text: 'Pendência editada com sucesso',
        actionText: 'Fechar',
        ariaLabel: 'Ticket criado com sucesso, clique para fechar'
      };
      toast.show(({ ...toastProps, onActionClick: toast.hide }));
    });
  }

  async loadUsers() {
    await axios.get(`${this.props.getBaseUrlApi()}/usuarios`, this.props.parameters())
      .then(response => {
        let users = response.data.usuarios;
        users = users.map(user => {
          user.value = user.descricao;
          user.label = user.descricao;
          return user;
        });
        users.unshift({ label: 'NÃO VINCULAR', value: 'NÃO VINCULAR', id: 0 });
        this.setState({ usuarios: users }, () => {
          if(this.state.usuario === null){
            this.setState({usuario: users[0]})
          }
        })
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeResponsavel(usuario) {
    this.setState({
      usuario
    });
  }

  render() {
    console.log('EditarPendencia.js -> render()');
    return (
      <Fragment>

        <Modal isOpen={this.props.modalPendenciaEditar} toggle={this.props.toggleModalPendenciaEditar}>
          <ModalHeader toggle={this.props.toggleModalPendenciaEditar}>Editar Pendência</ModalHeader>
          <ModalBody>


            <Form>
              <FormGroup row>
                <Label for="exampleEmail" sm={3}>Cliente <span style={{ fontWeight: 'bold', color: '#e00808' }}>*</span></Label>
                <Col sm={9}>
                  <Input readOnly value={this.props.cliente.descricao} />
                </Col>
              </FormGroup>

              {this.props.contatos.length ?

                <FormGroup row>
                  <Label for="exampleEmail" sm={3}>Contato <span style={{ fontWeight: 'bold', color: '#e00808' }}>*</span></Label>
                  <Col sm={9}>
                    <Select
                      value={this.state.contato}
                      onChange={this.onChangeInputContact}
                      options={this.state.contatos}
                    />
                  </Col>
                </FormGroup>
                :

                <FormGroup row>
                  <Label for="exampleEmail" sm={3}>Contato <span style={{ fontWeight: 'bold', color: '#e00808' }}>*</span></Label>
                  <Col sm={9}>
                    <select className="form-control" id="contato" disabled={true}>

                    </select>
                  </Col>
                </FormGroup>
              }

              {this.state.usuarios.length ?

                <FormGroup row>
                  <Label for="exampleEmail" sm={3}>Respo. <span style={{ fontWeight: 'bold', color: '#e00808' }}>*</span></Label>
                  <Col sm={9}>
                    <Select
                      value={this.state.usuario}
                      onChange={this.onChangeResponsavel}
                      options={this.state.usuarios}
                    />
                  </Col>
                </FormGroup>
              :

                <FormGroup row>
                  <Label for="exampleEmail" sm={3}>Respo. <span style={{ fontWeight: 'bold', color: '#e00808' }}>*</span></Label>
                  <Col sm={9}>
                    <select className="form-control" id="responsavel" disabled={true}>

                    </select>
                  </Col>
                </FormGroup>
              }

              <FormGroup row>
                <Label for="exampleEmail" sm={3}>Pendência</Label>
                <Col sm={9}>
                  <select className="form-control" id="pendencia" value={this.state.pendencia} onChange={this.onChangeInput}>
                    <option value="Pendência Priori">Pendência Priori</option>
                    <option value="Pendência Cliente">Pendência Cliente</option>
                    <option value="Pendência Terceiros">Pendência Terceiros</option>
                  </select>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="exampleEmail" sm={3}>Assunto <span style={{ fontWeight: 'bold', color: '#e00808' }}>*</span></Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    id="assunto"
                    value={this.state.assunto}
                    onChange={this.onChangeInput}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="exampleEmail" sm={3}>Descrição<span style={{ fontWeight: 'bold', color: '#e00808' }}>*</span></Label>
                <Col sm={9}>
                  <Input type="textarea"
                    id="descricao"
                    value={this.state.descricao}
                    onChange={this.onChangeInput}
                    rows={4}
                  />
                </Col>
              </FormGroup>
            </Form>


          </ModalBody>
          <ModalFooter>
            <ToastConsumer>
              {
                ({ show, hide }) => (
                  <Button color="primary" onClick={() => this.onSubmit({ toast: { show, hide } })}>Salvar</Button>
                )
              }
            </ToastConsumer>
            <Button color="secondary" onClick={this.props.toggleModalPendenciaEditar}>Cancelar</Button>
          </ModalFooter>
        </Modal>



      </Fragment>
    )
  }
}

export default EditarPendencia;
