import React, { Component } from "react";
import api from "../../services/api";
import validator from "validator";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane, Label } from "reactstrap";
import "./Requisito.css";
import axios from "axios";
import Select from "react-select";
import { ToastConsumer } from "react-awesome-toasts";
import moment from "moment";
import AsyncSelect from "react-select/async";
import InputMask from 'react-input-mask';

class Requisito extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputClient = this.onChangeInputClient.bind(this);
    this.onChangeInputContact = this.onChangeInputContact.bind(this);
    this.onChangeInputProject = this.onChangeInputProject.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.loadProjetos = this.loadProjetos.bind(this);
    this.loadData = this.loadData.bind(this);
    this.validate = this.validate.bind(this);
    this.carregaDados = this.carregaDados.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.loadEtapas = this.loadEtapas.bind(this);
    this.onChangeEtapa = this.onChangeEtapa.bind(this);
    this.promiseOptionsSprints = this.promiseOptionsSprints.bind(this);
    this.transformDataComboBoxSprint = this.transformDataComboBoxSprint.bind(this);
    this.loadSprints = this.loadSprints.bind(this);
    this.onChangeSprint = this.onChangeSprint.bind(this);

    
    this.promiseOptionsModulos = this.promiseOptionsModulos.bind(this);
    this.loadModulos = this.loadModulos.bind(this);
    this.onChangeModulo = this.onChangeModulo.bind(this);
    this.transformDataComboBoxModulo = this.transformDataComboBoxModulo.bind(this);

    this.promiseOptionsTelas = this.promiseOptionsTelas.bind(this);
    this.loadTelas = this.loadTelas.bind(this);
    this.onChangeTela = this.onChangeTela.bind(this);
    this.transformDataComboBoxTela = this.transformDataComboBoxTela.bind(this);
    this.onChangePrioridade = this.onChangePrioridade.bind(this);

    this.state = {
      cliente: null,
      contato: null,
      projeto: null,
      tipo: "Customização",
      titulo: "",
      descricao: "",
      prioridade: "Baixa",
      projetos: [], //LISTA DE PROJETOS
      clientes: [], //LISTA DE CLIENTES
      contatos: [], //LISTA DE CONTATOS APÓS SELECIONAR O CLIENTE
      errors: {
        cliente: false,
        titulo: false,
        descricao: false,
        projeto: false,
      },
      permitidoAlterarCliente: true,
      idPreTicket: null,
      activeTab: 'dados', // dados, etapas, sprintHoras
      etapasEstoria: [],
      sprints:[],
      sprintSelecionada: null,
      qtdeTotalCob: "",
      qtdeTotalOrc: "",
      telaSelecionada: null,
      moduloSelecionado: null,
      prioridadeSelecionada: 3,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    await this.loadClients();
    await this.loadProjetos();
    await this.carregaDados();
    await this.loadEtapas();
  }

  async carregaDados() {
    let preTicket = localStorage.getItem("preTicket");
    // localStorage.removeItem("preTicket")
    if (preTicket !== null) {
      preTicket = JSON.parse(preTicket);
      this.setState(
        {
          titulo: preTicket.assunto,
          descricao: preTicket.descricao,
          prioridade: preTicket.prioridade,
          idPreTicket: preTicket._id,
        },
        () => {
          let clientes = this.state.clientes;

          let clienteFind = clientes.find(
            (it) => it.id === preTicket.cliente.id
          );

          if (clienteFind !== undefined) {
            clienteFind.contatos = clienteFind.contatos.map((contato) => {
              contato.value = contato.descricao + " - " + contato.fone;
              contato.label = contato.descricao + " - " + contato.fone;
              return contato;
            });

            let contatoFind = clienteFind.contatos.find(
              (it) => it.id === preTicket.contato.id
            );

            this.setState(
              {
                cliente: clienteFind,
                contatos: clienteFind.contatos,
                permitidoAlterarCliente: false,
              },
              () => {
                if (contatoFind !== undefined) {
                  this.setState({ contato: contatoFind });
                }
              }
            );
          }
        }
      );
    }
  }

  async loadClients() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/clientes`, this.props.parameters())
      .then((response) => {
        let clientes = response.data.clientes;
        clientes = clientes.map((cliente) => {
          cliente.descricao = cliente.fantasia;
          cliente.value = cliente.descricao;
          cliente.label = cliente.descricao;
          return cliente;
        });
        this.setState({ clientes: clientes });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  async loadProjetos() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/projetos`, this.props.parameters())
      .then((response) => {
        let projetos = response.data.projetos;
        projetos = projetos.map((projeto) => {
          projeto.descricao = projeto.nome;
          projeto.value = projeto.codigo;
          projeto.label = projeto.nome;
          return projeto;
        });
        this.setState({ projetos: projetos });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeInputClient(cliente) {
    let client = Object.assign({}, cliente);
    client.contatos = client.contatos.map((contato) => {
      contato.value = contato.descricao + " - " + contato.fone;
      contato.label = contato.descricao + " - " + contato.fone;
      return contato;
    });

    this.setState({
      cliente: client,
      contatos: client.contatos,
      contato: client.contatos[0],
    });
  }

  onChangeInputContact(contact) {
    this.setState({
      contato: contact,
    });
  }

  onChangeInputProject(projeto) {
    this.setState({
      projeto: projeto,
    });
  }

  onChangeInput(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async validate() {
    let isValid = true;
    let errors = this.state.errors;

    if (this.state.cliente === null) {
      errors.cliente = true;
      await this.setState({ errors });
      isValid = false;
      return false;
    }

    if (!this.state.cliente.descricao) {
      errors.cliente = true;
      isValid = false;
    } else {
      errors.cliente = false;
    }

    if (!this.state.projeto) {
      errors.projeto = true;
      isValid = false;
    } else {
      errors.projeto = false;
    }

    if (validator.isEmpty(this.state.titulo, [{ ignore_whitespace: true }])) {
      errors.titulo = true;
      isValid = false;
    } else {
      errors.titulo = false;
    }
    if (
      validator.isEmpty(this.state.descricao, [{ ignore_whitespace: true }])
    ) {
      errors.descricao = true;
      isValid = false;
    } else {
      errors.descricao = false;
    }

    await this.setState({ errors });
    return isValid;
  }

  async onSubmit({ toast }, manterDados) {
    let contatoTicket = {
      id: this.state.contato.id,
      descricao: this.state.contato.descricao,
      email: this.state.contato.email,
      fone: this.state.contato.fone,
      setor: this.state.contato.setor,
      emailMarketing: this.state.contato.emailMarketing,
      isPrincipal: this.state.contato.isPrincipal,
      label: this.state.contato.label,
      value: this.state.contato.value,
      usaFranquia: this.state.contato.usaFranquia,
    }

    const toastProps = {
      text: "Requisito criado com sucesso",
      actionText: "Fechar",
      ariaLabel: "Requisito criado com sucesso, clique para fechar",
    };

    let isValid = await this.validate();
    if (!isValid) return false;

    let localClient = Object.assign({}, this.state.cliente);
    delete localClient.contatos;
    delete localClient.fantasia;
    delete localClient.status;
    delete localClient.type;
    delete localClient.value;
    delete localClient.label;
    delete localClient.codigo;

    // let localContato = Object.assign({}, this.state.contato);
    // delete localContato.setor;
    // delete localContato.emailMarketing;
    // delete localContato.isPrincipal;
    // delete localContato.usaFranquia;
    // delete localContato.value;
    // delete localContato.label;

    // alert(JSON.stringify(localContato))

    let localProjeto = Object.assign({}, this.state.projeto);

    let prioridade = this.state.prioridade;
    if (prioridade === "Alta") {
      prioridade = 0;
    }
    if (prioridade === "Média") {
      prioridade = 1;
    }
    if (prioridade === "Baixa") {
      prioridade = 2;
    }

    let etapasEstoria = this.state.etapasEstoria.filter((it) => it.selecionado);

    let objectToSend = {
      tipo: this.state.tipo,
      cliente: localClient,
      contato: {
        id: this.state.contato.id,
        descricao: this.state.contato.descricao,
        email: this.state.contato.email,
        fone: this.state.contato.fone,
      },
      idProjeto: localProjeto.id,
      idResponsavel: localProjeto.responsavel,
      titulo: this.state.titulo,
      descricao: this.state.descricao,
      prioridade: prioridade,
    };

    if(etapasEstoria.length > 0){
      etapasEstoria = etapasEstoria.map((it) => {
        return {
          _id: it._id,
          id: it.id,
          descricao: it.descricao
        }
      })
      objectToSend.etapasEstoria = etapasEstoria;
    }

    if(this.state.sprintSelecionada !== null){
      objectToSend.idSprint = this.state.sprintSelecionada.id;
    }

    if(this.state.qtdeTotalCob !== ""){
      let qtdeTotalCob = this.state.qtdeTotalCob;
      if(qtdeTotalCob.indexOf('_') !== -1){
        alert('Formato inválido para o total de horas cobradas.')
        return false;
      }

      let horas = parseInt(qtdeTotalCob.split(':')[0]);
      let minutos = parseInt(qtdeTotalCob.split(':')[1]);

      if(isNaN(horas) || isNaN(minutos)){
        alert('Formato inválido para o total de horas cobradas.')
        return false;
      }
      objectToSend.qtdeTotalCob = (horas * 60) + minutos;
    }

    if(this.state.qtdeTotalOrc !== ""){
      let qtdeTotalOrc = this.state.qtdeTotalOrc;
      if(qtdeTotalOrc.indexOf('_') !== -1){
        alert('Formato inválido para o total de horas orçadas.')
        return false;
      }

      let horas = parseInt(qtdeTotalOrc.split(':')[0]);
      let minutos = parseInt(qtdeTotalOrc.split(':')[1]);

      if(isNaN(horas) || isNaN(minutos)){
        alert('Formato inválido para o total de horas orçadas.')
        return false;
      }
      objectToSend.qtdeTotalOrc = (horas * 60) + minutos;
    }

    if(this.state.moduloSelecionado !== null){
      objectToSend.modulo = this.state.modulo;
    }

    if(this.state.telaSelecionada !== null){
      objectToSend.tela = this.state.tela;
    }

    objectToSend['prioridade2'] = this.state.prioridadeSelecionada;

    await api
      .post("requisitos", { requisito: objectToSend }, this.props.parameters())
      .then(async (res) => {
        toast.show({ ...toastProps, onActionClick: toast.hide });

        if (manterDados) {  
          this.setState({
            titulo: "",
            descricao: "",
            errors: {
              cliente: false,
              titulo: false,
              descricao: false,
              projeto: false,
            },
            activeTab: "dados"
          });
        } else {
          this.setState({
            cliente: null,
            contato: null,
            tipo: "Customização",
            titulo: "",
            descricao: "",
            prioridade: "Baixa",
            errors: {
              cliente: false,
              titulo: false,
              descricao: false,
              projeto: false,
            },
            projeto: null,
            etapasEstoria: this.state.etapasEstoria.map((it) => {return {...it, selecionado: false}}),
            activeTab: "dados",
            sprintSelecionada: null,
            qtdeTotalCob: "",
            qtdeTotalOrc: "",
          });
        }

        if (this.state.idPreTicket !== null) {
          let obj = {
            apontamento: {
              horaInicial: moment().toDate(),
              horaFinal: moment().add(1, 'minutes').toDate(),
              descricao: `TICKET FINALIZADO AUTOMATICAMENTE APÓS GERAÇÃO DE REQUISITO ${res.data.codigo}`,
              tipoEstoria: {
                id: 114,
                codigo: "1.14",
                descricao: "INTERNO NÃO COBRADO",
              },
              usuarioCriacao: {
                _id: "f3f18a3c230df522e4ac69c00d05bfe8",
                id: 11,
                codigo: "1.1",
                descricao: "PRIORI",
              },
              usuarioCancelamento: null,
              status: 0,
              ordem: 0,
              contato: contatoTicket,
              tempoTotal: { minutos: 1, segundos: 0 },
            },
            finalizacaoSucesso: true,
            projeto: null,
            finalizarPreTicket: true
          };

          await api
            .post(
              `atendimento/finalizar/${this.state.idPreTicket}/0`,
              obj,
              this.props.parameters()
            )
            .then((res) => {});
        }
      });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  async loadEtapas() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/etapas-estoria`, this.props.parameters())
      .then((response) => {
        let etapas = response.data.etapas;

        for(let etapa of etapas){
          etapa.selecionado = false;
        }
      
       this.setState({ etapasEstoria: etapas });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeEtapa(_id, isChecked){
    let etapas = this.state.etapasEstoria;
    for(let etapa of etapas){
      if(_id === etapa._id){
        etapa.selecionado = isChecked
      }
    }
    this.setState({etapasEstoria: etapas})
  }

  promiseOptionsSprints(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let sprints = await this.loadSprints();
          sprints = this.transformDataComboBoxSprint(sprints);
          this.setState({ sprints });
          resolve(sprints);
        } else {
          // let aux = inputValue.substr(0, 1);
          // let len = 3;
          //if (aux == "&" || aux == "%") len = 3;
          if (inputValue.length >= 3) {
            let sprints = await this.loadSprints(inputValue);
            sprints = this.transformDataComboBoxSprint(sprints);
            this.setState({ sprints });
            resolve(sprints);
          } else {
            resolve(this.state.sprints);
          }
        }
      }, 100);
    });
  }

  transformDataComboBoxSprint(lista) {
    lista = lista.map((item) => {
      item.value = item.id;
      item.label = item.descFormat;
      return item;
    });
    return lista;
  }

  async loadSprints(busca = null) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/sprints-backlog`,
          { busca },
          this.props.parameters()
        )
        .then((response) => {
          let sprints = response.data.sprints;
          resolve(sprints);
        })
        .catch((err) => this.props.handlingErrorsApi(err, this.props));
    });
  }

  onChangeSprint(sprintSelecionada) {
    this.setState({ sprintSelecionada });
  }

  promiseOptionsModulos(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let modulos = await this.loadModulos();
          modulos = this.transformDataComboBoxModulo(modulos);
          this.setState({ modulos });
          resolve(modulos);
        } else {
          // let aux = inputValue.substr(0, 1);
          // let len = 3;
          //if (aux == "&" || aux == "%") len = 3;
          if (inputValue.length >= 3) {
            let modulos = await this.loadModulos(inputValue);
            modulos = this.transformDataComboBoxModulo(modulos);
            this.setState({ modulos });
            resolve(modulos);
          } else {
            resolve(this.state.modulos);
          }
        }
      }, 100);
    });
  }

  async loadModulos(busca = null) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/modulos`,
          { busca },
          this.props.parameters()
        )
        .then((response) => {
          let modulos = response.data.modulos;
          resolve(modulos);
        })
        .catch((err) => this.props.handlingErrorsApi(err, this.props));
    });
  }

  onChangeModulo(moduloSelecionado) {
    this.setState({ moduloSelecionado });
  }

  transformDataComboBoxModulo(lista) {
    lista = lista.map((item) => {
      item.value = item.id;
      item.label = item.descFormat;
      return item;
    });
    return lista;
  }


  promiseOptionsTelas(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let telas = await this.loadTelas();
          telas = this.transformDataComboBoxTela(telas);
          this.setState({ telas });
          resolve(telas);
        } else {
          // let aux = inputValue.substr(0, 1);
          // let len = 3;
          //if (aux == "&" || aux == "%") len = 3;
          if (inputValue.length >= 3) {
            let telas = await this.loadTelas(inputValue);
            telas = this.transformDataComboBoxTela(telas);
            this.setState({ telas });
            resolve(telas);
          } else {
            resolve(this.state.telas);
          }
        }
      }, 100);
    });
  }

  async loadTelas(busca = null) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/telas`,
          { busca },
          this.props.parameters()
        )
        .then((response) => {
          let telas = response.data.telas;
          resolve(telas);
        })
        .catch((err) => this.props.handlingErrorsApi(err, this.props));
    });
  }

  onChangeTela(telaSelecionada) {
    this.setState({ telaSelecionada });
  }

  transformDataComboBoxTela(lista) {
    lista = lista.map((item) => {
      item.value = item.id;
      item.label = item.descFormat;
      return item;
    });
    return lista;
  }

  onChangePrioridade(value){
    this.setState({prioridadeSelecionada: parseInt(value)})
  }

  render() {
    return (
      <div>
        <div id="contentSite">
          <div className="container">
            <div className="Example_box">
              <div className="Example_box-content">
                <div
                  className="col-sm-9"
                  style={{ paddingTop: "15px" }}
                >

                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={{ active: this.state.activeTab === "dados" }}
                        onClick={() => {
                          this.toggleTab("dados");
                        }}
                      >
                        Dados
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={{
                          active: this.state.activeTab === "etapas",
                        }}
                        onClick={() => {
                          this.toggleTab("etapas");
                        }}
                      >
                        Etapas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={{
                          active: this.state.activeTab === "sprintHoras",
                        }}
                        onClick={() => {
                          this.toggleTab("sprintHoras");
                        }}
                      >
                        Sprint/Horas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={{
                          active: this.state.activeTab === "observacaoInterna",
                        }}
                        onClick={() => {
                          this.toggleTab("observacaoInterna");
                        }}
                      >
                        Observação Interna
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="dados">
                      <form>
                        <div className="form-row" style={{paddingTop:"10px"}}>
                          <div className="form-group col-md-6">
                            <label htmlFor="inputEmail4" className="label-form">
                              Tipo
                            </label>
                            <select
                              className="form-control"
                              id="tipo"
                              value={this.state.tipo}
                              onChange={this.onChangeInput}
                            >
                              <option value="Customização">Customização</option>
                              <option value="Adequação Legal">
                                Adequação Legal
                              </option>
                              <option value="Não Conformidade">
                                Não Conformidade
                              </option>
                            </select>
                          </div>
                          <div className="form-group col-md-6">
                            <label
                              htmlFor="inputPassword4"
                              className="label-form"
                            >
                              Cliente
                            </label>
                            <Select
                              value={this.state.cliente}
                              onChange={this.onChangeInputClient}
                              options={this.state.clientes}
                              focus={false}
                              className={
                                this.state.errors.cliente ? "is-invalid" : ""
                              }
                              isDisabled={!this.state.permitidoAlterarCliente}
                            />
                            {this.state.errors.cliente && (
                              <div className="is-invalid-feedback">
                                Por favor selecione um cliente.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label
                              htmlFor="inputAddress"
                              className="label-form"
                            >
                              Contatos
                            </label>
                            {this.state.contatos.length ? (
                              <Select
                                value={this.state.contato}
                                onChange={this.onChangeInputContact}
                                options={this.state.contatos}
                              />
                            ) : (
                              <select
                                className="form-control"
                                id="contato"
                                disabled={true}
                              />
                            )}
                          </div>
                          <div className="form-group col-md-6">
                            <label
                              htmlFor="inputAddress"
                              className="label-form"
                            >
                              Projeto
                            </label>
                            {this.state.projetos.length ? (
                              <Select
                                value={this.state.projeto}
                                onChange={this.onChangeInputProject}
                                options={this.state.projetos}
                              />
                            ) : (
                              <select
                                className="form-control"
                                id="contato"
                                disabled={true}
                              />
                            )}

                            {this.state.errors.projeto && (
                              <div className="is-invalid-feedback">
                                Por favor selecione um projeto.
                              </div>
                            )}
                          </div>
                          {/* <div className="form-group col-md-6">
                    <label htmlFor="inputAddress2" className="label-form">
                      Prioridade
                    </label>
                    <select
                      className="form-control"
                      id="prioridade"
                      value={this.state.prioridade}
                      onChange={this.onChangeInput}
                    >
                      <option value="Baixa">Baixa</option>
                      <option value="Média">Média</option>
                      <option value="Alta">Alta</option>
                    </select>
                  </div> */}
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label htmlFor="inputCity" className="label-form">
                              Título
                            </label>
                            <input
                              type="text"
                              className={
                                this.state.errors.titulo
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              id="titulo"
                              name="titulo"
                              onChange={this.onChangeInput}
                              value={this.state.titulo}
                            />
                            {this.state.errors.titulo && (
                              <div className="is-invalid-feedback">
                                Por favor digite um título.
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label htmlFor="inputCity" className="label-form">
                              Descrição
                            </label>
                            <textarea
                              id="descricao"
                              value={this.state.descricao}
                              onChange={this.onChangeInput}
                              rows={4}
                              className={
                                this.state.errors.descricao
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {this.state.errors.descricao && (
                              <div className="is-invalid-feedback">
                                Por favor digite uma descrição.
                              </div>
                            )}
                          </div>
                        </div>

                       
                      </form>
                    </TabPane>

                    <TabPane tabId="etapas">

                      <table className="table table-bordered table-striped table-hover" style={{fontSize:"14px", marginTop:"10px"}}>
                        <thead className="thead-dar-custom thead-site">
                          <th className="text-center" style={{width:"150px", maxWidth:"150px"}}>Selecionado ?</th>
                          <th>Etapa</th>
                        </thead>
                        <tbody>
                          {this.state.etapasEstoria.map((etapa) => (
                            <tr key={etapa._id}>
                              <td className="text-center">
                                <input 
                                  type="checkbox" 
                                  checked={etapa.selecionado} 
                                  onChange={(e) => {this.onChangeEtapa(etapa._id, e.target.checked)}}
                                />
                              </td>
                              <td>{etapa.descricao}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </TabPane>

                    <TabPane tabId="sprintHoras">
                      <form>
                        <div
                          className="form-row"
                          style={{ paddingTop: "10px", paddingBottom:"10px" }}
                        >
                          <div className="form-group col-md-12">
                            <label htmlFor="inputEmail4" className="label-form">
                              Sprint
                            </label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={this.promiseOptionsSprints}
                              value={this.state.sprintSelecionada}
                              onChange={(item) => this.onChangeSprint(item)}
                              placeholder="Selecione"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label
                              htmlFor="inputPassword4"
                              className="label-form"
                            >
                              Horas Cobradas
                            </label>
                            <InputMask
                              mask="99:99"
                              value={this.state.qtdeTotalCob}
                              onChange={(e) => {
                                this.setState({ qtdeTotalCob: e.target.value });
                              }}
                              placeholder="HH:MM"
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label
                              htmlFor="inputPassword4"
                              className="label-form"
                            >
                              Horas Orçadas
                            </label>
                            <InputMask
                              mask="99:99"
                              value={this.state.qtdeTotalOrc}
                              onChange={(e) => {
                                this.setState({ qtdeTotalOrc: e.target.value });
                              }}
                              placeholder="HH:MM"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </form>
                    </TabPane>
                    <TabPane tabId="observacaoInterna">
                    <div className='row' style={{marginBottom:"50px"}}>
                                <div className='col-4'>
                                  <div className="form-group" style={{paddingTop:"20px"}}>
                                    <Label>Módulo:</Label>
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={this.promiseOptionsModulos}
                                      value={this.state.moduloSelecionado}
                                      onChange={(item) => this.onChangeModulo(item)}
                                      placeholder="Selecione"
                                    />
                                  </div>
                                </div>
                                <div className='col-4'>
                                  <div className="form-group" style={{paddingTop:"20px"}}>
                                    <Label>Tela:</Label>
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={this.promiseOptionsTelas}
                                      value={this.state.telaSelecionada}
                                      onChange={(item) => this.onChangeTela(item)}
                                      placeholder="Selecione"
                                    />
                                  </div>
                                </div>
                                <div className='col-4'>
                                  <div className="form-group" style={{paddingTop:"20px"}}>
                                    <Label>Prioridade:</Label>
                                    <select className='form-control' value={this.state.prioridadeSelecionada} onChange={(e) => {
                                      this.onChangePrioridade(e.target.value)
                                    }}>
                                      <option value="0">P0</option>
                                      <option value="1">P1</option>
                                      <option value="2">P2</option>
                                      <option value="3">P3</option>
                                    </select>
                                  </div>
                                </div>

                              </div>
                    </TabPane>

                  </TabContent>

                  <ToastConsumer>
                          {({ show, hide }) => (
                            <React.Fragment>
                              <Button
                                type="button"
                                style={{ margin: "5px 5px 5px 0px" }}
                                color="success"
                                onClick={() =>
                                  this.onSubmit(
                                    { toast: { show, hide } },
                                    false
                                  )
                                }
                              >
                                <i
                                  className="fa fa-floppy-o"
                                  aria-hidden="true"
                                ></i>{" "}
                                Salvar/Novo
                              </Button>
                              <Button
                                type="button"
                                style={{ margin: "5px" }}
                                color="success"
                                onClick={() =>
                                  this.onSubmit({ toast: { show, hide } }, true)
                                }
                              >
                                <i
                                  className="fa fa-floppy-o"
                                  aria-hidden="true"
                                ></i>{" "}
                                Salvar/Similar
                              </Button>
                            </React.Fragment>
                          )}
                        </ToastConsumer>

                        <Button
                          type="button"
                          style={{ margin: "5px" }}
                          onClick={() => this.props.history.push("/home")}
                        >
                          <i
                            className="fa fa-arrow-circle-o-left"
                            aria-hidden="true"
                          ></i>{" "}
                          Voltar
                        </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Requisito;
