import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  UncontrolledTooltip
} from "reactstrap";
import "./Notificacao.css";
import axios from "axios";
import moment from "moment";

class Notificacao extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.loadNotificacoes = this.loadNotificacoes.bind(this);
    this.onClickArquivar = this.onClickArquivar.bind(this);
    this.calculaTempo = this.calculaTempo.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.defineTipoIcone = this.defineTipoIcone.bind(this);

    this.state = {
      notificacoes: [],
      notificacoesAnteriores: [],
      notificacoesArquivadas: [],
      activeTab: "1",
      existeNotificacao: false
    };
  }

  async componentDidMount() {
    localStorage.setItem("existeNotificacao", false);
    await this.loadData();
    let notificacoes = this.state.notificacoes
      .concat(this.state.notificacoesAnteriores)
      .concat(this.state.notificacoesArquivadas);
    notificacoes = notificacoes.filter(not => {
      if (not.visualizado !== true) {
        return not._id;
      }
    });

    if (notificacoes.length) {
      notificacoes = notificacoes.map(notificacao => notificacao._id)
      this.notificacoesVisualizar(notificacoes);
    }
  }

  async loadData() {
    await this.loadNotificacoes(true);
  }

  async loadNotificacoes(executarInterval = false) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/notificacoes`,
        this.props.parameters()
      )
      .then(response => {
        let {
          notificacoes,
          notificacoesAnteriores,
          notificacoesArquivadas
        } = response.data;

        for (let notificacao of notificacoes) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.iconeDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        for (let notificacao of notificacoesAnteriores) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.iconeDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        for (let notificacao of notificacoesArquivadas) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.urlDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        let existeNotificacao = this.state.existeNotificacao;

        if (notificacoes.length > 0 || notificacoesAnteriores.length > 0) {
          existeNotificacao = true;
        }

        this.setState(
          {
            notificacoes,
            notificacoesAnteriores,
            notificacoesArquivadas,
            existeNotificacao
          },
          () => {
            if (executarInterval) {
              setInterval(() => {
                let notificacoesMinuto = this.state.notificacoes;
                let notificacoesAnterioresMinuto = this.state
                  .notificacoesAnteriores;
                let notificacoesArquivadasMinuto = this.state
                  .notificacoesArquivadas;
                for (let notificacao of notificacoesMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
                    notificacao.tipo
                  );
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                for (let notificacao of notificacoesAnterioresMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
                    notificacao.tipo
                  );
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                for (let notificacao of notificacoesArquivadasMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
                    notificacao.tipo
                  );
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                this.setState({
                  notificacoes: notificacoesMinuto,
                  notificacoesAnteriores: notificacoesAnterioresMinuto,
                  notificacoesArquivadas: notificacoesArquivadasMinuto
                });
              }, 60000); // 1 minuto
            }
          }
        );
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  async onClickArquivar(idNotificacao = null) {
    let listaNotificacoes = [];

    if (idNotificacao === null) {
      let lista = this.state.notificacoes.concat(
        this.state.notificacoesAnteriores
      );

      listaNotificacoes = lista.map(item => item._id);
    } else {
      listaNotificacoes.push(idNotificacao);
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/notificacoes/arquivar`,
        { listaNotificacoes },
        this.props.parameters()
      )
      .then(response => {
        this.loadNotificacoes();
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));

    return true;
  }

  calculaTempo(dataEmissao) {
    let dataAtual = moment().utc();
    let dataNotificacao = moment(dataEmissao).utc();
    let ms = dataAtual.diff(dataNotificacao);
    let duration = moment.duration(ms);
    let totalMinutos = Math.floor(duration.asMinutes());

    let totalHoras = 0;
    let totalDias = 0;

    if (totalMinutos === 0) {
      return `Há alguns instantes`;
    }

    if (totalMinutos > 59) {
      totalHoras = parseInt(totalMinutos / 60);
    } else {
      return `Há ${totalMinutos} ${totalMinutos === 1 ? "minuto" : "minutos"}`;
    }

    if (totalHoras > 23) {
      totalDias = parseInt(totalHoras / 24);
      return `Há ${totalDias} ${totalDias === 1 ? "dia" : "dias"}`;
    } else {
      return `Há ${totalHoras} ${totalHoras === 1 ? "hora" : "horas"}`;
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  defineTipoIcone(tipo) {
    let icone = "";
    let iconeDescricao = "";
    let urlDescricao = "";

    if (tipo === "novoComentario") {
      icone = "fa-commenting";
      iconeDescricao = "Comentário";
      urlDescricao = "Abrir Ticket";
    } else if (tipo === "contestacaoAvaliada") {
      icone = "fa-exclamation-circle";
      iconeDescricao = "Contestação";
      urlDescricao = "Abrir Ticket";
    } else if (
      tipo === "requisitoAprovacao" ||
      tipo === "requisitoCancelamento"
    ) {
      icone = "fa-list-ul";
      iconeDescricao = "Requisito";
      urlDescricao = "Abrir Requisito";
    } else {
      icone = "fa-ticket";
      iconeDescricao = "Ticket";
      urlDescricao = "Abrir Ticket";
    }
    return { icone, iconeDescricao, urlDescricao };
  }

  async notificacoesVisualizar(listaNotificacoes) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/notificacoes/visualizar`,
        { listaNotificacoes },
        this.props.parameters()
      )
      .then(response => {})
      .catch(err => this.props.handlingErrorsApi(err, this.props));

    return true;
  }

  render() {
    return (
      <div>
        <div id="contentSite">
          <div className="container">
            <div
              className="offset-sm-1 col-sm-9"
              style={{ paddingTop: "15px" }}
            >
              <Nav tabs>
                <NavItem style={{ cursor: "pointer" }}>
                  <NavLink
                    className={{ active: this.state.activeTab === "1" }}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    Notificações
                  </NavLink>
                </NavItem>
                <NavItem style={{ cursor: "pointer" }}>
                  <NavLink
                    className={{ active: this.state.activeTab === "2" }}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    Arquivadas
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {this.state.notificacoes.length === 0 &&
                    this.state.notificacoesAnteriores.length === 0 && (
                      <div className="nenhuma-notificacao">
                        Você ainda não possui nenhuma notificação
                      </div>
                    )}

                  {this.state.notificacoes.length > 0 && (
                    <React.Fragment>
                      <div className="box-notificacao">
                        <div className="header-notificacao">
                          <span>Hoje</span>
                        </div>

                        {this.state.notificacoes.length > 0 &&
                          this.state.notificacoes.map(notificacao => (
                            <div
                              className="item-notificacao"
                              key={notificacao._id}
                            >
                              <div className="aha">
                                <div className="tipo-notificacao">
                                  <i
                                    className={`fa ${notificacao.icone}`}
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "16px",
                                      marginRight: "5px"
                                    }}
                                  ></i>{" "}
                                  {notificacao.iconeDescricao}
                                </div>

                                <div className="titulo-notificacao">
                                  {notificacao.titulo}
                                </div>
                                <div
                                  style={{
                                    marginBottom: "10px"
                                  }}
                                >
                                  <div style={{ float: "left", width: "100%" }}>
                                    {notificacao.descricao}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        color: "#6f7782"
                                      }}
                                    >
                                      {" "}
                                      {notificacao.tempo}
                                    </span>
                                  </div>
                                </div>
                                {notificacao.url !== null && (
                                  <div
                                    className="link-notificacao"
                                    onClick={() =>
                                      this.props.history.push(
                                        `/${notificacao.url}`
                                      )
                                    }
                                  >
                                    {notificacao.urlDescricao}
                                  </div>
                                )}
                              </div>

                              <div
                                className="aha2"
                                onClick={() =>
                                  this.onClickArquivar(notificacao._id)
                                }
                                id={`btn-arquivar-${notificacao._id}`}
                              >
                                <div>
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                  <UncontrolledTooltip
                                    placement="right"
                                    target={`btn-arquivar-${notificacao._id}`}
                                  >
                                    Arquivar notificação
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </React.Fragment>
                  )}

                  {this.state.notificacoesAnteriores.length > 0 && (
                    <React.Fragment>
                      <div
                        className="box-notificacao"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="header-notificacao">
                          <span>Anteriores</span>
                        </div>
                        {this.state.notificacoesAnteriores.map(notificacao => (
                          <div
                            className="item-notificacao"
                            key={notificacao._id}
                          >
                            <div className="aha">
                              <div className="tipo-notificacao">
                                <i
                                  className={`fa ${notificacao.icone}`}
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "16px",
                                    marginRight: "5px"
                                  }}
                                ></i>{" "}
                                {notificacao.iconeDescricao}
                              </div>

                              <div className="titulo-notificacao">
                                {notificacao.titulo}
                              </div>
                              <div
                                style={{
                                  marginBottom: "10px"
                                }}
                              >
                                <div style={{ float: "left", width: "100%" }}>
                                  {notificacao.descricao}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "#6f7782"
                                    }}
                                  >
                                    {" "}
                                    {notificacao.tempo}
                                  </span>
                                </div>
                              </div>
                              {notificacao.url !== null && (
                                <div
                                  className="link-notificacao"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/${notificacao.url}`
                                    )
                                  }
                                >
                                  {notificacao.urlDescricao}
                                </div>
                              )}
                            </div>

                            <div
                              className="aha2"
                              id={`btn-arquivar-${notificacao._id}`}
                              onClick={() =>
                                this.onClickArquivar(notificacao._id)
                              }
                            >
                              <div>
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  style={{ fontSize: "16px" }}
                                ></i>
                                <UncontrolledTooltip
                                  placement="right"
                                  target={`btn-arquivar-${notificacao._id}`}
                                >
                                  Arquivar notificação
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  )}

                  {this.state.existeNotificacao && (
                    <div
                      className="arquivar-todos-notificacao"
                      onClick={() => this.onClickArquivar()}
                    >
                      Arquivar todas as notificações
                    </div>
                  )}
                </TabPane>

                <TabPane tabId="2">
                  {this.state.notificacoesArquivadas.length === 0 && (
                    <div className="nenhuma-notificacao">
                      Você ainda não arquivou nenhuma notificação
                    </div>
                  )}
                  {this.state.notificacoesArquivadas.length > 0 && (
                    <React.Fragment>
                      <div
                        className="box-notificacao"
                        style={{ marginBottom: "40px" }}
                      >
                        <div className="header-notificacao">
                          <span>Arquivadas</span>
                        </div>

                        {this.state.notificacoesArquivadas.map(notificacao => (
                          <div
                            className="item-notificacao"
                            key={notificacao._id}
                          >
                            <div className="aha">
                              <div className="tipo-notificacao">
                                <i
                                  className={`fa ${notificacao.icone}`}
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "16px",
                                    marginRight: "5px"
                                  }}
                                ></i>{" "}
                                {notificacao.iconeDescricao}
                              </div>

                              <div className="titulo-notificacao">
                                {notificacao.titulo}
                              </div>
                              <div
                                style={{
                                  marginBottom: "10px"
                                }}
                              >
                                <div style={{ float: "left", width: "100%" }}>
                                  {notificacao.descricao}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "#6f7782"
                                    }}
                                  >
                                    {" "}
                                    {notificacao.tempo}
                                  </span>
                                </div>
                              </div>
                              {notificacao.url !== "" && (
                                <div
                                  className="link-notificacao"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/${notificacao.url}`
                                    )
                                  }
                                >
                                  {notificacao.urlDescricao}
                                </div>
                              )}
                            </div>

                            <div
                              className="aha2"
                              onClick={() =>
                                this.onClickArquivar(notificacao._id)
                              }
                              id={`btn-arquivar-${notificacao._id}`}
                            >
                              <div>
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  style={{ fontSize: "16px" }}
                                ></i>
                                <UncontrolledTooltip
                                  placement="right"
                                  target={`btn-arquivar-${notificacao._id}`}
                                >
                                  Ativar notificação
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  )}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Notificacao;
