import Cronograma from './Cronograma';
import CreateTicket from './CreateTicket';
import DetailTicket from './DetailTicket';
import Login from './Login';
import Home from './Home';
import EditTicket from './EditTicket';
import Requisito from './Requisito';
import Home1 from './Home1';
import Historico from './Historico';
import Notificacao from './Notificacao';
import Config from './Config';
import DetailPreTicket from './DetailPreTicket';
import EditPreTicket from './EditPreTicket';

export { Cronograma, CreateTicket, DetailTicket, Login, Home, EditTicket, Requisito, Home1, Historico, Notificacao, Config, DetailPreTicket, EditPreTicket };