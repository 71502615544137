import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import Switch from "react-input-switch";
import { ToastConsumer } from "react-awesome-toasts";

class Config extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.loadNotificacoes = this.loadNotificacoes.bind(this);
    this.onClickArquivar = this.onClickArquivar.bind(this);
    this.calculaTempo = this.calculaTempo.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.defineTipoIcone = this.defineTipoIcone.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.handleChangeAnaliseTicket = this.handleChangeAnaliseTicket.bind(this);
    this.handleClickSalvarUsuarios = this.handleClickSalvarUsuarios.bind(this);

    this.state = {
      notificacoes: [],
      notificacoesAnteriores: [],
      notificacoesArquivadas: [],
      activeTab: "1",
      existeNotificacao: false,
      usuarios: [],
    };
  }

  async componentDidMount() {
    localStorage.setItem("existeNotificacao", false);
    await this.loadData();
    let notificacoes = this.state.notificacoes
      .concat(this.state.notificacoesAnteriores)
      .concat(this.state.notificacoesArquivadas);
    notificacoes = notificacoes.filter((not) => {
      if (not.visualizado !== true) {
        return not._id;
      }
    });

    if (notificacoes.length) {
      notificacoes = notificacoes.map((notificacao) => notificacao._id);
      this.notificacoesVisualizar(notificacoes);
    }

    this.loadUsuarios();
  }

  async loadData() {
    await this.loadNotificacoes(true);
  }

  async loadNotificacoes(executarInterval = false) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/notificacoes`,
        this.props.parameters()
      )
      .then((response) => {
        let { notificacoes, notificacoesAnteriores, notificacoesArquivadas } =
          response.data;

        for (let notificacao of notificacoes) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.iconeDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        for (let notificacao of notificacoesAnteriores) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.iconeDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        for (let notificacao of notificacoesArquivadas) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.urlDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        let existeNotificacao = this.state.existeNotificacao;

        if (notificacoes.length > 0 || notificacoesAnteriores.length > 0) {
          existeNotificacao = true;
        }

        this.setState(
          {
            notificacoes,
            notificacoesAnteriores,
            notificacoesArquivadas,
            existeNotificacao,
          },
          () => {
            if (executarInterval) {
              setInterval(() => {
                let notificacoesMinuto = this.state.notificacoes;
                let notificacoesAnterioresMinuto =
                  this.state.notificacoesAnteriores;
                let notificacoesArquivadasMinuto =
                  this.state.notificacoesArquivadas;
                for (let notificacao of notificacoesMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } =
                    this.defineTipoIcone(notificacao.tipo);
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                for (let notificacao of notificacoesAnterioresMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } =
                    this.defineTipoIcone(notificacao.tipo);
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                for (let notificacao of notificacoesArquivadasMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } =
                    this.defineTipoIcone(notificacao.tipo);
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                this.setState({
                  notificacoes: notificacoesMinuto,
                  notificacoesAnteriores: notificacoesAnterioresMinuto,
                  notificacoesArquivadas: notificacoesArquivadasMinuto,
                });
              }, 60000); // 1 minuto
            }
          }
        );
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  async onClickArquivar(idNotificacao = null) {
    let listaNotificacoes = [];

    if (idNotificacao === null) {
      let lista = this.state.notificacoes.concat(
        this.state.notificacoesAnteriores
      );

      listaNotificacoes = lista.map((item) => item._id);
    } else {
      listaNotificacoes.push(idNotificacao);
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/notificacoes/arquivar`,
        { listaNotificacoes },
        this.props.parameters()
      )
      .then((response) => {
        this.loadNotificacoes();
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));

    return true;
  }

  calculaTempo(dataEmissao) {
    let dataAtual = moment().utc();
    let dataNotificacao = moment(dataEmissao).utc();
    let ms = dataAtual.diff(dataNotificacao);
    let duration = moment.duration(ms);
    let totalMinutos = Math.floor(duration.asMinutes());

    let totalHoras = 0;
    let totalDias = 0;

    if (totalMinutos === 0) {
      return `Há alguns instantes`;
    }

    if (totalMinutos > 59) {
      totalHoras = parseInt(totalMinutos / 60);
    } else {
      return `Há ${totalMinutos} ${totalMinutos === 1 ? "minuto" : "minutos"}`;
    }

    if (totalHoras > 23) {
      totalDias = parseInt(totalHoras / 24);
      return `Há ${totalDias} ${totalDias === 1 ? "dia" : "dias"}`;
    } else {
      return `Há ${totalHoras} ${totalHoras === 1 ? "hora" : "horas"}`;
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  defineTipoIcone(tipo) {
    let icone = "";
    let iconeDescricao = "";
    let urlDescricao = "";

    if (tipo === "novoComentario") {
      icone = "fa-commenting";
      iconeDescricao = "Comentário";
      urlDescricao = "Abrir Ticket";
    } else if (tipo === "contestacaoAvaliada") {
      icone = "fa-exclamation-circle";
      iconeDescricao = "Contestação";
      urlDescricao = "Abrir Ticket";
    } else if (
      tipo === "requisitoAprovacao" ||
      tipo === "requisitoCancelamento"
    ) {
      icone = "fa-list-ul";
      iconeDescricao = "Requisito";
      urlDescricao = "Abrir Requisito";
    } else {
      icone = "fa-ticket";
      iconeDescricao = "Ticket";
      urlDescricao = "Abrir Ticket";
    }
    return { icone, iconeDescricao, urlDescricao };
  }

  async notificacoesVisualizar(listaNotificacoes) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/notificacoes/visualizar`,
        { listaNotificacoes },
        this.props.parameters()
      )
      .then((response) => {})
      .catch((err) => this.props.handlingErrorsApi(err, this.props));

    return true;
  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/lista-usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        let usuarios = response.data.usuarios;
        for (let usuario of usuarios) {
          usuario.alterado = false;
        }

        usuarios = usuarios.sort(function(a, b) {
          return a.id - b.id;
        });

        this.setState({ usuarios });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));

    return true;
  }

  handleChangeAnaliseTicket(value, tipo, usuario) {
    let usuarios = this.state.usuarios;
    usuario[tipo] = value === 0 ? false : true;
    usuario.alterado = true;

    for (let user of usuarios) {
      if (user._id === usuario._id) {
        user = usuario;
      }
    }

    this.setState({ usuarios }, () => {});
  }

  async handleClickSalvarUsuarios({ toast }) {
    let usuarios = this.state.usuarios.filter((it) => it.alterado);
    if (usuarios.length === 0) {
      return false;
    }

    usuarios = usuarios.map((it) => {
      return {
        _id: it._id,
        analisaPreTicketSuporte: it.analisaPreTicketSuporte,
        analisaPreTicketConsultoria: it.analisaPreTicketConsultoria,
        analisaPreTicketDesenvolvimento: it.analisaPreTicketDesenvolvimento,
      };
    });

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atualiza-analise-pre-tickets`,
        { usuarios },
        this.props.parameters()
      )
      .then((response) => {
        const toastProps = {
          text: "Usuários atualizados com sucesso!",
          actionText: "Fechar",
          ariaLabel: "Usuários atualizados com sucesso!",
        };

        toast.show({ ...toastProps, onActionClick: toast.hide });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  render() {
    return (
      <div>
        <div id="contentSite">
          <div className="container">
            <div
              className="col-sm-12"
              style={{ paddingTop: "15px", paddingBottom: "100px" }}
            >
              <table
                className="table table-hover table-striped"
                style={{ fontSize: "15px" }}
              >
                <thead className="thead-dark">
                  <tr>
                    <th style={{}}>Código</th>
                    <th style={{}}>Usuário</th>
                    <th style={{ textAlign: "center" }}>Suporte</th>
                    <th style={{ textAlign: "center" }}>Consultoria</th>
                    <th style={{ textAlign: "center" }}>Desenvolvimento</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.usuarios.map((usuario, key) => (
                    <tr key={key}>
                      <td>{usuario.codigo}</td>
                      <td>{usuario.descricao}</td>
                      <td>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Switch
                            value={usuario.analisaPreTicketSuporte ? 1 : 0}
                            onChange={(e) =>
                              this.handleChangeAnaliseTicket(
                                e,
                                "analisaPreTicketSuporte",
                                usuario
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Switch
                            value={usuario.analisaPreTicketConsultoria ? 1 : 0}
                            onChange={(e) =>
                              this.handleChangeAnaliseTicket(
                                e,
                                "analisaPreTicketConsultoria",
                                usuario
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Switch
                            value={
                              usuario.analisaPreTicketDesenvolvimento ? 1 : 0
                            }
                            onChange={(e) =>
                              this.handleChangeAnaliseTicket(
                                e,
                                "analisaPreTicketDesenvolvimento",
                                usuario
                              )
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <ToastConsumer>
                {({ show, hide }) => (
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() =>
                      this.handleClickSalvarUsuarios({ toast: { show, hide } })
                    }
                  >
                    <i className="fa fa-save"></i>&nbsp;Salvar
                  </button>
                )}
              </ToastConsumer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Config;
