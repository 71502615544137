const isAuthenticated = () => {
  if (getToken()) {
    return true;
  } else {
    return false;
  }
}

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('notificacoes');
  localStorage.removeItem('existeNotificacaoNova');
}

const login = (token) => {
  localStorage.setItem('token', token);
}

const getToken = () => {
  return localStorage.getItem('token');
}

const getUser = () => {
  return localStorage.getItem('user');
}

module.exports = { isAuthenticated, logout, login, getToken, getUser }
