import React, { Component } from 'react';
import api from '../../services/api';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, UncontrolledTooltip } from 'reactstrap';
import Apontamento from '../Apontamento';
import validator from 'validator';
import moment from 'moment';
import axios from 'axios';
import { CommentList, ListaPendencias, ApontamentoAtual, ModalAvaliar, ModalAvaliacao, EditarPendencia, ModalConfirm } from '../../components';
import CreatePendencia from '../CreatePendencia';
import { ToastConsumer } from 'react-awesome-toasts';
import Select from 'react-select';
import queryString from 'query-string';
import AdicionarComentario from '../../components/AdicionarComentario';
import CreateTicket from '../../pages/CreateTicket'
import AsyncSelect from "react-select/async";
import Upload from "../../components/Upload";
import { uniqueId } from "lodash";
import filesize from "filesize";
import FileList from "../../components/FileList";
import FileDownload from "js-file-download";
const { getStatusName, atualizaActions, getStatusClassName } = require('../../utils');

class DetailTicket extends Component {

  constructor(props) {
    super(props);
    this.onClickStartService = this.onClickStartService.bind(this);
    this.onClickEndService = this.onClickEndService.bind(this);
    this.onClickCancelService = this.onClickCancelService.bind(this);
    this.getDetailsTicket = this.getDetailsTicket.bind(this);
    this.updateActions = this.updateActions.bind(this);
    this.toggle = this.toggle.bind(this);
    this.loadEstorias = this.loadEstorias.bind(this);
    this.onChangeTipoEstoria = this.onChangeTipoEstoria.bind(this);
    this.onChangeContato = this.onChangeContato.bind(this);
    this.validarFinalizaAtendimento = this.validarFinalizaAtendimento.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.adicionarComentarios = this.adicionarComentarios.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.toggleModalEditarComentario = this.toggleModalEditarComentario.bind(this); this.onChangeCommentModalEditar = this.onChangeCommentModalEditar.bind(this);
    this.onClickEditComment = this.onClickEditComment.bind(this);
    this.editarComentario = this.editarComentario.bind(this);
    this.toggleModalAvaliacao = this.toggleModalAvaliacao.bind(this);
    this.toggleModalAvaliar = this.toggleModalAvaliar.bind(this);
    this.handleRating = this.handleRating.bind(this);
    this.toggleModalPendenciaEditar = this.toggleModalPendenciaEditar.bind(this);
    this.atualizaPendencias = this.atualizaPendencias.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.toggleModalAlert = this.toggleModalAlert.bind(this);
    this.toggleModalImpedimento = this.toggleModalImpedimento.bind(this); 
    this.onChangeProjeto = this.onChangeProjeto.bind(this);
    this.loadProjetos = this.loadProjetos.bind(this);
    this.selecionaAba = this.selecionaAba.bind(this);
    this.handleChangeInputObservacaoInterna = this.handleChangeInputObservacaoInterna.bind(this);
    this.salvarObservacaoInterna = this.salvarObservacaoInterna.bind(this);
    this.baixarAnexo = this.baixarAnexo.bind(this);
    this.alterarSituacaoTicket = this.alterarSituacaoTicket.bind(this);

    this.promiseOptionsModulos = this.promiseOptionsModulos.bind(this);
    this.loadModulos = this.loadModulos.bind(this);
    this.onChangeModulo = this.onChangeModulo.bind(this);
    this.transformDataComboBoxModulo = this.transformDataComboBoxModulo.bind(this);

    this.promiseOptionsTelas = this.promiseOptionsTelas.bind(this);
    this.loadTelas = this.loadTelas.bind(this);
    this.onChangeTela = this.onChangeTela.bind(this);
    this.transformDataComboBoxTela = this.transformDataComboBoxTela.bind(this);
    this.onChangePrioridade = this.onChangePrioridade.bind(this);
    this.handleUpload = this.handleUpload.bind(this);


    this.processUpload = this.processUpload.bind(this);
    this.updateFile = this.updateFile.bind(this);
    this.handleClickBaixarArquivo = this.handleClickBaixarArquivo.bind(this);
    this.loadArquivos = this.loadArquivos.bind(this);
    this.handleClickDeletarArquivo = this.handleClickDeletarArquivo.bind(this);

    this.state = {
      user: JSON.parse(this.props.user()),
      id: props.match.params.id,
      ticket: {
        cliente: {
          id: '',
          descricao: '',
          telefone: '',
          cnpj: ''
        },
        contato: {
          email: '',
          telefone: ''
        },
        tipo: '',
        assunto: '',
        descricao: '',
        prioridade: '',
        dataAgenda: '',
        notificarCliente: '',
        situacao: '',
        apontamentos: [{}],
        comentarios: [],
        logs: []
      },
      actionsButtons: {
        iniciarAtendimento: false,
        finalizarAtendimento: false,
        pausarAtendimento: false,
        criarApontamento: false,
        cancelarAtendimento: false,
      },
      usuarioIniciouAtendimento: false,
      modal: false,
      actionModal: null,
      estorias: [],
      contatos: [],
      activeTab: 'resumo',
      comentario: '',
      comentarioEditar: { comentario: '' },
      modalEditarComentario: false,
      pendencias: [],
      apontamentoAtual: { descricao: '', tipoEstoria: null },
      modalAvaliacao: false,
      rating: 1,
      comentarioAvaliacao: '',
      avaliacaoRequerida: false,
      modalAvaliar: false,
      pendenciaEditar: null,
      modalPendenciaEditar: false,
      refreshStatePendencia: false,
      modalConfirm: false,
      titleModal: '',
      modalApp: {
        alert: false
      },
      buttonEditComment: false,
      fkTipoEstoriaSemRetorno:null,      
      tempoTotal: null,
      finalizacaoSucesso: true,
      modalImpedimento: false,
      errors: {
        tipoEstoria: false,
        descricao: false,
        contato: false,
        descricaoComentario: false,
        descricaoComentarioEditar: false,
        projeto: false
      },
      projetos: [],
      projeto: null,
      observacaoInterna: '',
      telaSelecionada: null,
      moduloSelecionado: null,
      prioridadeSelecionada: 3,
      uploadedFiles: [],
      arquivos: [],
    };
  }

  async componentDidMount() {
    this.selecionaAba();
    await this.getDetailsTicket();
    await this.loadEstorias();
    await this.loadContacts();
    await this.loadProjetos();
    await this.loadArquivos();
  }

  async loadEstorias() {
    await axios.get(`${this.props.getBaseUrlApi()}/tipos-estoria`, this.props.parameters())
      .then(response => {
        let estorias = response.data.estorias;
        const fkTipoEstoriaSemRetorno = response.data.fkTipoEstoriaSemRetorno;
        estorias = estorias.map((estoria) => {
          estoria.value = `${estoria.codigo} - ${estoria.descricao}`;
          estoria.label = `${estoria.codigo} - ${estoria.descricao}`;
          return estoria;
        });
        this.setState({ estorias: estorias, fkTipoEstoriaSemRetorno });
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  async loadContacts() {
    await axios.get(`${this.props.getBaseUrlApi()}/contatos/${this.state.ticket.cliente._id}`, this.props.parameters())
      .then(response => {
        let contatos = response.data.contatos;
        contatos = contatos.map(contato => {
          contato.label = contato.descricao;
          contato.value = contato.descricao;
          return contato;
        })

        this.setState({ contatos: contatos });

      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  async getDetailsTicket() {
    await axios.get(`${this.props.getBaseUrlApi()}/tickets/${this.state.id}`, this.props.parameters()).then(async (response) => {
      let ticket = response.data.ticket;
      let pendencias = response.data.pendencias;
      let usuarioIniciouAtendimento = false;
      let apontamentoAtual = this.state.apontamentoAtual;
      let avaliacaoRequerida = false;

      if (ticket.situacao === 2) {
        for (let i = 0; i < ticket.apontamentos.length; i++) {
          if (ticket.apontamentos[i].horaFinal === '') {
            apontamentoAtual = Object.assign({}, ticket.apontamentos[i]);
            apontamentoAtual.contato = ticket.contato;
            apontamentoAtual.horaInicial = new Date(apontamentoAtual.horaInicial);

            if (ticket.apontamentos[i].usuarioCriacao._id === this.state.user._id) {
              usuarioIniciouAtendimento = true;
            }
          }
        }
      }      

      if (ticket.dataAgenda !== null || ticket.tipo !== 'Suporte') {
        avaliacaoRequerida = true;
      }      

      ticket.descricao = ticket.descricao.replace(/(?:\r\n|\r|\n)/g, '<br />');   
      
      let tempoTotal = Object.assign({}, ticket.tempoTotal);

      if(ticket.observacaoInterna === undefined){
        ticket.observacaoInterna = '';
      }

      let moduloSelecionado = this.state.moduloSelecionado;
      let telaSelecionada = this.state.telaSelecionada;
      let prioridadeSelecionada = this.state.prioridadeSelecionada;

      if(ticket.modulo === undefined){
        ticket.modulo = null;
      }else{
        moduloSelecionado = ticket.modulo;
      }

      if(ticket.tela === undefined){
        ticket.tela = null;
      }else{
        telaSelecionada = ticket.tela;
      }

      if(ticket.prioridade2 === undefined){
        ticket.prioridade2 = 3;
      }else{
        prioridadeSelecionada = ticket.prioridade2;
      }

      await this.setState({ ticket: ticket, pendencias: pendencias, apontamentoAtual: apontamentoAtual, usuarioIniciouAtendimento: usuarioIniciouAtendimento, avaliacaoRequerida: avaliacaoRequerida, tempoTotal, observacaoInterna: ticket.observacaoInterna, moduloSelecionado, telaSelecionada, prioridadeSelecionada }, async () => {
        await this.updateActions();
      });
    }).catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  async onClickStartService({ toast }) {
    let localApontamento = {
      horaInicial: new Date().toJSON(),
      horaFinal: '',
      descricao: '',
      tipoEstoria: {
        codigo: '',
        descricao: ''
      },
      usuarioCriacao: null,
      usuarioCancelamento: null,
      status: 0
    }

    await api.post(`atendimento/iniciar/${this.state.id}`, localApontamento, await this.props.parameters()).then(async (res) => {

      const toastProps = {
        text: 'Atendimento iniciado',
        actionText: 'Fechar',
        ariaLabel: 'Ticket criado com sucesso, clique para fechar'
      };


      let ticket = res.data.ticket;
      let usuarioIniciouAtendimento = false;
      let apontamentoAtual = this.state.apontamentoAtual;

      if (ticket.situacao === 2) {
        for (let i = 0; i < ticket.apontamentos.length; i++) {
          if (ticket.apontamentos[i].horaFinal === '') {
            apontamentoAtual = Object.assign({}, ticket.apontamentos[i]);
            apontamentoAtual.contato = ticket.contato;
            apontamentoAtual.horaInicial = new Date(apontamentoAtual.horaInicial);

            if (ticket.apontamentos[i].usuarioCriacao._id === this.state.user._id) {
              usuarioIniciouAtendimento = true;
            }

          }
        }
      }
      this.setState({ ticket: ticket, usuarioIniciouAtendimento: usuarioIniciouAtendimento, apontamentoAtual: apontamentoAtual }, async () => {
        await this.updateActions();
        toast.show(({ ...toastProps, onActionClick: toast.hide }));
      });
    });
  }

  async validarFinalizaAtendimento(tipo = null) {
    let isValid = true;
    let errors = this.state.errors;


    if (validator.isEmpty(this.state.apontamentoAtual.tipoEstoria.codigo, [{ ignore_whitespace: true }])) {
      isValid = false;
      errors.tipoEstoria = true;
      this.setState({errors});
      return false;
    }else{
      errors.tipoEstoria = false;
    }

    if (validator.isEmpty(this.state.apontamentoAtual.tipoEstoria.descricao, [{ ignore_whitespace: true }])) {
      isValid = false;
      errors.tipoEstoria = true;
      this.setState({errors});
      return false;
    }else{
      errors.tipoEstoria = false;
    }

    if (validator.isEmpty(this.state.apontamentoAtual.descricao, [{ ignore_whitespace: true }])) {
      isValid = false;
      errors.descricao = true;
      this.setState({errors});
      return false;
    }else{
      errors.descricao = false;
    }

    if (this.state.apontamentoAtual.descricao.length < 7) {
      isValid = false;
      errors.descricao = true;
      this.setState({errors});
      return false;
    }else{
      errors.descricao = false;
    }

    if (validator.isEmpty(this.state.apontamentoAtual.contato.descricao, [{ ignore_whitespace: true }])) {
      isValid = false;
      errors.contato = true;
      this.setState({errors});
      return false;
    }else{
      errors.contato = false;
    }

    if(tipo !== null){
      if(tipo === 'impedimento'){

        if (this.state.projeto === null) {
          isValid = false;
          errors.projeto = true;
          this.setState({errors});
          return false;
        }else{
          errors.projeto = false;
          this.setState({errors});
        }
      }else{
        errors.projeto = false;
        this.setState({errors});
      }
    }else{
      errors.projeto = false;
      this.setState({errors});
    }

    return isValid;
  }

  async onClickEndService({ action, toast, tipo }) {

    if (action === 'pausar') {

      const toastProps = {
        text: 'Atendimento parado',
        actionText: 'Fechar',
        ariaLabel: 'Ticket criado com sucesso, clique para fechar'
      };




      let isValid = await this.validarFinalizaAtendimento();

      if (isValid) {
        let localApontamento = this.state.apontamentoAtual;
        let url = '';
        localApontamento.horaFinal = new Date();
        let dateFinal = localApontamento.horaFinal;
        let dateInicial = localApontamento.horaInicial;
        let ms = moment(dateFinal, "YYYY-MM-DD HH:mm:ss").diff(moment(dateInicial, "YYYY-MM-DD HH:mm:ss"));
        let duration = moment.duration(ms);
        localApontamento.tempoTotal = { minutos: Math.floor(duration.asMinutes()), segundos: parseInt(moment.utc(ms).format('ss')) };

        let tipoEstoria = Object.assign({}, localApontamento.tipoEstoria);
        delete localApontamento.tipoEstoria;
        localApontamento.tipoEstoria = { id : tipoEstoria.id, codigo: tipoEstoria.codigo, descricao: tipoEstoria.descricao };

        delete localApontamento.contato.value;
        delete localApontamento.contato.label;

        url = `atendimento/parar/${this.state.id}/${localApontamento.ordem}`;

        await api.post(url, localApontamento, this.props.parameters()).then(async (res) => {
          let ticket = res.data.ticket;
          let usuarioIniciouAtendimento = false;
          this.setState({ ticket: ticket, usuarioIniciouAtendimento: usuarioIniciouAtendimento, apontamentoAtual: { usuarioCriacao: { _id: null } }, modal: !this.state.modal }, async () => {
            await this.updateActions();

            toast.show(({ ...toastProps, onActionClick: toast.hide }));
          });
        });

      }

    } else if (action === 'finalizar') {

      let isValid = await this.validarFinalizaAtendimento(tipo);

      if(isValid){

        if(tipo === 'impedimento'){
          this.setState({finalizacaoSucesso : false})
        }

        let localApontamento = this.state.apontamentoAtual;
        localApontamento.horaFinal = new Date();
        let dateFinal = localApontamento.horaFinal;
        let dateInicial = localApontamento.horaInicial;
        let ms = moment(dateFinal, "YYYY-MM-DD HH:mm:ss").diff(moment(dateInicial, "YYYY-MM-DD HH:mm:ss"));
        let duration = moment.duration(ms);
        localApontamento.tempoTotal = { minutos: Math.floor(duration.asMinutes()), segundos: parseInt(moment.utc(ms).format('ss')) };

        delete localApontamento.tipoEstoria._id;
        delete localApontamento.tipoEstoria._rev;
        delete localApontamento.tipoEstoria.value;
        delete localApontamento.tipoEstoria.type;
        delete localApontamento.tipoEstoria.label;
        delete localApontamento.tipoEstoria.status;
        delete localApontamento.tipoEstoria.tipoApontamento;
        delete localApontamento.tipoEstoria.cobrado;
        delete localApontamento.tipoEstoria.faturado;
        delete localApontamento.contato.value;
        delete localApontamento.contato.label;

        if (this.state.avaliacaoRequerida) {
          this.setState({ modalAvaliar: !this.state.modalAvaliar });
        } else {
          let finalizacaoSucesso = this.state.finalizacaoSucesso;
          if(tipo === 'impedimento'){
            finalizacaoSucesso = false;
          }
          this.setState({ modalAvaliacao: !this.state.modalAvaliacao, finalizacaoSucesso });
        }
      }
    }

  }

  onClickCancelService({ toast }) {

    const toastProps = {
      text: 'Ticket cancelado',
      actionText: 'Fechar',
      ariaLabel: 'Ticket criado com sucesso, clique para fechar'
    };

    api.post(`atendimento/cancelar/${this.state.id}`, {}, this.props.parameters()).then(async (response) => {
      let ticket = response.data.ticket;
      await this.setState({ ticket: ticket, modalConfirm: !this.state.modalConfirm }, async () => {
        await this.updateActions();
        toast.show(({ ...toastProps, onActionClick: toast.hide }));
      });
    });
  }

  async updateActions() {
    let localActions = atualizaActions(this.state.ticket.situacao, this.state.usuarioIniciouAtendimento);
    await this.setState({ actionsButtons: localActions });
  }

  onChangeTipoEstoria(tipoEstoria) {
    let localApontamento = this.state.apontamentoAtual;
    if(tipoEstoria.id === this.state.fkTipoEstoriaSemRetorno && localApontamento.descricao === ''){
      localApontamento.descricao = 'Tentativa de contato sem sucesso.';
    }
    localApontamento.tipoEstoria = tipoEstoria;
    this.setState({ apontamentoAtual: localApontamento })

  }

  onChangeContato(contact) {
    let localApontamento = this.state.apontamentoAtual;
    localApontamento.contato = contact;
    this.setState({ apontamentoAtual: localApontamento });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  async adicionarComentarios({ toast }) {
    let errors = this.state.errors;
    if (this.state.comentario === '' || this.state.comentario.length < 7) { 
      errors.descricaoComentario = true;
      this.setState({errors})
      return false;    
    }else{      
      errors.descricaoComentario = false;
      this.setState({errors})
    }
    let comentario = this.state.comentario;
    await axios.post(`${this.props.getBaseUrlApi()}/comentarios/${this.state.id}`, { comentario }, this.props.parameters())
      .then(response => this.setState({ ticket: response.data.ticket, comentario: '' }, () => {

        const toastProps = {
          text: 'Comentário adicionado com sucesso',
          actionText: 'Fechar',
          ariaLabel: 'Ticket criado com sucesso, clique para fechar'
        };

        toast.show(({ ...toastProps, onActionClick: toast.hide }));

      }))
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeComment(e) {
    this.setState({ comentario: e.target.value });
  }

  onChangeCommentModalEditar(e) {
    let value = e.target.value;
    let btnActive = false;
    if(value.length >= 7){
      btnActive = true;
    }
    let localComentario = this.state.comentarioEditar;
    localComentario.comentario = value;
    this.setState({ comentarioEditar: localComentario,buttonEditComment : btnActive});
  }

  async toggleModalEditarComentario() {
    await this.setState({ modalEditarComentario: !this.state.modalEditarComentario });
  }

  async onClickEditComment(comentario) {
    let btnActive = false;
    if(comentario.comentario.length >= 7){
      btnActive = true;
    }

    let errors = this.state.errors;
    errors.descricaoComentarioEditar = false;

    await this.setState({ comentarioEditar: Object.assign({}, comentario), buttonEditComment : btnActive, errors }, () => this.toggleModalEditarComentario());
  }

  async editarComentario({ toast }) {
    let comentario = this.state.comentarioEditar;

    let errors = this.state.errors;
    if(comentario.comentario.length < 7 || comentario.comentario === ''){
      errors.descricaoComentarioEditar = true;
      this.setState({errors});
      return false;

    }else{
      errors.descricaoComentarioEditar = false;
      this.setState({errors});
    }

    await axios.put(`${this.props.getBaseUrlApi()}/comentarios/${this.state.id}/${comentario.ordem}`, { comentario }, this.props.parameters())
      .then(response => this.setState({ ticket: response.data.ticket, comentarioEditar: { comentario: '' }, modalEditarComentario: !this.state.modalEditarComentario, buttonEditComment : false }, () => {

        const toastProps = {
          text: 'Comentário editado com sucesso',
          actionText: 'Fechar',
          ariaLabel: 'Ticket criado com sucesso, clique para fechar'
        };

        toast.show(({ ...toastProps, onActionClick: toast.hide }));

      }))
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  toggleModalAvaliacao() {
    this.setState({
      modalAvaliacao: !this.state.modalAvaliacao
    });
  }

  toggleModalAvaliar() {
    this.setState({
      modalAvaliar: !this.state.modalAvaliar,
      modal: false,
      modalAvaliacao: false
    });
  }

  handleRating() { 
    let apontamentoAtual = this.state.apontamentoAtual;
    apontamentoAtual.horaFinal = new Date();
    this.setState({ modal: !this.state.modal, actionModal: 'finalizar', apontamentoAtual: apontamentoAtual });
  }

  toggleModalPendenciaEditar() {
    this.setState({ modalPendenciaEditar: !this.state.modalPendenciaEditar });
  }

  atualizaPendencias(ticket, pendencias) {
    if (this.state.refreshStatePendencia) {
      this.setState({ ticket: ticket, pendencias: pendencias, refreshStatePendencia: false });
    } else {
      this.setState({ pendencias: pendencias, refreshStatePendencia: false });
    }
  }

  toggleConfirm() {
    this.setState({
      modalConfirm: !this.state.modalConfirm
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  toggleModalAlert() {
    let localModal = this.state.modalApp;
    localModal.alert = !localModal.alert;
    this.setState({ modalApp: localModal });
  }

  toggleModalImpedimento(){
    this.setState({modalImpedimento : !this.state.modalImpedimento})
  }

  async loadProjetos() {
    await axios.get(`${this.props.getBaseUrlApi()}/projetos`, this.props.parameters())
      .then(response => {
        let projetos = response.data.projetos;
        projetos = projetos.map((projeto) => {
          projeto.value = projeto.id;
          projeto.label = projeto.nome;
          return projeto;
        });
        this.setState({ projetos });
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeProjeto(projeto){
    this.setState({projeto});
  }

  selecionaAba(){
    const parsed = queryString.parse(this.props.location.search);
    if(parsed.aba !== undefined){
      if(parsed.aba !== ''){
        this.setState({activeTab: parsed.aba});
      }      
    }
  }

  handleChangeInputObservacaoInterna(e){
    this.setState({observacaoInterna: e.target.value});
  }

  async salvarObservacaoInterna({ toast }) {
    let observacaoInterna = this.state.observacaoInterna;
    let modulo = this.state.moduloSelecionado;
    let tela = this.state.telaSelecionada;
    let prioridade2 = this.state.prioridadeSelecionada;

    const objectToSend = {
      observacaoInterna,
      modulo,
      tela,
      prioridade2
    }
    
    await axios.post(`${this.props.getBaseUrlApi()}/adicionar-observacao-ticket/${this.state.id}`, objectToSend , this.props.parameters())
      .then(response => {

        const toastProps = {
          text: 'Informações da Aba de Observação interna atualizada',
          actionText: 'Fechar',
          ariaLabel: 'Informações da Aba de Observação interna atualizada, clique para fechar'
        };

        toast.show(({ ...toastProps, onActionClick: toast.hide }));


      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  async baixarAnexo(url) {
    setTimeout(() => {
      const newWindow = window.open(`${url}`, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }, 1000);
  }

  async alterarSituacaoTicket({ toast }, situacaoNova) {

    await axios.post(`${this.props.getBaseUrlApi()}/alterar-situacao-ticket/${this.state.id}`, { situacaoNova }, this.props.parameters())
      .then(response => {

        const toastProps = {
          text: 'Situação do ticket atualizada com sucesso!',
          actionText: 'Fechar',
          ariaLabel: 'Situação do ticket atualizada com sucesso, clique para fechar'
        };

        toast.show(({ ...toastProps, onActionClick: toast.hide }));

        setTimeout(() => {
          window.location.reload()
        }, 1500);


      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  promiseOptionsModulos(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let modulos = await this.loadModulos();
          modulos = this.transformDataComboBoxModulo(modulos);
          this.setState({ modulos });
          resolve(modulos);
        } else {
          // let aux = inputValue.substr(0, 1);
          // let len = 3;
          //if (aux == "&" || aux == "%") len = 3;
          if (inputValue.length >= 3) {
            let modulos = await this.loadModulos(inputValue);
            modulos = this.transformDataComboBoxModulo(modulos);
            this.setState({ modulos });
            resolve(modulos);
          } else {
            resolve(this.state.modulos);
          }
        }
      }, 100);
    });
  }

  async loadModulos(busca = null) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/modulos`,
          { busca },
          this.props.parameters()
        )
        .then((response) => {
          let modulos = response.data.modulos;
          resolve(modulos);
        })
        .catch((err) => this.props.handlingErrorsApi(err, this.props));
    });
  }

  onChangeModulo(moduloSelecionado) {
    this.setState({ moduloSelecionado });
  }

  transformDataComboBoxModulo(lista) {
    lista = lista.map((item) => {
      item.value = item.id;
      item.label = item.descFormat;
      return item;
    });
    return lista;
  }


  promiseOptionsTelas(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let telas = await this.loadTelas();
          telas = this.transformDataComboBoxTela(telas);
          this.setState({ telas });
          resolve(telas);
        } else {
          // let aux = inputValue.substr(0, 1);
          // let len = 3;
          //if (aux == "&" || aux == "%") len = 3;
          if (inputValue.length >= 3) {
            let telas = await this.loadTelas(inputValue);
            telas = this.transformDataComboBoxTela(telas);
            this.setState({ telas });
            resolve(telas);
          } else {
            resolve(this.state.telas);
          }
        }
      }, 100);
    });
  }

  async loadTelas(busca = null) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/telas`,
          { busca },
          this.props.parameters()
        )
        .then((response) => {
          let telas = response.data.telas;
          resolve(telas);
        })
        .catch((err) => this.props.handlingErrorsApi(err, this.props));
    });
  }

  onChangeTela(telaSelecionada) {
    this.setState({ telaSelecionada });
  }

  transformDataComboBoxTela(lista) {
    lista = lista.map((item) => {
      item.value = item.id;
      item.label = item.descFormat;
      return item;
    });
    return lista;
  }

  onChangePrioridade(value){
    this.setState({prioridadeSelecionada: parseInt(value)})
  }


  handleUpload = (files) => {
    const uploadedFiles = files.map((file) => {
      let tipoArquivo = file.name.split(".").pop();
      let previewClass = null;

      if (tipoArquivo === "pdf") {
        previewClass = "fa fa-file-pdf-o";
      }

      if (
        tipoArquivo === "xls" ||
        tipoArquivo === "xlt" ||
        tipoArquivo === "xlsx"
      ) {
        previewClass = "fa fa-file-excel-o";
      }
      if (
        tipoArquivo === "doc" ||
        tipoArquivo === "docx" ||
        tipoArquivo === "docm"
      ) {
        previewClass = "fa fa-file-excel-o";
      }

      return {
        file,
        id: uniqueId(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
        previewClass,
      };
    });

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };
  

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    let params = {
      onUploadProgress: (e) => {
        const progress = parseInt(Math.round((e.loaded * 100) / e.total));

        this.updateFile(uploadedFile.id, {
          progress,
        });
      },
      headers: null,
    };

    let propsHeaders = this.props.parameters();
    params.headers = propsHeaders.headers;

    axios
      .post(
        `${this.props.getBaseUrlApi()}/arquivos/${this.state.id}`,
        data,
        params
      )
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url,
        });
        this.loadArquivos();
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  componentWillUnmount() {
    this.state.uploadedFiles.forEach((file) =>
      URL.revokeObjectURL(file.preview)
    );
  }

  async handleClickBaixarArquivo(id, nome, status) {
    if (status === "B") {
      alert(
        "Este arquivo já foi sincronizado com o SAC OFF e não esta mais disponível."
      );
    }

    let arquivoFind = this.state.arquivos.find((it) => it._id === id);

    if(arquivoFind.urlDownload === undefined){
      arquivoFind.urlDownload = null;
    }

    if (arquivoFind.urlDownload === null) {
      let params = {};
      params.responseType = "blob";

      await axios
        .get(`${this.props.getBaseUrlApi()}/arquivos-download/${id}`, params)
        .then(async (response) => {
          FileDownload(response.data, nome);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            // this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              // this.props.logout();
            }
          }
        });
    }else{
      window.open(arquivoFind.urlDownload, '_blank', 'noopener,noreferrer');
    }
  }


  async loadArquivos() {
    await axios.post(`${this.props.getBaseUrlApi()}/buscar-arquivos`, {id: this.state.id}, this.props.parameters())
      .then(response => {
        let arquivos = response.data.arquivos;


        arquivos = arquivos.map((arquivo) => {
          arquivo.tipoClass = "fa fa-file-text";

          if (arquivo.tipo === "pdf") {
            arquivo.tipoClass = "fa fa-file-pdf-o";
          }

          if (
            arquivo.tipo === "xls" ||
            arquivo.tipo === "xlt" ||
            arquivo.tipo === "xlsx"
          ) {
            arquivo.tipoClass = "fa fa-file-excel-o";
          }
          if (
            arquivo.tipo === "doc" ||
            arquivo.tipo === "docx" ||
            arquivo.tipo === "docm"
          ) {
            arquivo.tipoClass = "fa fa-file-excel-o";
          }

          if (
            arquivo.tipo === "jpeg" ||
            arquivo.tipo === "pjpeg" ||
            arquivo.tipo === "png" ||
            arquivo.tipo === "gif"
          ) {
            arquivo.tipoClass = "fa fa-file-image-o";
          }
          return arquivo;
        });


        this.setState({ arquivos });

      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  async handleClickDeletarArquivo(id) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/deletar-arquivo`,
        { id },
        this.props.parameters()
      )
      .then((response) => {
        alert("Arquivo inativado com sucesso");
        this.loadArquivos();
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  render() {
    return (
      <div id="contentSite">
        <div className="container">
          <div className="Example_box">
            <div className="Example_box-header"><span>Detalhe do Ticket</span></div>
            <div className="Example_box-content">

              <Col sm={12}>

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === 'resumo' }}
                      onClick={() => { this.toggleTab('resumo'); }}>
                      Resumo
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === 'apontamentos' }}
                      onClick={() => { this.toggleTab('apontamentos'); }}>
                      Apontamentos
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === 'comentarios' }}
                      onClick={() => { this.toggleTab('comentarios'); }}>
                      Conversação
                    </NavLink>
                  </NavItem>
                  
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === 'pendencias' }}
                      onClick={() => { this.toggleTab('pendencias'); }}>
                      Pendências
                    </NavLink>
                  </NavItem> 
                  {this.state.ticket.tipo === 'Suporte' && <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === 'contestacao' }}
                      onClick={() => { this.toggleTab('contestacao'); }}>
                      Contestação
                    </NavLink>
                  </NavItem>} 

                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === 'observacaoInterna' }}
                      onClick={() => { this.toggleTab('observacaoInterna'); }}>
                      Observação interna
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === 'arquivos' }}
                      onClick={() => { this.toggleTab('arquivos'); }}>
                      Arquivos
                    </NavLink>
                  </NavItem>
                  {this.state.ticket.origemEmissao !== undefined && this.state.ticket.origemEmissao === 'portalCliente' && (
                    <React.Fragment>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === 'anexos' }}
                          onClick={() => { this.toggleTab('anexos'); }}
                        >
                          Anexos
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === 'logs' }}
                          onClick={() => { this.toggleTab('logs'); }}
                        >
                          Logs
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="resumo">

                    <div style={{ paddingTop: '15px' }}>

                    <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Código: </b>
                            {this.state.ticket.codigo}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Data Emissão: </b>
                            {moment(this.state.ticket.dataCriacao).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Situação: </b>
                            <span style={{ width: '100%', fontWeight: 'bold', paddingLeft: '3px' }}>
                          <span className={"tag " + getStatusClassName(this.state.ticket.situacao)}>{getStatusName(this.state.ticket.situacao)}</span>
                        </span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Cliente: </b>
                            {this.state.ticket.cliente.descricao}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Contato: </b>
                            {this.state.ticket.contato.descricao} (
                            {this.state.ticket.contato.email})
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Telefone: </b>
                            {this.state.ticket.contato.fone}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                       
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tipo: </b>
                            {this.state.ticket.tipo}
                          </div>
                        </div>

                        {this.state.ticket.origemEmissao !== undefined && this.state.ticket.origemEmissao === 'portalCliente' && (
                          <div className="col-md-4 no-padding">
                            <div style={{ padding: "3px" }}>
                              <b>Tela/Processo: </b>
                              {this.state.ticket.telaProcesso !== undefined
                                ? this.state.ticket.telaProcesso
                                : ""}
                            </div>
                          </div>
                        )}

                        {this.state.ticket.origemEmissao !== undefined && this.state.ticket.origemEmissao === 'portalCliente' && (
                          <div className="col-md-4 no-padding">
                            <div style={{ padding: "3px" }}>
                              <b>Módulo: </b>
                              {this.state.ticket.modulo !== undefined &&
                              this.state.ticket.modulo !== null
                                ? this.state.ticket.modulo
                                : ""}
                            </div>
                          </div>
                        )}
                        
                        
                        {/* <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Prioridade: </b>
                            {this.state.ticket.prioridade}
                          </div>
                        </div> */}
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Usuário Atendimento: </b>
                            {this.state.ticket.usuario
                              ? this.state.ticket.usuario.descricao
                              : "Não Vinculado"}
                          </div>
                        </div>

                        


                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Assunto: </b>
                            {this.state.ticket.assunto}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Descrição:</b>
                            <div
                              className=""
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.ticket.descricao
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      {this.state.ticket.situacaoPortalCliente ===
                        "Cancelado" && (

                      <div className="row">
                        <div className="col-md-12 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Obs Cancelamento:</b>
                            <div
                              className=""
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.ticket.obsCancelamentoPortalCliente
                              )}
                            />
                          </div>
                        </div>
                      </div>
                        )}
                      
                     

                     


                 

                  


                     
                      
                      <br/>
                        
                      <FormGroup style={{ paddingTop: '20px' }}>
                        {/* <ToastConsumer>
                          {
                            ({ show, hide }) => (
                              <Button
                                color="success"
                                onClick={() => this.onClickStartService({ toast: { show, hide } })}
                                style={{ margin: "3px", minWidth: '105px' }}
                                disabled={!this.state.actionsButtons.iniciarAtendimento}>
                                <i className="fa fa-play" aria-hidden="true"></i> Iniciar
                              </Button>
                            )
                          }
                        </ToastConsumer> */}

                        {/* <Button
                          color="warning"
                          onClick={() => {
                            this.setState({ modal: !this.state.modal, actionModal: 'pausar', apontamentoAtual: this.state.apontamentoAtual, titleModal: 'Pausar Ticket' })
                          }}
                          style={{ margin: "3px", color: '#FFF', minWidth: '105px' }}
                          disabled={!this.state.actionsButtons.pausarAtendimento}>
                          <i className="fa fa-pause" aria-hidden="true"></i> Pausar
                      </Button> */}

                      {this.state.ticket.tipo !== "" && this.state.ticket.tipo !== "Suporte" && (
                        <ToastConsumer>
                          {
                            ({ show, hide }) => (
                              <Button
                                color="success"
                                onClick={() => this.alterarSituacaoTicket({ toast: { show, hide } }, 2)}
                                style={{ margin: "3px", minWidth: '105px' }}
                                disabled={this.state.ticket.situacao === "" ? false : (this.state.ticket.situacao === 2 || this.state.ticket.situacao === 4 || this.state.ticket.situacao === 5 ? true : false)}>
                                <i className="fa fa-play" aria-hidden="true"></i> Atendendo
                              </Button>
                            )
                          }
                        </ToastConsumer>
                      )}

                      {this.state.ticket.tipo !== "" && this.state.ticket.tipo === "Suporte" && (

                        <ToastConsumer>
                        {
                          ({ show, hide }) => (
                            <Button
                              color="warning"
                              onClick={() => this.alterarSituacaoTicket({ toast: { show, hide } }, 6)}
                              style={{ margin: "3px", minWidth: '105px', color: "white" }}
                              disabled={this.state.ticket.situacao === "" ? false : (this.state.ticket.situacao === 4 || this.state.ticket.situacao === 5 || this.state.ticket.situacao === 6 ? true : false)}>
                              <i className="fa fa-play" aria-hidden="true"></i> Em Análise
                            </Button>
                          )
                        }
                        </ToastConsumer>
                      )}

                      
                        <Button
                          onClick={() => {
                            if(this.state.ticket.usuario){
                              
                                if (this.state.ticket.apontamentos.length) {

                                  let apontamentos = this.state.ticket.apontamentos.filter(apont => apont.status === 0);

                                  if(apontamentos.length){
                                    if (this.state.avaliacaoRequerida) {
                                      this.setState({ modalAvaliar: !this.state.modalAvaliar });
                                    } else {
                                      if(this.state.ticket.tipo === 'Suporte'){
                                        this.setState({ modalImpedimento: !this.state.modalImpedimento });
                                      }
                                    }
                                  }else{
                                    this.toggleModalAlert();
                                  }
                                  
                                } else {
                                  this.toggleModalAlert();
                                }

                              
                            } else {
                              alert("Para finalizar o Ticket, é necessário ter um atendendente vinculado.");
                            }
                            /*if (this.state.ticket.situacao == 2) {

                              this.setState({ modal: !this.state.modal, actionModal: 'finalizar', apontamentoAtual: this.state.apontamentoAtual, titleModal: 'Finalizar Ticket' });

                            } else {

                              if (this.state.ticket.apontamentos.length) {

                                let apontamentos = this.state.ticket.apontamentos.filter(apont => apont.status === 0);

                                if(apontamentos.length){
                                  if (this.state.avaliacaoRequerida) {
                                    this.setState({ modalAvaliar: !this.state.modalAvaliar });
                                  } else {
                                    if(this.state.ticket.tipo === 'Suporte'){
                                      this.setState({ modalImpedimento: !this.state.modalImpedimento });
                                    }
                                  }
                                }else{
                                  this.toggleModalAlert();
                                }
                                
                              } else {
                                this.toggleModalAlert();
                              }

                            }*/

                          }}
                          style={{ margin: "3px", minWidth: '105px' }}
                          disabled={this.state.ticket.situacao === "" ? true : (this.state.ticket.situacao === 4 || this.state.ticket.situacao === 5 ? true : false)}
                          
                          >
                          <i className="fa fa-stop" aria-hidden="true"></i> Finalizar
                      </Button>

                        <Button
                          color="danger"
                          onClick={() => this.setState({ modalConfirm: !this.state.modalConfirm })}
                          style={{ margin: "3px", minWidth: '105px' }}
                          disabled2={!this.state.actionsButtons.cancelarAtendimento}
                          disabled={this.state.ticket.situacao === "" ? true : (this.state.ticket.situacao === 4 || this.state.ticket.situacao === 5 ? true : false)}
                          ><i className="fa fa-times" aria-hidden="true"></i> Cancelar
                      </Button>


                        {this.state.ticket.situacao !== 4 && this.state.ticket.situacao !== 5 && this.state.ticket.tipo !== 'Pendência' &&
                          <Button
                            color="info"
                            onClick={() => this.props.history.push(`/tickets/edit/${this.state.id}`)}
                            style={{ margin: "3px", minWidth: '105px' }}> <i className="fa fa-pencil" aria-hidden="true"></i> Ticket
                        </Button>
                        }

                        {this.state.ticket.situacao !== 4 && this.state.ticket.situacao !== 5 && this.state.ticket.tipo === 'Pendência' &&
                          <Button
                            color="info"
                            onClick={() => this.setState({ pendenciaEditar: this.state.ticket, modalPendenciaEditar: !this.state.modalPendenciaEditar, refreshStatePendencia: true })}
                            style={{ margin: "3px", minWidth: '105px' }}> <i className="fa fa-pencil" aria-hidden="true"></i> Pendência
                        </Button>
                        }

                      </FormGroup>

                      {this.state.apontamentoAtual &&
                        <ToastConsumer>
                          {
                            ({ show, hide }) => (

                              <ApontamentoAtual
                                modal={this.state.modal}
                                toggle={this.toggle}
                                apontamento={this.state.apontamentoAtual}
                                estorias={this.state.estorias}
                                contatos={this.state.contatos}
                                onChangeDateTime={(localApontamento) => this.setState({ apontamentoAtual: localApontamento })}
                                onChangeDescricao={(apontamentoAtual) => this.setState({ apontamentoAtual: apontamentoAtual })}
                                onClickEnd={(tipo) => this.onClickEndService({ action: this.state.actionModal, toast: { show, hide }, tipo })}
                                onChangeTipoEstoria={this.onChangeTipoEstoria}
                                onChangeContato={this.onChangeContato}
                                titleModal={this.state.titleModal}
                                tipoTicket={this.state.ticket.tipo}
                                errors={this.state.errors}
                                projetos={this.state.projetos}
                                projeto={this.state.projeto}
                                onChangeProjeto={this.onChangeProjeto}
                              />
                            )
                          }
                        </ToastConsumer>
                      }

                    </div>
                  </TabPane>
                  <TabPane tabId="apontamentos">
                    <Apontamento
                      id={this.state.id}
                      parameters={this.props.parameters}
                      getBaseUrlApi={this.props.getBaseUrlApi}
                      handlingErrorsApi={this.props.handlingErrorsApi}
                      modal={this.state.modal}
                      apontamentos={this.state.ticket.apontamentos}
                      atualiza={(ticket) => this.setState({ ticket: ticket })}
                      contatos={this.state.contatos}
                      contatoTicket={this.state.ticket.contato}
                      exibeCadastro={this.state.ticket.situacao !== 4 && this.state.ticket.situacao !== 5 ? true : false}
                      createMarkup={this.createMarkup}
                      tempoTotal={this.state.tempoTotal}
                    />
                  </TabPane>
                  <TabPane tabId="comentarios">

                  <ToastConsumer>
                      {
                        ({ show, hide }) => (
                          <CommentList
                            comentarios={this.state.ticket.comentarios}
                            onChangeCommentModalEditar={this.onChangeCommentModalEditar}
                            toggleModalEditarComentario={this.toggleModalEditarComentario}
                            onClickEditComment={this.onClickEditComment}
                            modalEditarComentario={this.state.modalEditarComentario}
                            comentarioEditar={this.state.comentarioEditar}
                            editarComentario={() => this.editarComentario({ toast: { show, hide } })}
                            user={this.state.user}
                            buttonEditComment={this.state.buttonEditComment}
                            errors={this.state.errors}
                          />
                        )
                      }
                    </ToastConsumer>

                    <ToastConsumer>
                      {
                        ({ show, hide }) => {
                          if (this.state.ticket) {
                            return (
                              <AdicionarComentario
                                comentario={this.state.comentario}
                                adicionarComentarios={() => this.adicionarComentarios({ toast: { show, hide } })}
                                onChangeComment={this.onChangeComment}
                                errors={this.state.errors}
                              />
                            )
                          } else {
                            return null
                          }
                        }
                      }
                    </ToastConsumer>
                    
                  </TabPane>

                  <TabPane tabId="pendencias">
                    {this.state.contatos.length && this.state.ticket.cliente && this.state.ticket.situacao !== 4 && this.state.ticket.situacao !== 5 &&

                      <React.Fragment>
                        {/* <CreateTicket                       
                          parameters={this.props.parameters}
                          user={this.props.user}
                          getBaseUrlApi={this.props.getBaseUrlApi}
                          handlingErrorsApi={this.props.handlingErrorsApi}
                          contatos={this.state.contatos}
                          cliente={this.state.ticket.cliente}  
                          history={this.props.history}                    
                        /> */}

                        <CreatePendencia
                          handlingErrorsApi={this.props.handlingErrorsApi}
                          parameters={this.props.parameters}
                          getBaseUrlApi={this.props.getBaseUrlApi}
                          atualiza={(ticket, pendencias) => this.setState({ ticket: ticket, pendencias: pendencias })}
                          contatos={this.state.contatos}
                          cliente={this.state.ticket.cliente}
                          id={this.state.id}
                          teste={'teste'}
                        />
                      </React.Fragment>
                    }

                    {true &&
                      <ListaPendencias
                        pendencias={this.state.pendencias}
                        setPendenciaEditar={(pendencia) => { this.setState({ pendenciaEditar: pendencia, modalPendenciaEditar: !this.state.modalPendenciaEditar }) }}
                      />
                    }

                    {this.state.pendenciaEditar &&
                      <EditarPendencia
                        handlingErrorsApi={this.props.handlingErrorsApi}
                        parameters={this.props.parameters}
                        getBaseUrlApi={this.props.getBaseUrlApi}
                        atualiza={this.atualizaPendencias}
                        contatos={this.state.contatos}
                        cliente={this.state.ticket.cliente}
                        id={this.state.id}
                        teste={'teste'}
                        pendencia={this.state.pendenciaEditar}
                        modalPendenciaEditar={this.state.modalPendenciaEditar}
                        toggleModalPendenciaEditar={this.toggleModalPendenciaEditar}
                      />
                    }

                  </TabPane>
                  <TabPane tabId="contestacao">
                    {this.state.ticket.tipo !== '' && 
                      <div style={{ paddingTop: "15px" }}>    
                      
                        {this.state.ticket.contestacao === null && 
                          <div 
                            style={{
                              textAlign: "center",
                              padding: "15px"
                            }}
                          >
                            Ticket não possui contestação
                          </div>
                        }   

                        {this.state.ticket.contestacao !== null && 
                          <React.Fragment>

                            <div className="item-detalhe-ticket-contestacao">
                              <span className="item-detalhe-ticket-span">
                                {" "}
                                <b>Situação:</b>
                                <span
                                  style={{
                                    width: "100%",
                                    fontWeight: "bold",
                                    paddingLeft: "3px"
                                  }}
                                >
                                  <span
                                    className={
                                      "tag "+ 
                                      (this.state.ticket.contestacao.aceita === null ? 'analise' : this.state.ticket.contestacao.aceita ? 'aceita': 'recusada')
                                    }
                                  >
                                    {this.state.ticket.contestacao.aceita === null ? 'Análise' : this.state.ticket.contestacao.aceita ? 'Aceita' : 'Recusada'}
                                  </span>
                                </span>
                              </span>
                            </div>

                            <div className="item-detalhe-ticket-contestacao">
                              <span className="item-detalhe-ticket-span">
                                {" "}
                                <b>Data Emissão:</b> {moment(this.state.ticket.contestacao.data).utcOffset('-03:00').format('DD/MM/YYYY HH:mm')}
                              </span>
                            </div>

                            <div 
                              className="item-detalhe-ticket-contestacao" 
                              dangerouslySetInnerHTML={this.createMarkup('<b>Contestação:</b> <br>' + this.state.ticket.contestacao.mensagem)} 
                            />

                            {this.state.ticket.contestacao.mensagemAnalise !== '' && this.state.ticket.contestacao.mensagemAnalise !== undefined && 
                              <div 
                                className="item-detalhe-ticket-contestacao" 
                                dangerouslySetInnerHTML={this.createMarkup('<b>Resposta:</b> <br>' + this.state.ticket.contestacao.mensagemAnalise)} 
                              />
                            }
                          </React.Fragment>
                        }                       

                        </div>
                      }
                    </TabPane>

                    <TabPane tabId="observacaoInterna">

                      <ToastConsumer>
                        {
                          ({ show, hide }) => (
                            <React.Fragment>


                              <div className='row'>
                                <div className='col-4'>
                                  <div className="form-group" style={{paddingTop:"20px"}}>
                                    <Label>Módulo:</Label>
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={this.promiseOptionsModulos}
                                      value={this.state.moduloSelecionado}
                                      onChange={(item) => this.onChangeModulo(item)}
                                      placeholder="Selecione"
                                    />
                                  </div>
                                </div>
                                <div className='col-4'>
                                  <div className="form-group" style={{paddingTop:"20px"}}>
                                    <Label>Tela:</Label>
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={this.promiseOptionsTelas}
                                      value={this.state.telaSelecionada}
                                      onChange={(item) => this.onChangeTela(item)}
                                      placeholder="Selecione"
                                    />
                                  </div>
                                </div>
                                <div className='col-4'>
                                  <div className="form-group" style={{paddingTop:"20px"}}>
                                    <Label>Prioridade:</Label>
                                    <select className='form-control' value={this.state.prioridadeSelecionada} onChange={(e) => {
                                      this.onChangePrioridade(e.target.value)
                                    }}>
                                      <option value="0">P0</option>
                                      <option value="1">P1</option>
                                      <option value="2">P2</option>
                                      <option value="3">P3</option>
                                    </select>
                                  </div>
                                </div>

                              </div>

                              <div className="form-group" style={{paddingTop:"20px"}}>
                                <Label>Observação:</Label>
                                <textarea
                                  rows={5}
                                  className="form-control"
                                  type="text"
                                  value={this.state.observacaoInterna}
                                  onChange={this.handleChangeInputObservacaoInterna}
                                />
                              </div>

                            <FormGroup>
                              <Button 
                                type="button" 
                                color="success" 
                                onClick={() => this.salvarObservacaoInterna({ toast: { show, hide } })}
                              >
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{' '}
                                Salvar
                              </Button>
                            </FormGroup>

                            </React.Fragment>
                          )
                        }
                      </ToastConsumer>                      

                  </TabPane>
                    <TabPane tabId="arquivos">

                      <ToastConsumer>
                        {
                          ({ show, hide }) => (
                            <React.Fragment>
                            <div className="card-body">
                              <div className="row">
                        
                                  <div
                                    className="col-md-5"
                                    style={{ borderRight: "1px solid #6666" }}
                                  >
                                    <div className="content-drop">
                                      <Upload onUpload={this.handleUpload} />
                                      {!!this.state.uploadedFiles.length && (
                                        <FileList
                                          files={this.state.uploadedFiles}
                                          onDelete={this.handleDelete}
                                        />
                                      )}
                                    </div>
                                  </div>
                               
  
                                <div
                                  className={`col-md-7`}
                                >
                                  <table className="table">
                                    <thead
                                      className="text-primary"
                                      ref={(node) => {
                                        if (node) {
                                          node.style.setProperty(
                                            "color",
                                            '#2c2e3e',
                                            "important"
                                          );
                                        }
                                      }}
                                    >
                                      <th style={{ borderTop: "none" }}>
                                        Arquivo
                                      </th>
                                      <th
                                        className="text-right"
                                        style={{ borderTop: "none" }}
                                      >
                                        Data
                                      </th>
                                      <th
                                        style={{
                                          borderTop: "none",
                                        }}
                                      >
                                        Ação
                                      </th>
                                    </thead>
                                    <tbody>
                                      {this.state.arquivos.length === 0 && (
                                        <td colSpan="3">
                                          <div
                                            className="text-center w-100"
                                            style={{ float: "left" }}
                                          >
                                            Nenhum arquivo neste ticket.
                                          </div>
                                        </td>
                                      )}
  
                                      {this.state.arquivos.length > 0 &&
                                        this.state.arquivos.map(
                                          (arquivo, key) => (
                                            <tr key={key}>
                                              <td>
                                                <i
                                                  className={arquivo.tipoClass}
                                                ></i>
                                                &nbsp; {arquivo.nomeOriginal}
                                              </td>
                                              <td className="text-right">
                                                {moment(
                                                  arquivo.dataCadastro
                                                ).format("DD/MM/YYYY")}{" "}
                                                <br />
                                                {moment(
                                                  arquivo.dataCadastro
                                                ).format("HH:mm")}
                                              </td>
  
                                              <td>
                                                {" "}
                                                <button
                                                  className="btn btn-secondary btn-sm"
                                                  style={{
                                                    padding: "0.275rem 0.55rem",
                                                    margin: "0 3px",
                                                  }}
                                                  onClick={() =>
                                                    this.handleClickBaixarArquivo(
                                                      arquivo._id,
                                                      arquivo.nomeOriginal,
                                                      arquivo.status
                                                    )
                                                  }
                                                  // id={`tooltip-filtro-atendimentos`}
                                                  id={`tooltip-baixar-arquivos-${arquivo.id}`}
                                                >
                                                  <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={`tooltip-baixar-arquivos-${arquivo.id}`}
                                                >
                                                  Download
                                                </UncontrolledTooltip>
                                                <button
                                                  className="btn btn-danger btn-round"
                                                  onClick={() =>{

                                                    if(arquivo.status === 'A'){
                                                      this.handleClickDeletarArquivo(
                                                        arquivo._id
                                                      )
                                                    }else{
                                                      alert('Este arquivo já foi importado para o SAC OFF e não é mais possível a inativação.')
                                                    }

                                                  }
                                                   
                                                  }
                                                  id={`tooltip-deletar-arquivos-${arquivo.id}`}
                                                  style={{
                                                    padding: "0.275rem 0.55rem",
                                                    margin: "0 3px",
                                                  }}
                                                >
                                                  <i className="fa fa-times"></i>
                                                </button>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={`tooltip-deletar-arquivos-${arquivo.id}`}
                                                >
                                                  Deletar
                                                </UncontrolledTooltip>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* </div> */}
                              {/* <div className="table-responsive1">
                            
                              </div>{" "} */}
                            </div>
                          </React.Fragment>
                          )
                        }
                      </ToastConsumer>                      

                  </TabPane>

                 {this.state.ticket.anexos !== undefined && (
                   <TabPane tabId="anexos">
                      <div style={{ paddingTop: "15px" }}>
                      <table className="table table-bordered table-striped table-hover">
                        <thead className="thead-dark-custom thead-site">
                          <th style={{ borderTop: "none" }}>Descrição</th>
                          <th
                            style={{
                              borderTop: "none",
                              textAlign: "center",
                            }}
                          >
                            Data
                          </th>
                          <th className="text-center">Ações</th>
                        </thead>
                        <tbody>
                          {this.state.ticket.anexos.length > 0 &&
                            this.state.ticket.anexos.map((anexo, key) => (
                              <tr key={key}>
                                <td>{anexo.desc}</td>
                                <td className="text-center">
                                  {moment(anexo.dtGravacao).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                                <td className="text-center">
                                  <div style={{ margin: "3px" }}>
                                    <i
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                        color:"#28a745"
                                      }}
                                      className="fa fa-download"
                                      onClick={() => {
                                        this.baixarAnexo(anexo.url)
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                   </div>
                 </TabPane>
                 )}
                 {this.state.ticket.logs !== undefined && (
                   <TabPane tabId="logs">
                      <div style={{ paddingTop: "15px" }}>
                     <table className="table table-bordered table-striped table-hover">
                       <thead className="thead-dark-custom thead-site">
                         <th style={{ borderTop: "none" }}>Descrição</th>
                         <th
                           style={{
                             borderTop: "none",
                             textAlign: "center",
                           }}
                         >
                           Data
                         </th>
                       </thead>
                       <tbody>
                         {this.state.ticket.logs.length > 0 &&
                           this.state.ticket.logs.map((log, key) => (
                             <tr key={key}>
                               <td>{log.desc}</td>
                               <td className="text-center">
                                 {moment(log.dtGravacao).format(
                                   "DD/MM/YYYY HH:mm"
                                 )}
                               </td>
                             </tr>
                           ))}
                       </tbody>
                     </table>
                   </div>
                 </TabPane>
                 )}


                </TabContent>
                <ToastConsumer>
                  {
                    ({ show, hide }) => (
                      <ModalAvaliar
                        modalAvaliar={this.state.modalAvaliar}
                        toggleModalAvaliar={this.toggleModalAvaliar}
                        rating={this.state.rating}
                        comentario={this.state.comentarioAvaliacao}
                        handleChangeComentario={(comment) => this.setState({ comentarioAvaliacao: comment })}
                        handleRating={(rating) => this.setState({ rating: rating })}
                        handleSaveRating={async () => {

                          const toastProps = {
                            text: 'Ticket finalizado com sucesso',
                            actionText: 'Fechar',
                            ariaLabel: 'Ticket criado com sucesso, clique para fechar'
                          };

                          let url = `atendimento/finalizar/${this.state.id}/${this.state.apontamentoAtual.ordem}`;
                          let apontamentoAtual = this.state.apontamentoAtual ? this.state.apontamentoAtual : {};

                          let projeto = null;

                          if(this.state.ticket.tipo === 'Suporte' && !this.state.finalizacaoSucesso){
                            projeto = {idProjeto: this.state.projeto.id, idResponsavel: this.state.projeto.responsavel};
                          }
                          

                          let obj = {
                            apontamento : apontamentoAtual,
                            finalizacaoSucesso: this.state.finalizacaoSucesso,
                            projeto
                          }

                          await api.post(url, obj, this.props.parameters()).then(async (res) => {

                            let objectToSend = {
                              avaliacao: this.state.rating,
                              comentario: this.state.comentarioAvaliacao
                            }

                            await api.post(`avaliar/${this.state.id}`, objectToSend, this.props.parameters()).then(async (res) => {
                              let ticket = res.data.ticket;
                              let usuarioIniciouAtendimento = false;
                              this.setState({ ticket: ticket, usuarioIniciouAtendimento: usuarioIniciouAtendimento, apontamentoAtual: { usuarioCriacao: { _id: null } }, modal: false, modalAvaliacao: false, modalAvaliar: false }, async () => {
                                await this.updateActions();
                                show(({ ...toastProps, onActionClick: hide }));
                              });
                            });

                          });

                        }}
                        cancelRating={async () => {

                          const toastProps = {
                            text: 'Ticket finalizado com sucesso',
                            actionText: 'Fechar',
                            ariaLabel: 'Ticket criado com sucesso, clique para fechar'
                          };

                          let url = `atendimento/finalizar/${this.state.id}/${this.state.apontamentoAtual.ordem}`;
                          let apontamentoAtual = this.state.apontamentoAtual ? this.state.apontamentoAtual : {};

                          let projeto = null;

                          if(this.state.ticket.tipo === 'Suporte' && !this.state.finalizacaoSucesso){
                            projeto = {idProjeto: this.state.projeto.id, idResponsavel: this.state.projeto.responsavel};
                          }

                          let obj = {
                            apontamento : apontamentoAtual,
                            finalizacaoSucesso: this.state.finalizacaoSucesso,
                            projeto
                          }

                          await api.post(url, obj, this.props.parameters()).then(async (res) => {
                            let ticket = res.data.ticket;
                            let usuarioIniciouAtendimento = false;
                            this.setState({ ticket: ticket, usuarioIniciouAtendimento: usuarioIniciouAtendimento, apontamentoAtual: { usuarioCriacao: { _id: null } }, modal: false, modalAvaliacao: false, modalAvaliar: false }, async () => {
                              await this.updateActions();
                              show(({ ...toastProps, onActionClick: hide }));
                            });
                          });


                        }}
                        avaliacaoRequerida={this.state.avaliacaoRequerida}
                      />
                    )
                  }
                </ToastConsumer>




                <ToastConsumer>
                  {
                    ({ show, hide }) => (
                      <ModalAvaliacao
                        modalAvaliacao={this.state.modalAvaliacao}
                        toggleModalAvaliacao={this.toggleModalAvaliacao}
                        acceptRating={() => this.setState({ modalAvaliar: !this.state.modalAvaliar })}
                        notRating={async () => {
                          let url = `atendimento/finalizar/${this.state.id}/${this.state.apontamentoAtual.ordem}`;
                          let apontamentoAtual = this.state.apontamentoAtual ? this.state.apontamentoAtual : {};

                          let projeto = null;

                          if(this.state.ticket.tipo === 'Suporte' && !this.state.finalizacaoSucesso){
                            projeto = {idProjeto: this.state.projeto.id, idResponsavel: this.state.projeto.responsavel};
                          }
                          
                          let obj = {
                            apontamento : apontamentoAtual,
                            finalizacaoSucesso: this.state.finalizacaoSucesso,
                            projeto
                          }
                          
                          await api.post(url, obj, this.props.parameters()).then(async (res) => {
                            let ticket = res.data.ticket;
                            let usuarioIniciouAtendimento = false;
                            this.setState({ ticket: ticket, usuarioIniciouAtendimento: usuarioIniciouAtendimento, apontamentoAtual: { usuarioCriacao: { _id: null } }, modal: false, modalAvaliacao: false }, async () => {

                              const toastProps = {
                                text: 'Ticket finalizado com sucesso',
                                actionText: 'Fechar',
                                ariaLabel: 'Ticket criado com sucesso, clique para fechar'
                              };



                              await this.updateActions();
                              show(({ ...toastProps, onActionClick: hide }));
                            });
                          });
                        }}
                      />
                    )
                  }
                </ToastConsumer>

                <ToastConsumer>
                  {
                    ({ show, hide }) => (
                      <ModalConfirm
                        modal={this.state.modalConfirm}
                        toggle={this.toggleConfirm}
                        text={'Tem certeza que deseja cancelar o Ticket?'}
                        onClickYes={() => this.onClickCancelService({ toast: { show, hide } })}
                        onClickNo={() => this.toggleConfirm()}
                      />
                    )
                  }
                </ToastConsumer>


                <Modal isOpen={this.state.modalApp.alert} toggle={this.toggleModalAlert}>
                  <ModalHeader toggle={this.toggleModalAlert}>Atenção</ModalHeader>
                  <ModalBody>

                    <div>Para Finalizar o atendimento, o ticket deve ter pelo menos um apontamento. </div>

                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={this.toggleModalAlert}>Fechar</Button>
                  </ModalFooter>
                </Modal>    

                <Modal isOpen={this.state.modalImpedimento} toggle={this.toggleModalImpedimento}>
                  <ModalHeader toggle={this.toggleModalImpedimento}>Finalizar Atendimento</ModalHeader>
                  <ModalBody>
                    <FormGroup className="">
                      <Label>Projeto: </Label>
                      <Select
                        value={this.state.projeto}
                        onChange={this.onChangeProjeto}
                        options={this.state.projetos}
                      />
                      {this.state.errors.projeto && 
                        <div className="is-invalid-feedback">
                          Por favor selecione um projeto.
                        </div>
                      }
                    </FormGroup>        
                  </ModalBody>
                  <ModalFooter>   
                    <Button 
                      className="button-impedimento" 
                      onClick={() => {

                        if(this.state.projeto === null){
                          let errors = this.state.errors;
                          errors.projeto = true;
                          this.setState({errors});
                          return false;                        
                        }

                        this.setState(
                          {
                            finalizacaoSucesso: false, 
                            modalAvaliacao: !this.state.modalAvaliacao, 
                            modalImpedimento: false
                          }
                        )
                      }}>
                        <i className="fa fa-hospital-o" aria-hidden="true"></i> 
                        Impedimento
                      </Button>
                      
                    <Button onClick={() => this.setState({modalAvaliacao: !this.state.modalAvaliacao, modalImpedimento :false} )}><i className="fa fa-stop" aria-hidden="true"></i> Finalizar</Button>  
                    <Button color="danger" onClick={this.toggleModalImpedimento}>Cancelar</Button>
                  </ModalFooter>
                </Modal>

              </Col>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailTicket;  