import React from 'react';
import { Modal} from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import "./Carregando.css";

const Carregando = (props) => { 
    return (
        <Modal id="load-modal" backdropClassName="back-modal" size="lg" backdrop="static" isOpen={props.modalLoad} toggle={props.toggleLoad}>
            <div id="div-load-modal" style={{ textAlign:"center" }}>
                <img src="/images/load.gif"/>
            </div>
        </Modal>);
};

export default Carregando;