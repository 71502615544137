import React, { Component } from 'react';
import { Button, UncontrolledTooltip, Col, Input, Label } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Pagination from 'react-js-pagination';
import "./Historico.css";

class Historico extends Component {
  constructor(props) {
    super(props);
    this.handleInputFiltroChange = this.handleInputFiltroChange.bind(this);
    this.onChangeInputClient = this.onChangeInputClient.bind(this);
    this.limparFiltro = this.limparFiltro.bind(this);
    this.actionFiltrar = this.actionFiltrar.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.getTickets = this.getTickets.bind(this);
    this.paginar = this.paginar.bind(this);

    this.state = {
      cliente:null,
      tickets: [],      
      ticketsBkp: [],
      clientes: [], //LISTA DE CLIENTES
      ticketsPaginados: [],
      filtro: {
        pesquisa: {
          codigo: '',
          situacao: '',
          tipo: '',
          cliente:'',
          periodo: 0
        },
        ordenacao: {
          coluna: 'codigo', // ['codigo', 'usuario', 'dataEmissao', 'situacao', 'tipo']
          tipo: 'ASC'
        }        
      },
      paginacao:{
        pagina: 1,
        limite: 15,
        totalItens: 0
      },
      periodo:{
        inicial: new Date(),
        fim: new Date()
      }
    }
  }

  async componentDidMount() {
    localStorage.setItem('prevPage','history');
    await this.loadClients();      
  }

  onChangeInputClient(cliente) {
    let client = Object.assign({}, cliente);
    client.contatos = client.contatos.map(contato => {
      contato.value = contato.descricao + ' - ' + contato.fone;
      contato.label = contato.descricao + ' - ' + contato.fone;
      return contato;
    });

    let tipoAtendimento = this.state.tipo;

    if(tipoAtendimento === 'Suporte'){     
      let contatosFranquia = client.contatos.filter(c => c.usaFranquia === true);
      client.contatos = [];
      client.contatos = contatosFranquia;
    }

    this.setState({
      cliente: cliente.id,
      contatos: client.contatos,
      contato: client.contatos[0]
    });
  }

  async getTickets(){
    this.limparFiltro();
    let inicial = this.state.periodo.inicial;
    let final = this.state.periodo.fim;
    await axios.post(`${this.props.getBaseUrlApi()}/tickets/history/`,{inicial,final} ,this.props.parameters())
    .then((response) => {
      let tickets = response.data.tickets;
        for (let i = 0; i < tickets.length; i++) {

          tickets[i].situacaoInt = tickets[i].situacao.toString();

          if(!tickets[i].hasOwnProperty('dataFinalizacao')){
            tickets[i].dataFinalizacao = null;
            tickets[i].dataFinalizacaoString = ' - ';
          }

          if (tickets[i].situacao === 0) {
            tickets[i].situacao = 'Pendente';
            tickets[i].situacaoClass = 'aFazer';
          } if (tickets[i].situacao === 1) {
            tickets[i].situacao = 'Agendado';
            tickets[i].situacaoClass = 'agendado';
          } if (tickets[i].situacao === 2) {
            tickets[i].situacao = 'Atendendo';
            tickets[i].situacaoClass = 'atendendo';
          } if (tickets[i].situacao === 3) {
            tickets[i].situacao = 'Parado';
            tickets[i].situacaoClass = 'parado';
          }if (tickets[i].situacao === 4) {
            tickets[i].situacao = 'Finalizado';
            tickets[i].situacaoClass = 'finalizado';
          }if (tickets[i].situacao === 5) {
            tickets[i].situacao = 'Cancelado';
            tickets[i].situacaoClass = 'cancelado';
          }
          if (tickets[i].situacao === 6) {
            tickets[i].situacao = 'Em Análise';
            tickets[i].situacaoClass = 'emAnalise';
          }
          tickets[i].usuarioDescricao = tickets[i].usuario ? tickets[i].usuario.descricao.split(' ')[0] : 'NÃO VINCULADO';

          if (tickets[i].assunto.length > 100) {
            tickets[i].assuntoResumido = tickets[i].assunto.substr(0, 100) + '...';
          } else {
            tickets[i].assuntoResumido = tickets[i].assunto;
          }

          if(tickets[i].dataFinalizacao !== null){
            tickets[i].dataFinalizacao = new Date(tickets[i].dataFinalizacao);
            tickets[i].dataFinalizacaoString = new Date(tickets[i].dataFinalizacao).toLocaleDateString();
          }

          tickets[i].dataCriacao = new Date(tickets[i].dataCriacao);
        }
        
      this.setState({ tickets, ticketsBkp: tickets, ticketsPaginados : tickets }, () => this.actionFiltrar());
    }).catch(err => {
      console.log('rrurur', err)
      this.props.handlingErrorsApi(JSON.stringify(err), this.props)});

  }

  async loadClients() {
    await axios.get(`${this.props.getBaseUrlApi()}/clientes`, this.props.parameters())
      .then(response => {
        let clientes = response.data.clientes;
        clientes = clientes.map(cliente => {
          cliente.descricao = cliente.fantasia;
          cliente.value = cliente.descricao;
          cliente.label = cliente.descricao;
          return cliente;
        });
        this.setState({ clientes: clientes });
      })
      .catch(err => this.props.handlingErrorsApi(err, this.props));
  }

  handleInputFiltroChange(e, tipo) {
    let value = '';
    if(tipo == 'cliente'){
      value = e.id;
    } else {
      value = e.target.value;    
    }
    let filtro = this.state.filtro;
    filtro.pesquisa[tipo] = tipo === 'codigo' ? (value).toString().toUpperCase() : value;    
    this.setState({filtro},() => this.actionFiltrar());
  }

  limparFiltro() {
    let filtro = this.state.filtro;
    filtro.pesquisa.codigo = "";
    filtro.pesquisa.situacao = "";
    filtro.pesquisa.tipo = "";
    filtro.pesquisa.periodo = 0;
    this.setState({
      filtro, 
      cliente:null, 
      tickets:[], 
      paginacao:{
        pagina: 1,
        limite: 15,
        totalItens: 0
      }
    });
  }

  async actionFiltrar(){
    let filtro = this.state.filtro;
    let tickets = this.state.ticketsBkp.concat();

    if (filtro.pesquisa.codigo !== '') {
      tickets = tickets.filter((ticket) => {
        let ticketComparer = Object.assign({}, ticket);
        let codigo = filtro.pesquisa.codigo;
        if (ticketComparer.codigo.includes(codigo)) {
          return ticket;
        }
      });
    }

    if (filtro.pesquisa.tipo !== '') {
      tickets = tickets.filter(ticket => ticket.tipo === filtro.pesquisa.tipo);
    }

    if (filtro.pesquisa.situacao !== '') {

      tickets = tickets.filter(ticket => {
        if(ticket.situacaoInt === filtro.pesquisa.situacao) return ticket;

      });
    }

    if (filtro.pesquisa.cliente !== '') {
      tickets = tickets.filter((ticket) => {
        if(ticket.cliente.id == filtro.pesquisa.cliente)
          return ticket;
      })
    }

    let dataInicial = "";

    if (filtro.pesquisa.periodo !== '' && filtro.pesquisa.periodo !== 'null' & filtro.pesquisa.periodo !== null) {

      if(filtro.pesquisa.periodo == 0){ // MES ATUAL

        let date = new Date();
        dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
    
        tickets = tickets.filter(ticket => {
          
          if(ticket.dataFinalizacao !== null){
            if(moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial)){
              return ticket;
            }
          }else{
            if(moment(ticket.dataCriacao).isSameOrAfter(dataInicial)){
              return ticket;
            }
          }
        });

      }else if(filtro.pesquisa.periodo == 1){ // MES PASSADO
       // dataInicial = moment().subtract(1, "months").format("YYYY-MM") + "-01";
       let date = new Date();
       dataInicial = new Date(date.getFullYear(), date.getMonth()-1, 1);

       const lastday = function(y,m){
        return  new Date(y, m +1, 0);
        }

        let dataFinal = lastday(dataInicial.getFullYear(), dataInicial.getMonth());
        
        tickets = tickets.filter(ticket => {
          if(ticket.situacao === 'Finalizado'){
            if(moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial) && moment(ticket.dataFinalizacao).isSameOrBefore(dataFinal)){
              return ticket;
            }            
          }    
        });

      }else if(filtro.pesquisa.periodo == 2){ // ULTIMOS 30 DIAS
        dataInicial = moment().subtract(30, "days").format("YYYY-MM-DD");               
        
        tickets = tickets.filter(ticket => {          
          if(ticket.dataFinalizacao !== null){
            if(moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial)){
              return ticket;
            }
          }else{
            if(moment(ticket.dataCriacao).isSameOrAfter(dataInicial)){
              return ticket;
            }
          }
        });
      }
    }

    let ticketsAberto = tickets.filter(ticket => ticket.dataFinalizacao === null);
    let ticketsFinalizados = tickets.filter(ticket => ticket.dataFinalizacao !== null);
    let ticketsOrdenados = [].concat(ticketsAberto);
    ticketsFinalizados = ticketsFinalizados.sort(function (a, b) { return a.dataFinalizacao.getTime() - b.dataFinalizacao.getTime() });
    ticketsOrdenados = ticketsOrdenados.concat(ticketsFinalizados);
    this.setState({tickets : ticketsOrdenados}, () => this.paginar(1));
  }


  paginar(pagina = 1){
    const LIMIT = this.state.paginacao.limite;
    let tickets = this.state.tickets.concat();
    pagina = pagina -1;
    tickets = tickets.slice(pagina * LIMIT, (pagina + 1) * LIMIT);
    pagina = pagina +1;

    let paginacao = this.state.paginacao;
    paginacao.pagina = pagina;
    paginacao.totalItens = this.state.tickets.length;
    this.setState({ticketsPaginados: tickets, paginacao});
  }

  async onChangeDate(date, type) {
    let periodo = this.state.periodo;
    periodo[type] = date;
    await this.setState({
      periodo: periodo
    });
  }

  render() {
    return (
      <div>
        <div id="contentSite">
          <div className="">
            <div className="container">
              <div className="Example_box">
                <div style={{padding:"15px 30px", fontSize: "14px"}} className="font-tickets">

                 
                      <div className="row" style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}>
                      <div className="form-group col-md-2">
                        <label for="inputCity" className="label-form" style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Cliente:
                        </label>
                        <Select
                          defaultValue={this.state.cliente}
                          onChange={(e) => this.handleInputFiltroChange(e, 'cliente')}
                          options={this.state.clientes}
                          focus={false}
                        />
                      </div>
                      <div className="form-group col-md-2 group-tickets noprint">
                          <label for="inputCity" className="label-form" style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Código
                        </label>
                          <input
                            type="text"
                            className='form-control input-sm input-filter'
                            value={this.state.filtro.pesquisa.codigo}
                            onChange={(e) => this.handleInputFiltroChange(e, 'codigo')}

                          />
                        </div>

                        <div className="form-group col-md-2 group-tickets noprint">
                          <label for="inputCity" className="label-form" style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Situação
                        </label>
                          <select
                            className='form-control input-sm input-filter'
                            value={this.state.filtro.pesquisa.situacao}
                            onChange={(e) => this.handleInputFiltroChange(e, 'situacao')}>
                              <option value="">Todos</option>
                              <option value="0">Pendente</option>
                              <option value="1">Agendado</option>
                              <option value="2">Atendendo</option>
                              <option value="6">Em Análise</option>
                              <option value="3">Parado</option>
                              <option value="4">Finalizado</option>
                              <option value="5">Cancelado</option>                              
                          </select>
                        </div>

                        <div className="form-group col-md-2 group-tickets noprint">
                          <label for="inputCity" className="label-form" style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Tipo
                          </label>
                          <select
                            className='form-control input-sm input-filter'
                            value={this.state.filtro.pesquisa.tipo}
                            onChange={(e) => this.handleInputFiltroChange(e, 'tipo')}>
                              <option value="">Todos</option>
                              <option value="Suporte">Suporte</option>
                              <option value="Atendimento Presencial">Atendimento Presencial</option>
                              <option value="Atendimento Remoto">Atendimento Remoto</option>
                              <option value="Atendimento Não Franqueado">Atendimento Não Franqueado</option>
                              <option value="Atendimento Horário Especial">Atendimento Horário Especial</option>
                              <option value="Desenvolvimento Sob Medida">Desenvolvimento Sob Medida</option>
                          </select>
                        </div>

                        <div className="form-group col-md-3 group-tickets noprint">
                          <label for="inputCity" className="label-form" style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Período
                          </label>
                          <div className='col-lg-12 row' style={{ padding:0, marginLeft:'3px' }}>
                            <Col sm={5} style={{ padding:0 }}> 
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                customInput={<Input />}
                                selected={this.state.periodo.inicial}
                                onChange={(dt) => {this.onChangeDate(dt, 'inicial')}}
                              />
                            
                            </Col>
                            <Col sm={1}  style={{ padding:3,  marginLeft:'3px' }}> 
                              <Label className="label-form">até</Label>
                            </Col>
                            <Col sm={5} style={{ padding:0, marginLeft:'4px' }}> 
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                customInput={<Input />}
                                selected={this.state.periodo.fim}
                                onChange={(dt) => {this.onChangeDate(dt, 'fim')}}
                              />
                            
                            </Col>
                          </div>
                        </div>
                       

                        <div className="form-group col-md-1 noprint" style={{ marginTop: "11px", padding: "0" }}>
                          <button className="btn btn-secondary btn-sm" id="btn-eraser-todos-tickets" style={{ padding: '.275rem .55rem', margin: "10px 5px 0px 5px" }} onClick={() => this.getTickets()}>
                            <i className="fa fa-filter" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip placement="right" target='btn-eraser-todos-tickets'>
                            Filtrar
                          </UncontrolledTooltip>

                          <button className="btn btn-danger btn-sm noprint" id="btn-filter-todos-tickets" style={{ padding: '.275rem .55rem', margin: "10px 5px 0px 5px" }} onClick={() => this.limparFiltro()}>
                              <i className="fa fa-eraser" aria-hidden="true"></i>
                            </button>
                            <UncontrolledTooltip placement="right" target='btn-filter-todos-tickets'>
                              Limpar filtro
                          </UncontrolledTooltip>

                        </div>


                      </div>            

                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px" }}
                      >
                        <thead className="thead-dark-custom thead-site thead-print">
                          <tr>
                            <th style={{ maxWidth: "115px" }}>Código</th>
                            <th style={{ maxWidth: "120px" }}>Situação</th>
                            <th style={{ maxWidth: "270px" }}>Tipo</th>
                            <th>Data Agenda</th>
                            <th>Assunto</th>
                            <th style={{ maxWidth: "150px" }}>Atendente</th>
                            <th style={{ maxWidth: "150px" }} >Finalização</th>
                            <th className="noprint">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tickets.length && this.state.ticketsPaginados.map((ticket, key) => {return(
                            <React.Fragment>
                              <tr key={key} className="tr-grid">
                                <th scope="row" style={{ maxWidth: "115px" }}>
                                  {ticket.codigo}
                                </th>
                                <td style={{ maxWidth: "120px", minWidth: "110px" }}>
                                  <span style={{ width: '100%', fontWeight: 'bold', maxWidth: "150px" }}>
                                    <span className={"tag " + ticket.situacaoClass}>{ticket.situacao}</span>
                                  </span>
                                </td>
                                <td style={{ maxWidth: "270px" }}>{ticket.tipo}
                                </td>
                                <td style={{ maxWidth: "270px" }}>{ticket.dataAgenda === null ? '-' : moment(ticket.dataAgenda).utcOffset('-03:00').format('DD/MM/YYYY HH:mm')}
                                </td>
                                <td
                                  style={{ maxWidth: "480px" }}
                                >
                                  {ticket.contestacao !== null && ticket.contestacao.aceita === null && 
                                    <React.Fragment>
                                      <i className="fa fa-exclamation-circle icon-contestacao" aria-hidden="true" id={`contestacao-${ticket._id}`}></i>{' '}
                                      <UncontrolledTooltip placement="right" target={"contestacao-" + ticket._id}>
                                        Ticket contestado
                                      </UncontrolledTooltip>
                                    </React.Fragment>                                  
                                  }
                                  {ticket.assuntoResumido}
                                </td>
                                <td style={{ maxWidth: "150px" }}>
                                  {ticket.usuarioDescricao}
                                </td>
                                <td style={{ maxWidth: "150px" }}>
                                  {ticket.dataFinalizacaoString}
                                </td>


                                <td className="noprint">
                                  <Link to={`/tickets/detail/${ticket._id}`}>
                                    <Button color="secondary" id={"tooltip-" + ticket._id} style={{ padding: '.275rem .55rem' }}>
                                      <i className="fa fa-eye" aria-hidden="true"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="right" target={"tooltip-" + ticket._id}>
                                      Visualizar Ticket
                                    </UncontrolledTooltip>
                                  </Link>
                                </td>
                              </tr>
                            </React.Fragment>
                          )})}
                        </tbody>
                      </table>
                      
                      <div className="noprint" style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}>
                      {this.state.paginacao.totalItens > 0 && 
                        <div className="paginator">
                          <Pagination
                            prevPageText={<i className='fa fa-angle-left'/>}
                            nextPageText={<i className='fa fa-angle-right'/>}
                            firstPageText={<i className='fa fa-angle-double-left'/>}
                            lastPageText={<i className='fa fa-angle-double-right'/>}
                            activePage={this.state.paginacao.pagina}
                            itemsCountPerPage={this.state.paginacao.limite}
                            totalItemsCount={this.state.paginacao.totalItens}
                            onChange={(pagina) => this.paginar(pagina)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      } 
                      </div>            
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Historico;