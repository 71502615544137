import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ModalAlert = (props) => {
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalBody>

        <p style={{ fontSize: '18px' }}>{props.text}</p>

      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>Fechar</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAlert;