import React, { Component } from "react";
import {
  Button,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
  Col,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ToastConsumer } from "react-awesome-toasts";
import Pagination from "react-js-pagination";

class Cronograma extends Component {
  constructor(props) {
    super(props);
    this.toggleTab = this.toggleTab.bind(this);
    this.salvarFiltro = this.salvarFiltro.bind(this);
    this.ordenar = this.ordenar.bind(this);
    this.handleInputFiltroChange = this.handleInputFiltroChange.bind(this);
    this.actionFiltrar = this.actionFiltrar.bind(this);
    this.filtro = this.filtro.bind(this);
    this.limparFiltro = this.limparFiltro.bind(this);
    this.limparFiltroSix = this.limparFiltroSix.bind(this);
    this.getTicketsContestados = this.getTicketsContestados.bind(this);
    this.toggleModalContestacao = this.toggleModalContestacao.bind(this);
    this.onClickBotaoAvaliarContestacao =
      this.onClickBotaoAvaliarContestacao.bind(this);
    this.onChangeMensagemAvaliacao = this.onChangeMensagemAvaliacao.bind(this);
    this.avaliarContestacao = this.avaliarContestacao.bind(this);
    this.getTicketsFinalizados = this.getTicketsFinalizados.bind(this);
    this.paginar = this.paginar.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.loadPreTickets = this.loadPreTickets.bind(this);
    this.limparFiltroPreTickets = this.limparFiltroPreTickets.bind(this);
    this.handleChangeInputFiltroPreTickets = this.handleChangeInputFiltroPreTickets.bind(this);

    this.state = {
      cliente: null,
      ticketsPaginados: [],
      tickets: [],
      ticketsUsuario: [],
      ticketsSuporte: [],
      activeTab: "1",
      filtro: null,
      ticketsBkp: [],
      ticketFinalBkp: [],
      ticketsUsuarioBkp: [],
      ticketsSuporteBkp: [],
      pendencias: [],
      pendenciasBkp: [],
      ticketsContestados: [],
      modalContestacao: false,
      mensagemContestacao: "",
      mensagemAvaliacao: "",
      idTicketContestacao: null,
      errors: {
        mensagemContestacao: false,
      },
      codigoTicketConstestacao: "",
      clienteContestacao: "",
      exibirMensagemMeusTickets: false,
      exibirMensagemTickets: false,
      exibirMensagemPendencias: false,
      exibirMensagemTicketsSuporte: false,
      exibirMensagemContestacoes: false,
      loading: true,
      loadingContestacoes: true,
      filtros: {
        pesquisa: {
          codigo: "",
          situacao: "",
          tipo: "",
          periodo: 0,
          cliente: "",
        },
        ordenacao: {
          coluna: "codigo", // ['codigo', 'usuario', 'dataEmissao', 'situacao', 'tipo']
          tipo: "ASC",
        },
      },
      paginacao: {
        pagina: 1,
        limite: 15,
        totalItens: 0,
      },
      finalizado: {
        inicial: new Date(),
        fim: new Date(),
      },
      exibirAbaPreTickets: false,
      executouLoadPreTickets: false,
      preTickets: [],
      filtroPreTickets: {
        cliente: "",
        tipoPortal: "Todos",
        situacao: "Todos"
      }
    };
  }

  async componentDidMount() {
    await this.loadClients();
    let user = JSON.parse(this.props.user());
    let filtroStr = localStorage.getItem("filtro");
    let filtro = {};

    if (filtroStr !== null) {
      let filtroTeste = JSON.parse(filtroStr);

      if (!filtroTeste.hasOwnProperty("version")) {
        filtroStr = null;
      } else {
        if (filtroTeste.version !== 4) {
          filtroStr = null;
        }
      }
    }

    if (filtroStr === null) {
      filtro = {
        items: [
          {
            pesquisa: {
              codigo: "",
              cliente: "",
              tipo: "",
              usuario: "",
            },
            ordenacao: {
              coluna: "codigo", // ['codigo', 'cliente', 'usuario', 'dataEmissao', 'situacao']
              tipo: "ASC",
            },
          },
          {
            pesquisa: {
              codigo: "",
              cliente: "",
              tipo: "",
              usuario: "",
            },
            ordenacao: {
              coluna: "codigo",
              tipo: "ASC",
            },
          },
          {
            pesquisa: {
              codigo: "",
              cliente: "",
              tipo: "",
              usuario: "",
            },
            ordenacao: {
              coluna: "codigo",
              tipo: "ASC",
            },
          },
          {
            pesquisa: {
              codigo: "",
              cliente: "",
              tipo: "",
              usuario: "",
            },
            ordenacao: {
              coluna: "codigo",
              tipo: "ASC",
            },
          },
        ],
        abaAtiva: "1",
        version: 4,
      };

      localStorage.setItem("filtro", JSON.stringify(filtro));
    } else {
      filtro = JSON.parse(filtroStr);
    }

    //user.analisaPreTicketSuporte = false;

    if (filtro.abaAtiva === "7") {
      if (
        !user.analisaPreTicketSuporte &&
        !user.analisaPreTicketConsultoria &&
        !user.analisaPreTicketDesenvolvimento
      ) {
        filtro.abaAtiva = "1";
      }
    }

    let exibirAbaPreTickets = this.state.exibirAbaPreTickets;
    if (
      user.analisaPreTicketSuporte ||
      user.analisaPreTicketConsultoria ||
      user.analisaPreTicketDesenvolvimento
    ) {
      exibirAbaPreTickets = true;
    }

    if (exibirAbaPreTickets && filtro.abaAtiva === "7") {
      // CHAMA LOOOAD
      this.loadPreTickets();
    }

    this.setState({ filtro, activeTab: filtro.abaAtiva, exibirAbaPreTickets });

    await axios
      .get(`${this.props.getBaseUrlApi()}/tickets`, this.props.parameters())
      .then((response) => {
        let tickets = response.data.tickets;
        let ticketsUsuario = response.data.ticketsUsuario;

        let ticketsSuporte = response.data.ticketsSuporte;
        let pendencias = response.data.pendencias;

        for (let i = 0; i < tickets.length; i++) {
          //MUDA O DESCRITIVO DA SITUACAO
          if (tickets[i].situacao === 0) {
            tickets[i].situacao = "Pendente";
            tickets[i].situacaoClass = "aFazer";
          }
          if (tickets[i].situacao === 1) {
            tickets[i].situacao = "Agendado";
            tickets[i].situacaoClass = "agendado";
          }
          if (tickets[i].situacao === 2) {
            tickets[i].situacao = "Atendendo";
            tickets[i].situacaoClass = "atendendo";
          }
          if (tickets[i].situacao === 3) {
            tickets[i].situacao = "Parado";
            tickets[i].situacaoClass = "parado";
          }
          if (tickets[i].situacao === 6) {
            tickets[i].situacao = "Em Análise";
            tickets[i].situacaoClass = "emAnalise";
          }
          tickets[i].clienteDescricao = tickets[i].cliente.descricao;
          tickets[i].usuarioDescricao = tickets[i].usuario
            ? tickets[i].usuario.descricao.split(" ")[0]
            : "NÃO VINCULADO";

          if (tickets[i].assunto.length > 100) {
            tickets[i].assuntoResumido =
              tickets[i].assunto.substr(0, 100) + "...";
          } else {
            tickets[i].assuntoResumido = tickets[i].assunto;
          }
          if (tickets[i].clienteDescricao.length > 18) {
            tickets[i].clienteDescricaoResumida =
              tickets[i].clienteDescricao.substr(0, 18) + "...";
          } else {
            tickets[i].clienteDescricaoResumida = tickets[i].clienteDescricao;
          }

          tickets[i].dataEmissao = new Date(tickets[i].dataCriacao);
          tickets[i].horaCriacao = moment(tickets[i].dataCriacao).format(
            "HH:mm"
          );
          tickets[i].dataCriacao = new Date(
            tickets[i].dataCriacao
          ).toLocaleDateString();
        }
        for (let i = 0; i < ticketsUsuario.length; i++) {
          //MUDA O DESCRITIVO DA SITUACAO
          if (ticketsUsuario[i].situacao === 0) {
            ticketsUsuario[i].situacao = "Pendente";
            ticketsUsuario[i].situacaoClass = "aFazer";
          }
          if (ticketsUsuario[i].situacao === 1) {
            ticketsUsuario[i].situacao = "Agendado";
            ticketsUsuario[i].situacaoClass = "agendado";
          }
          if (ticketsUsuario[i].situacao === 2) {
            ticketsUsuario[i].situacao = "Atendendo";
            ticketsUsuario[i].situacaoClass = "atendendo";
          }
          if (ticketsUsuario[i].situacao === 3) {
            ticketsUsuario[i].situacao = "Parado";
            ticketsUsuario[i].situacaoClass = "parado";
          }
          if (ticketsUsuario[i].situacao === 6) {
            ticketsUsuario[i].situacao = "Em Análise";
            ticketsUsuario[i].situacaoClass = "emAnalise";
          }
          ticketsUsuario[i].clienteDescricao =
            ticketsUsuario[i].cliente.descricao;
          ticketsUsuario[i].usuarioDescricao = ticketsUsuario[i].usuario
            ? ticketsUsuario[i].usuario.descricao.split(" ")[0]
            : "NÃO VINCULADO";
          if (ticketsUsuario[i].assunto.length > 100) {
            ticketsUsuario[i].assuntoResumido =
              ticketsUsuario[i].assunto.substr(0, 100) + "...";
          } else {
            ticketsUsuario[i].assuntoResumido = ticketsUsuario[i].assunto;
          }
          ticketsUsuario[i].dataEmissao = new Date(
            ticketsUsuario[i].dataCriacao
          );
          ticketsUsuario[i].horaCriacao = moment(
            ticketsUsuario[i].dataCriacao
          ).format("HH:mm");
          ticketsUsuario[i].dataCriacao = new Date(
            ticketsUsuario[i].dataCriacao
          ).toLocaleDateString();

          ticketsUsuario[i].clienteDescricaoResumida =
            ticketsUsuario[i].clienteDescricao.substr(0, 18) + "...";
          if (ticketsUsuario[i].clienteDescricao.length > 18) {
            ticketsUsuario[i].clienteDescricaoResumida =
              ticketsUsuario[i].clienteDescricao.substr(0, 18) + "...";
          } else {
            ticketsUsuario[i].clienteDescricaoResumida =
              ticketsUsuario[i].clienteDescricao;
          }
        }
        for (let i = 0; i < pendencias.length; i++) {
          //MUDA O DESCRITIVO DA SITUACAO
          if (pendencias[i].situacao === 0) {
            pendencias[i].situacao = "Pendente";
            pendencias[i].situacaoClass = "aFazer";
          }
          if (pendencias[i].situacao === 1) {
            pendencias[i].situacao = "Agendado";
            pendencias[i].situacaoClass = "agendado";
          }
          if (pendencias[i].situacao === 2) {
            pendencias[i].situacao = "Atendendo";
            pendencias[i].situacaoClass = "atendendo";
          }
          if (pendencias[i].situacao === 3) {
            pendencias[i].situacao = "Parado";
            pendencias[i].situacaoClass = "parado";
          }
          if (pendencias[i].situacao === 6) {
            pendencias[i].situacao = "Em Análise";
            pendencias[i].situacaoClass = "emAnalise";
          }
          pendencias[i].clienteDescricao = pendencias[i].cliente.descricao;
          pendencias[i].usuarioDescricao = pendencias[i].usuario
            ? pendencias[i].usuario.descricao.split(" ")[0]
            : "NÃO VINCULADO";
          if (pendencias[i].assunto.length > 100) {
            pendencias[i].assuntoResumido =
              pendencias[i].assunto.substr(0, 100) + "...";
          } else {
            pendencias[i].assuntoResumido = pendencias[i].assunto;
          }
          pendencias[i].dataEmissao = new Date(pendencias[i].dataCriacao);
          pendencias[i].dataCriacao = new Date(
            pendencias[i].dataCriacao
          ).toLocaleDateString();

          pendencias[i].clienteDescricaoResumida =
            pendencias[i].clienteDescricao.substr(0, 18) + "...";
          if (pendencias[i].clienteDescricao.length > 18) {
            pendencias[i].clienteDescricaoResumida =
              pendencias[i].clienteDescricao.substr(0, 18) + "...";
          } else {
            pendencias[i].clienteDescricaoResumida =
              pendencias[i].clienteDescricao;
          }
        }

        for (let i = 0; i < ticketsSuporte.length; i++) {
          //MUDA O DESCRITIVO DA SITUACAO
          if (ticketsSuporte[i].situacao === 0) {
            ticketsSuporte[i].situacao = "Pendente";
            ticketsSuporte[i].situacaoClass = "aFazer";
          }
          if (ticketsSuporte[i].situacao === 1) {
            ticketsSuporte[i].situacao = "Agendado";
            ticketsSuporte[i].situacaoClass = "agendado";
          }
          if (ticketsSuporte[i].situacao === 2) {
            ticketsSuporte[i].situacao = "Atendendo";
            ticketsSuporte[i].situacaoClass = "atendendo";
          }
          if (ticketsSuporte[i].situacao === 3) {
            ticketsSuporte[i].situacao = "Parado";
            ticketsSuporte[i].situacaoClass = "parado";
          }
          if (ticketsSuporte[i].situacao === 6) {
            ticketsSuporte[i].situacao = "Em Análise";
            ticketsSuporte[i].situacaoClass = "emAnalise";
          }
          ticketsSuporte[i].clienteDescricao =
            ticketsSuporte[i].cliente.descricao;
          ticketsSuporte[i].usuarioDescricao = ticketsSuporte[i].usuario
            ? ticketsSuporte[i].usuario.descricao.split(" ")[0]
            : "NÃO VINCULADO";
          if (ticketsSuporte[i].assunto.length > 100) {
            ticketsSuporte[i].assuntoResumido =
              ticketsSuporte[i].assunto.substr(0, 100) + "...";
          } else {
            ticketsSuporte[i].assuntoResumido = ticketsSuporte[i].assunto;
          }
          ticketsSuporte[i].dataEmissao = new Date(
            ticketsSuporte[i].dataCriacao
          );
          ticketsSuporte[i].horaCriacao = moment(
            ticketsSuporte[i].dataCriacao
          ).format("HH:mm");
          ticketsSuporte[i].dataCriacao = new Date(
            ticketsSuporte[i].dataCriacao
          ).toLocaleDateString();

          ticketsSuporte[i].clienteDescricaoResumida =
            ticketsSuporte[i].clienteDescricao.substr(0, 18) + "...";
          if (ticketsSuporte[i].clienteDescricao.length > 18) {
            ticketsSuporte[i].clienteDescricaoResumida =
              ticketsSuporte[i].clienteDescricao.substr(0, 18) + "...";
          } else {
            ticketsSuporte[i].clienteDescricaoResumida =
              ticketsSuporte[i].clienteDescricao;
          }
        }

        let descUsuario = JSON.parse(this.props.user()).descricao;

        let pendenciasUsuario = pendencias.filter(
          (pendencia) =>
            pendencia.usuario !== null &&
            pendencia.usuario.descricao === descUsuario
        );

        ticketsUsuario = ticketsUsuario.concat(pendenciasUsuario);

        this.setState(
          {
            tickets: response.data.tickets,
            ticketsUsuario,
            ticketsBkp: tickets,
            ticketsUsuarioBkp: ticketsUsuario,
            pendencias,
            pendenciasBkp: pendencias,
            ticketsSuporte,
            ticketsSuporteBkp: ticketsSuporte,
            loading: false,
          },
          async () => {
            await this.filtro(null, "1", null);
            await this.filtro(null, "2", null);
            await this.filtro(null, "3", null);
            await this.filtro(null, "4", null);
            let abaAtiva = this.state.activeTab - 1;
            if (
              this.state.activeTab !== "5" &&
              this.state.activeTab !== "6" &&
              this.state.activeTab !== "7"
            ) {
              this.ordenar(this.state.filtro.items[abaAtiva].ordenacao.coluna);
            }
            this.getTicketsContestados();
          }
        );
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  toggleTab(tab) {
    let filtro = this.state.filtro;
    if (this.state.activeTab !== tab && filtro !== null) {
      this.setState({
        activeTab: tab,
      });
      filtro.abaAtiva = tab;
      this.salvarFiltro(filtro);

      if(tab === "7" && !this.state.executouLoadPreTickets){
        this.loadPreTickets()
      }
    }
  }

  async onChangeDate(date, type) {
    let finalizado = this.state.finalizado;
    finalizado[type] = date;
    await this.setState({
      finalizado: finalizado,
    });
  }

  salvarFiltro(filtro) {
    localStorage.setItem("filtro", JSON.stringify(filtro));
    this.setState({ filtro });
  }

  ordenar(coluna) {
    if (this.state.filtro !== null) {
      const abaAtual = parseInt(this.state.activeTab) - 1;

      let listaFiltro = this.state.filtro;
      let filtro = listaFiltro.items[abaAtual];

      if (filtro.ordenacao.coluna === coluna) {
        if (filtro.ordenacao.tipo === "ASC") {
          filtro.ordenacao.tipo = "DESC";
        } else {
          filtro.ordenacao.tipo = "ASC";
        }
      } else {
        filtro.ordenacao.coluna = coluna;
        filtro.ordenacao.tipo = "ASC";
      }
      let tickets = [];

      if (abaAtual === 0) {
        tickets = this.state.ticketsUsuario;
      } else if (abaAtual === 1) {
        tickets = this.state.tickets;
      } else if (abaAtual === 2) {
        tickets = this.state.pendencias;
      } else {
        tickets = this.state.ticketsSuporte;
      }

      if (filtro.ordenacao.tipo === "ASC") {
        if (coluna === "dataEmissao") {
          tickets = tickets.sort(function (a, b) {
            return a.dataEmissao.getTime() - b.dataEmissao.getTime();
          });
        } else {
          tickets.sort(function (a, b) {
            return a[coluna] > b[coluna] ? 1 : b[coluna] > a[coluna] ? -1 : 0;
          });
        }
      } else if (filtro.ordenacao.tipo === "DESC") {
        if (coluna === "dataEmissao") {
          tickets = tickets.sort(function (a, b) {
            return b.dataEmissao.getTime() - a.dataEmissao.getTime();
          });
        } else {
          tickets.sort(function (a, b) {
            return a[coluna] < b[coluna] ? 1 : b[coluna] < a[coluna] ? -1 : 0;
          });
        }
      }

      if (abaAtual === 0) {
        this.setState({ ticketsUsuario: tickets });
      } else if (abaAtual === 1) {
        this.setState({ tickets });
      } else if (abaAtual === 2) {
        this.setState({ pendencias: tickets });
      } else {
        this.setState({ ticketsSuporte: tickets });
      }

      listaFiltro.items[abaAtual] = filtro;
      this.salvarFiltro(listaFiltro);
    }
  }

  async handleInputFiltroChange(e, aba, tipo) {
    let valor = "";
    if (tipo == "cliente" && aba === 6) {
      valor = e.id;
    } else {
      valor = e.target.value;
    }
    await this.filtro(valor, aba, tipo);
  }

  async getTicketsFinalizados() {
    await this.setState({ loading: true });
    await this.limparFiltroSix();
    let inicial = this.state.finalizado.inicial;
    inicial = new Date(new Date(inicial).setHours(0, 0, 0, 0));
    let final = this.state.finalizado.fim;
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/tickets/finalizados/`,
        { inicial, final },
        this.props.parameters()
      )
      .then((response) => {
        let tickets = response.data.tickets;
        this.setState(
          {
            ticketsFinalizados: tickets,
            ticketsPaginados: tickets,
            ticketFinalBkp: tickets,
            loading: false,
          },
          () => this.actionFiltrar()
        );
      });
  }

  async actionFiltrar() {
    let filtro = this.state.filtros;
    let tickets = this.state.ticketFinalBkp.concat();

    if (filtro.pesquisa.codigo !== "") {
      tickets = tickets.filter((ticket) => {
        let ticketComparer = Object.assign({}, ticket);
        let codigo = filtro.pesquisa.codigo;
        if (ticketComparer.codigo.includes(codigo)) {
          return ticket;
        }
      });
    }
    if (filtro.pesquisa.tipo !== "") {
      tickets = tickets.filter(
        (ticket) => ticket.tipo === filtro.pesquisa.tipo
      );
    }

    if (filtro.pesquisa.situacao !== "") {
      tickets = tickets.filter((ticket) => {
        if (ticket.situacaoInt === filtro.pesquisa.situacao) return ticket;
      });
    }

    if (filtro.pesquisa.cliente !== "") {
      tickets = tickets.filter((ticket) => {
        if (ticket.cliente.id == filtro.pesquisa.cliente) return ticket;
      });
    }

    let dataInicial = "";

    if (
      filtro.pesquisa.periodo !== "" &&
      (filtro.pesquisa.periodo !== "null") & (filtro.pesquisa.periodo !== null)
    ) {
      if (filtro.pesquisa.periodo == 0) {
        // MES ATUAL

        let date = new Date();
        dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);

        tickets = tickets.filter((ticket) => {
          if (ticket.dataFinalizacao !== null) {
            if (moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial)) {
              return ticket;
            }
          } else {
            if (moment(ticket.dataCriacao).isSameOrAfter(dataInicial)) {
              return ticket;
            }
          }
        });
      } else if (filtro.pesquisa.periodo == 1) {
        // MES PASSADO
        // dataInicial = moment().subtract(1, "months").format("YYYY-MM") + "-01";
        let date = new Date();
        dataInicial = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        const lastday = function (y, m) {
          return new Date(y, m + 1, 0);
        };

        let dataFinal = lastday(
          dataInicial.getFullYear(),
          dataInicial.getMonth()
        );

        tickets = tickets.filter((ticket) => {
          if (ticket.situacao === "Finalizado") {
            if (
              moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial) &&
              moment(ticket.dataFinalizacao).isSameOrBefore(dataFinal)
            ) {
              return ticket;
            }
          }
        });
      } else if (filtro.pesquisa.periodo == 2) {
        // ULTIMOS 30 DIAS
        dataInicial = moment().subtract(30, "days").format("YYYY-MM-DD");

        tickets = tickets.filter((ticket) => {
          if (ticket.dataFinalizacao !== null) {
            if (moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial)) {
              return ticket;
            }
          } else {
            if (moment(ticket.dataCriacao).isSameOrAfter(dataInicial)) {
              return ticket;
            }
          }
        });
      }
    }

    let ticketsFinalizados = tickets;
    // ticketsFinalizados = ticketsFinalizados.sort(function (a, b) { return a.dataFinalizacao.getTime() - b.dataFinalizacao.getTime() });
    let ticketsOrdenados = ticketsFinalizados;
    this.setState({ ticketsFinalizados: ticketsOrdenados }, () =>
      this.paginar(1)
    );
  }

  async filtro(value, aba, tipo) {
    return new Promise((resolve, reject) => {
      let lc = value;
      if (aba === 6) {
        let filtros = this.state.filtros;
        filtros.pesquisa[tipo] =
          tipo === "codigo" ? lc.toString().toUpperCase() : lc;
        this.setState({ filtros }, () => this.actionFiltrar());
      } else if (aba != 6) {
        let objFiltro = this.state.filtro;

        aba = parseInt(aba) - 1;

        let filtro = objFiltro.items[aba];

        if (tipo !== null && aba !== 6) {
          filtro.pesquisa[tipo] =
            tipo === "codigo" ? value.toString().toUpperCase() : value;
          objFiltro.items[aba] = filtro;
        }

        let tickets = [];

        if (aba === 0) {
          tickets = this.state.ticketsUsuarioBkp;
        } else if (aba === 1) {
          tickets = this.state.ticketsBkp;
        } else if (aba === 2) {
          tickets = this.state.pendenciasBkp;
        } else {
          tickets = this.state.ticketsSuporteBkp;
        }

        if (filtro.pesquisa.codigo !== "") {
          tickets = tickets.filter((ticket) => {
            let ticketComparer = Object.assign({}, ticket);
            let codigo = filtro.pesquisa.codigo;
            if (ticketComparer.codigo.includes(codigo)) {
              return ticket;
            }
          });
        }

        if (filtro.pesquisa.cliente !== "") {
          tickets = tickets.filter((ticket) => {
            let ticketComparer = Object.assign({}, ticket);
            ticketComparer.clienteDescricao =
              ticketComparer.clienteDescricao.toUpperCase();
            let cliente = Object.assign(
              {},
              filtro.pesquisa
            ).cliente.toUpperCase();
            if (ticketComparer.clienteDescricao.includes(cliente)) {
              return ticket;
            }
          });
        }

        if (filtro.pesquisa.tipo !== "") {
          tickets = tickets.filter(
            (ticket) => ticket.tipo === filtro.pesquisa.tipo
          );
        }

        if (filtro.pesquisa.usuario !== "") {
          tickets = tickets.filter((ticket) => {
            if (ticket.usuario) {
              let cliente = Object.assign({}, filtro.pesquisa).usuario;
              if (ticket.usuario.descricao.includes(cliente)) {
                return ticket;
              }
            }
          });
        }

        if (aba === 0) {
          let exibirMensagemMeusTickets = false;

          if (tickets.length === 0) {
            exibirMensagemMeusTickets = true;
          }

          this.setState(
            { ticketsUsuario: tickets, exibirMensagemMeusTickets },
            () => {
              if (tipo !== null) {
                this.salvarFiltro(objFiltro);
              }
              resolve();
            }
          );
        } else if (aba === 1) {
          let exibirMensagemTickets = false;

          if (tickets.length === 0) {
            exibirMensagemTickets = true;
          }

          this.setState({ tickets: tickets, exibirMensagemTickets }, () => {
            if (tipo !== null) {
              this.salvarFiltro(objFiltro);
            }
            resolve();
          });
        } else if (aba === 2) {
          let exibirMensagemPendencias = false;

          if (tickets.length === 0) {
            exibirMensagemPendencias = true;
          }

          this.setState(
            { pendencias: tickets, exibirMensagemPendencias },
            () => {
              if (tipo !== null) {
                this.salvarFiltro(objFiltro);
              }
              resolve();
            }
          );
        } else {
          let exibirMensagemTicketsSuporte = false;

          if (tickets.length === 0) {
            exibirMensagemTicketsSuporte = true;
          }

          this.setState(
            { ticketsSuporte: tickets, exibirMensagemTicketsSuporte },
            () => {
              if (tipo !== null) {
                this.salvarFiltro(objFiltro);
              }
              resolve();
            }
          );
        }
      }
    });
  }

  async limparFiltro(tab) {
    let filtro = this.state.filtro;
    let filtroAba = filtro.items[tab];
    filtroAba.pesquisa.cliente = "";
    filtroAba.pesquisa.codigo = "";
    filtroAba.pesquisa.tipo = "";
    filtroAba.pesquisa.usuario = "";
    filtro.items[tab] = filtroAba;
    this.salvarFiltro(filtro);
    await this.filtro(null, this.state.filtro.abaAtiva, null);
  }

  async limparFiltroSix() {
    let filtros = this.state.filtros;
    filtros.pesquisa.codigo = "";
    filtros.pesquisa.tipo = "";
    filtros.pesquisa.periodo = 0;
    filtros.pesquisa.cliente = "";
    this.setState({
      filtros,
      cliente: null,
      ticketsFinalizados: [],
      ticketsPaginados: [],
      paginacao: {
        pagina: 1,
        limite: 15,
        totalItens: 0,
      },
    });
  }

  async getTicketsContestados() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/tickets/contestacoes`,
        this.props.parameters()
      )
      .then((response) => {
        let tickets = response.data.tickets;

        for (let ticket of tickets) {
          if (ticket.assunto.length > 100) {
            ticket.assuntoResumido = ticket.assunto.substr(0, 100) + "...";
          } else {
            ticket.assuntoResumido = ticket.assunto;
          }
          if (ticket.cliente.descricao.length > 18) {
            ticket.cliente.descricao =
              ticket.cliente.descricao.substr(0, 18) + "...";
          } else {
            ticket.cliente.descricao = ticket.cliente.descricao;
          }
          if (ticket.contestacao.mensagem.length > 100) {
            ticket.contestacao.mensagemResumida =
              ticket.contestacao.mensagem.substr(0, 100) + "...";
          } else {
            ticket.contestacao.mensagemResumida = ticket.contestacao.mensagem;
          }
        }

        let exibirMensagemContestacoes = false;

        if (tickets.length === 0) {
          exibirMensagemContestacoes = true;
        }

        this.setState({
          ticketsContestados: tickets,
          exibirMensagemContestacoes,
          loadingContestacoes: false,
        });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  toggleModalContestacao() {
    this.setState((prevState) => ({
      modalContestacao: !prevState.modalContestacao,
    }));
  }

  onClickBotaoAvaliarContestacao(ticket) {
    let mensagemContestacao = ticket.contestacao.mensagem;
    mensagemContestacao = mensagemContestacao.replace(
      /(?:\r\n|\r|\n)/g,
      "<br />"
    );
    let errors = this.state.errors;
    errors.mensagemContestacao = false;
    this.setState(
      {
        mensagemContestacao,
        idTicketContestacao: ticket._id,
        codigoTicketConstestacao: ticket.codigo,
        clienteContestacao: ticket.cliente.descricao,
        errors,
        mensagemAvaliacao: "",
      },
      () => this.toggleModalContestacao()
    );
  }

  async loadClients() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/clientes`, this.props.parameters())
      .then((response) => {
        let clientes = response.data.clientes;
        clientes = clientes.map((cliente) => {
          cliente.descricao = cliente.fantasia;
          cliente.value = cliente.descricao;
          cliente.label = cliente.descricao;
          return cliente;
        });
        this.setState({ clientes: clientes });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeMensagemAvaliacao(e) {
    this.setState({ mensagemAvaliacao: e.target.value });
  }

  async avaliarContestacao({ aceita, toast }) {
    let mensagem = this.state.mensagemAvaliacao;
    let errors = this.state.errors;

    let aceitarContestacao = aceita;
    let mensagemAnalise = this.state.mensagemAvaliacao;
    let idTicket = this.state.idTicketContestacao;

    const bodySend = {
      aceitarContestacao,
      mensagemAnalise,
      idTicket,
    };

    const toastProps = {
      text: "Contestação analisada",
      actionText: "Fechar",
      ariaLabel: "Contestação analisada com sucesso, clique para fechar",
    };

    if (aceita) {
      errors.mensagemContestacao = false;
    } else {
      if (mensagem.length === 0) {
        errors.mensagemContestacao = true;
        this.setState({ errors });
        return false;
      } else {
        errors.mensagemContestacao = false;
      }
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/tickets/contestacoes/analisar`,
        bodySend,
        this.props.parameters()
      )
      .then(async (response) => {
        this.getTicketsContestados();
        toast.show({ ...toastProps, onActionClick: toast.hide });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));

    this.toggleModalContestacao();
    this.setState({
      mensagemAvaliacao: "",
      errors,
      mensagemContestacao: "",
      idTicketContestacao: null,
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  paginar(pagina = 1) {
    const LIMIT = this.state.paginacao.limite;
    let tickets = this.state.ticketsFinalizados.concat();
    pagina = pagina - 1;
    tickets = tickets.slice(pagina * LIMIT, (pagina + 1) * LIMIT);
    pagina = pagina + 1;

    let paginacao = this.state.paginacao;
    paginacao.pagina = pagina;
    paginacao.totalItens = this.state.ticketsFinalizados.length;
    this.setState({ ticketsPaginados: tickets, paginacao });
  }

  async loadPreTickets() {
    const user = JSON.parse(this.props.user());

    const parametrosUsuario = {
      analisaPreTicketSuporte: user.analisaPreTicketSuporte, 
      analisaPreTicketConsultoria: user.analisaPreTicketConsultoria, 
      analisaPreTicketDesenvolvimento: user.analisaPreTicketDesenvolvimento, 
    }

    const parametrosBusca = this.state.filtroPreTickets;

    await axios
      .post(`${this.props.getBaseUrlApi()}/pre-tickets`, { parametrosUsuario, parametrosBusca }, this.props.parameters())
      .then((response) => {
        let tickets = response.data.tickets;

        for (let ticket of tickets) {

          if(ticket.situacaoPortalCliente === 'Pendente'){
            ticket.situacaoClass = 'pendente';
          }

          if(ticket.situacaoPortalCliente === 'Em análise'){
            ticket.situacaoClass = 'emAnalise';
          }

          if(ticket.situacaoPortalCliente === 'Cancelado'){
            ticket.situacaoClass = 'cancelado';
          }

          ticket.clienteDescricao = ticket.cliente.descricao;
          ticket.usuarioDescricao = ticket.usuario
            ? ticket.usuario.descricao.split(" ")[0]
            : "NÃO VINCULADO";

          if (ticket.assunto.length > 100) {
            ticket.assuntoResumido = ticket.assunto.substr(0, 100) + "...";
          } else {
            ticket.assuntoResumido = ticket.assunto;
          }
          if (ticket.clienteDescricao.length > 18) {
            ticket.clienteDescricaoResumida =
              ticket.clienteDescricao.substr(0, 18) + "...";
          } else {
            ticket.clienteDescricaoResumida = ticket.clienteDescricao;
          }

          ticket.dataEmissao = new Date(ticket.dataCriacao);
          ticket.horaCriacao = moment(ticket.dataCriacao).format("HH:mm");
          ticket.dataCriacao = new Date(
            ticket.dataCriacao
          ).toLocaleDateString();
        }

        this.setState({preTickets: tickets, executouLoadPreTickets: true})

      }).catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  limparFiltroPreTickets(){
    this.setState({filtroPreTickets: {
      cliente: "",
      tipoPortal: "Todos",
      situacao: "Todos"
    }}, () => {
      this.loadPreTickets();
    })
  }

  handleChangeInputFiltroPreTickets(value, type){
    let filtroPreTickets = this.state.filtroPreTickets;
    filtroPreTickets[type] = value;
    this.setState({filtroPreTickets}) 
  }

  render() {
    return (
      <React.Fragment>
        <div id="contentSite">
          <div className="container">
            <div className="Example_box">
              <div className="Example_box-content">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "1" }}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      Minhas Atividades
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "2" }}
                      onClick={() => {
                        this.toggleTab("2");
                      }}
                    >
                      Tickets
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "3" }}
                      onClick={() => {
                        this.toggleTab("3");
                      }}
                    >
                      Pendências
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "4" }}
                      onClick={() => {
                        this.toggleTab("4");
                      }}
                    >
                      Suporte
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "5" }}
                      onClick={() => {
                        this.toggleTab("5");
                      }}
                    >
                      Contestações
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "6" }}
                      onClick={() => {
                        this.toggleTab("6");
                      }}
                    >
                      Finalizados
                    </NavLink>
                  </NavItem>
                  {this.state.exibirAbaPreTickets && (
                    <NavItem>
                      <NavLink
                        className={{ active: this.state.activeTab === "7" }}
                        onClick={() => {
                          this.toggleTab("7");
                        }}
                      >
                        Tickets Portal Cliente
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {this.state.filtro !== null && (
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Código
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            value={this.state.filtro.items[0].pesquisa.codigo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "1", "codigo")
                            }
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Cliente
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            id="titulo"
                            name="titulo"
                            value={this.state.filtro.items[0].pesquisa.cliente}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "1", "cliente")
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Tipo
                          </label>
                          <select
                            className="form-control input-sm input-filter"
                            id="tipo"
                            name="tipo"
                            value={this.state.filtro.items[0].pesquisa.tipo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "1", "tipo")
                            }
                          >
                            <option value="">Todos</option>
                            <option value="Suporte">Suporte</option>
                            <option value="Atendimento Presencial">
                              Atendimento Presencial
                            </option>
                            <option value="Atendimento Remoto">
                              Atendimento Remoto
                            </option>
                            <option value="Atendimento Não Franqueado">
                              Atendimento Não Franqueado
                            </option>
                            <option value="Atendimento Horário Especial">
                              Atendimento Horário Especial
                            </option>
                            <option value="Desenvolvimento Sob Medida">
                              Desenvolvimento Sob Medida
                            </option>
                            <option value="Pendência">Pendência</option>
                          </select>
                        </div>

                        <div
                          className="form-group col-md-3"
                          style={{ marginTop: "26px", padding: "0" }}
                        >
                          <button
                            className="btn btn-secondary btn-sm"
                            id="btn-eraser-meus-tickets"
                            style={{ padding: ".275rem .55rem" }}
                            onClick={() => this.limparFiltro(0)}
                          >
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="right"
                            target="btn-eraser-meus-tickets"
                          >
                            Limpar filtro
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    )}

                    {this.state.loading && (
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px" }}
                      >
                        <div className="container-loading">
                          <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                            <div class="ldio-jz4kbaf040e">
                              <div></div>
                              <div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </table>
                    )}

                    {this.state.exibirMensagemMeusTickets && (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        Nenhum ticket neste momento.
                      </div>
                    )}

                    {!this.state.exibirMensagemMeusTickets &&
                      !this.state.loading && (
                        <table
                          className="table table-hover table-striped"
                          style={{ fontSize: "14px" }}
                        >
                          <thead className="thead-dark-custom thead-site">
                            <tr>
                              <th
                                style={{ maxWidth: "115px" }}
                                onClick={() => this.ordenar("codigo")}
                              >
                                Código
                              </th>
                              <th
                                style={{ maxWidth: "120px" }}
                                onClick={() => this.ordenar("situacao")}
                              >
                                Situação
                              </th>
                              <th
                                style={{ maxWidth: "270px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                Cliente
                              </th>
                              <th
                                style={{ maxWidth: "200px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                data Agenda
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Assunto
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Tipo
                              </th>
                              <th
                                style={{
                                  maxWidth: "150px",
                                  textAlign: "center",
                                }}
                                onClick={() => this.ordenar("dataEmissao")}
                              >
                                Emissão
                              </th>

                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ticketsUsuario.length > 0 &&
                              !this.state.exibirMensagemMeusTickets &&
                              this.state.ticketsUsuario.map((ticket, key) => (
                                <tr key={key}>
                                  <th scope="row" style={{ maxWidth: "115px" }}>
                                    {ticket.codigo}
                                  </th>
                                  <td
                                    style={{
                                      maxWidth: "120px",
                                      minWidth: "110px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        width: "100%",
                                        fontWeight: "bold",
                                        maxWidth: "150px",
                                      }}
                                    >
                                      <span
                                        className={
                                          "tag " + ticket.situacaoClass
                                        }
                                      >
                                        {ticket.situacao}
                                      </span>
                                    </span>
                                  </td>
                                  <td style={{ maxWidth: "270px" }}>
                                    <span
                                      id={"tooltip-descCliente" + ticket._id}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {ticket.clienteDescricaoResumida}
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target={
                                        "tooltip-descCliente" + ticket._id
                                      }
                                    >
                                      {ticket.contato.descricao} -{" "}
                                      {ticket.contato.fone}
                                    </UncontrolledTooltip>
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: "200px",
                                      align: "center",
                                    }}
                                  >
                                    {ticket.dataAgenda === null
                                      ? "-"
                                      : moment(ticket.dataAgenda)
                                          .utcOffset("-03:00")
                                          .format("DD/MM/YYYY HH:mm")}
                                  </td>
                                  <td style={{ maxWidth: "480px" }}>
                                    {ticket.assuntoResumido}
                                  </td>
                                  <td style={{ maxWidth: "150px" }}>
                                    {ticket.tipo}
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: "150px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {ticket.dataCriacao} <br />
                                    {ticket.horaCriacao}
                                  </td>

                                  <td>
                                    <Link to={`/tickets/detail/${ticket._id}`}>
                                      <Button
                                        color="secondary"
                                        id={"tooltip-" + ticket._id}
                                        style={{ padding: ".275rem .55rem" }}
                                      >
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target={"tooltip-" + ticket._id}
                                      >
                                        Visualizar Ticket
                                      </UncontrolledTooltip>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                  </TabPane>

                  <TabPane tabId="2">
                    {this.state.filtro !== null && (
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Código
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            value={this.state.filtro.items[1].pesquisa.codigo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "2", "codigo")
                            }
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Cliente
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            id="titulo"
                            name="titulo"
                            value={this.state.filtro.items[1].pesquisa.cliente}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "2", "cliente")
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Tipo
                          </label>
                          <select
                            className="form-control input-sm input-filter"
                            id="tipo"
                            name="tipo"
                            value={this.state.filtro.items[1].pesquisa.tipo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "2", "tipo")
                            }
                          >
                            <option value="">Selecione</option>
                            <option value="Suporte">Suporte</option>
                            <option value="Atendimento Presencial">
                              Atendimento Presencial
                            </option>
                            <option value="Atendimento Remoto">
                              Atendimento Remoto
                            </option>
                            <option value="Atendimento Não Franqueado">
                              Atendimento Não Franqueado
                            </option>
                            <option value="Atendimento Horário Especial">
                              Atendimento Horário Especial
                            </option>
                            <option value="Desenvolvimento Sob Medida">
                              Desenvolvimento Sob Medida
                            </option>
                          </select>
                        </div>

                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Usuário
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            id="titulo"
                            name="titulo"
                            value={this.state.filtro.items[1].pesquisa.usuario}
                            onChange={(e) => {
                              e.target.value = e.target.value.toUpperCase();
                              this.handleInputFiltroChange(e, "2", "usuario");
                            }}
                          />
                        </div>

                        <div
                          className="form-group col-md-3"
                          style={{ marginTop: "26px", padding: "0" }}
                        >
                          <button
                            className="btn btn-secondary btn-sm"
                            id="btn-eraser-todos-tickets"
                            style={{ padding: ".275rem .55rem" }}
                            onClick={() => this.limparFiltro(1)}
                          >
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="right"
                            target="btn-eraser-todos-tickets"
                          >
                            Limpar filtro
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    )}

                    {this.state.loading && (
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px" }}
                      >
                        <div className="container-loading">
                          <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                            <div class="ldio-jz4kbaf040e">
                              <div></div>
                              <div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </table>
                    )}

                    {this.state.exibirMensagemTickets && (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        Nenhum ticket neste momento.
                      </div>
                    )}

                    {!this.state.exibirMensagemTickets &&
                      !this.state.loading && (
                        <table
                          className="table table-hover table-striped"
                          style={{ fontSize: "14px" }}
                        >
                          <thead className="thead-dark-custom thead-site">
                            <tr>
                              <th
                                style={{ maxWidth: "115px" }}
                                onClick={() => this.ordenar("codigo")}
                              >
                                Código
                              </th>
                              <th
                                style={{ maxWidth: "120px" }}
                                onClick={() => this.ordenar("situacao")}
                              >
                                Situação
                              </th>
                              <th
                                style={{ maxWidth: "270px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                Cliente
                              </th>
                              <th
                                style={{ maxWidth: "200px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                data Agenda
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Assunto
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Tipo
                              </th>
                              <th
                                style={{ maxWidth: "150px" }}
                                onClick={() => this.ordenar("usuarioDescricao")}
                              >
                                Usuário
                              </th>
                              <th
                                style={{
                                  maxWidth: "150px",
                                  textAlign: "center",
                                }}
                                onClick={() => this.ordenar("dataEmissao")}
                              >
                                Emissão
                              </th>

                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tickets.length > 0 &&
                              !this.state.exibirMensagemTickets &&
                              this.state.tickets.map((ticket, key) => {
                                return (
                                  <tr key={key}>
                                    <th
                                      scope="row"
                                      style={{ maxWidth: "115px" }}
                                    >
                                      {ticket.codigo}
                                    </th>
                                    <td
                                      style={{
                                        maxWidth: "120px",
                                        minWidth: "110px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          width: "100%",
                                          fontWeight: "bold",
                                          maxWidth: "150px",
                                        }}
                                      >
                                        <span
                                          className={
                                            "tag " + ticket.situacaoClass
                                          }
                                        >
                                          {ticket.situacao}
                                        </span>
                                      </span>
                                    </td>

                                    <td style={{ maxWidth: "270px" }}>
                                      <span
                                        id={
                                          "tooltip-descCliente-geral" +
                                          ticket._id
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {ticket.clienteDescricaoResumida}
                                      </span>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target={
                                          "tooltip-descCliente-geral" +
                                          ticket._id
                                        }
                                      >
                                        {ticket.contato.descricao} -{" "}
                                        {ticket.contato.fone}
                                      </UncontrolledTooltip>
                                    </td>
                                    <td
                                      style={{
                                        maxWidth: "200px",
                                        align: "center",
                                      }}
                                    >
                                      {ticket.dataAgenda === null
                                        ? "-"
                                        : moment(ticket.dataAgenda)
                                            .utcOffset("-03:00")
                                            .format("DD/MM/YYYY HH:mm")}
                                    </td>
                                    <td style={{ maxWidth: "480px" }}>
                                      {ticket.assuntoResumido}
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {ticket.tipo}
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {ticket.usuarioDescricao}
                                    </td>
                                    <td
                                      style={{
                                        maxWidth: "150px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {ticket.dataCriacao} <br />
                                      {ticket.horaCriacao}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/tickets/detail/${ticket._id}`}
                                      >
                                        <Button
                                          color="secondary"
                                          id={"tooltip-" + ticket._id}
                                          style={{ padding: ".275rem .55rem" }}
                                        >
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                        <UncontrolledTooltip
                                          placement="right"
                                          target={"tooltip-" + ticket._id}
                                        >
                                          Visualizar Ticket
                                        </UncontrolledTooltip>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      )}
                  </TabPane>
                  <TabPane tabId="3">
                    {this.state.filtro !== null && (
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Código
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            value={this.state.filtro.items[2].pesquisa.codigo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "3", "codigo")
                            }
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Cliente
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            id="titulo"
                            name="titulo"
                            value={this.state.filtro.items[2].pesquisa.cliente}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "3", "cliente")
                            }
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Usuário
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            id="titulo"
                            name="titulo"
                            value={this.state.filtro.items[2].pesquisa.usuario}
                            onChange={(e) => {
                              e.target.value = e.target.value.toUpperCase();
                              this.handleInputFiltroChange(e, "3", "usuario");
                            }}
                          />
                        </div>

                        <div
                          className="form-group col-md-3"
                          style={{ marginTop: "26px", padding: "0" }}
                        >
                          <button
                            className="btn btn-secondary btn-sm"
                            id="btn-eraser-meus-tickets"
                            style={{ padding: ".275rem .55rem" }}
                            onClick={() => this.limparFiltro(2)}
                          >
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="right"
                            target="btn-eraser-meus-tickets"
                          >
                            Limpar filtro
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    )}

                    {this.state.loading && (
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px" }}
                      >
                        <div className="container-loading">
                          <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                            <div class="ldio-jz4kbaf040e">
                              <div></div>
                              <div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </table>
                    )}

                    {this.state.exibirMensagemPendencias && (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        Nenhum pendência neste momento.
                      </div>
                    )}

                    {!this.state.exibirMensagemPendencias &&
                      !this.state.loading && (
                        <table
                          className="table table-hover table-striped"
                          style={{ fontSize: "14px" }}
                        >
                          <thead className="thead-dark-custom thead-site">
                            <tr>
                              <th
                                style={{ maxWidth: "115px" }}
                                onClick={() => this.ordenar("codigo")}
                              >
                                Código
                              </th>
                              <th
                                style={{ maxWidth: "120px" }}
                                onClick={() => this.ordenar("situacao")}
                              >
                                Situação
                              </th>
                              <th
                                style={{ maxWidth: "270px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                Cliente
                              </th>
                              <th
                                style={{ maxWidth: "270px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                Usuário Vinculado
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Assunto
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Tipo
                              </th>
                              <th
                                style={{ maxWidth: "150px" }}
                                onClick={() => this.ordenar("dataEmissao")}
                              >
                                Emissão
                              </th>

                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.pendencias.length > 0 &&
                              !this.state.exibirMensagemPendencias &&
                              this.state.pendencias.map((ticket, key) => (
                                <tr key={key}>
                                  <th scope="row" style={{ maxWidth: "115px" }}>
                                    {ticket.codigo}
                                  </th>
                                  <td
                                    style={{
                                      maxWidth: "120px",
                                      minWidth: "110px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        width: "100%",
                                        fontWeight: "bold",
                                        maxWidth: "150px",
                                      }}
                                    >
                                      <span
                                        className={
                                          "tag " + ticket.situacaoClass
                                        }
                                      >
                                        {ticket.situacao}
                                      </span>
                                    </span>
                                  </td>
                                  <td style={{ maxWidth: "270px" }}>
                                    <span
                                      id={
                                        "tooltip-descCliente-pendencia" +
                                        ticket._id
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {ticket.clienteDescricaoResumida}
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target={
                                        "tooltip-descCliente-pendencia" +
                                        ticket._id
                                      }
                                    >
                                      {ticket.contato.descricao} -{" "}
                                      {ticket.contato.fone}
                                    </UncontrolledTooltip>
                                  </td>
                                  <td style={{ maxWidth: "270px" }}>
                                    {ticket.usuario.descricao}
                                  </td>
                                  <td style={{ maxWidth: "480px" }}>
                                    {ticket.assuntoResumido}
                                  </td>
                                  <td style={{ maxWidth: "150px" }}>
                                    {ticket.tipo}
                                  </td>
                                  <td style={{ maxWidth: "150px" }}>
                                    {ticket.dataCriacao}
                                  </td>

                                  <td>
                                    <Link to={`/tickets/detail/${ticket._id}`}>
                                      <Button
                                        color="secondary"
                                        id={"tooltip-" + ticket._id}
                                        style={{ padding: ".275rem .55rem" }}
                                      >
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target={"tooltip-" + ticket._id}
                                      >
                                        Visualizar Ticket
                                      </UncontrolledTooltip>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                  </TabPane>

                  <TabPane tabId="4">
                    {this.state.filtro !== null && (
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Código
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            value={this.state.filtro.items[3].pesquisa.codigo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "4", "codigo")
                            }
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Cliente
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            id="titulo"
                            name="titulo"
                            value={this.state.filtro.items[3].pesquisa.cliente}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "4", "cliente")
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Tipo
                          </label>
                          <select
                            className="form-control input-sm input-filter"
                            id="tipo"
                            name="tipo"
                            value={this.state.filtro.items[3].pesquisa.tipo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, "4", "tipo")
                            }
                          >
                            <option value="">Todos</option>
                            <option value="Suporte">Suporte</option>
                            <option value="Atendimento Presencial">
                              Atendimento Presencial
                            </option>
                            <option value="Atendimento Remoto">
                              Atendimento Remoto
                            </option>
                            <option value="Atendimento Não Franqueado">
                              Atendimento Não Franqueado
                            </option>
                            <option value="Atendimento Horário Especial">
                              Atendimento Horário Especial
                            </option>
                            <option value="Desenvolvimento Sob Medida">
                              Desenvolvimento Sob Medida
                            </option>
                            <option value="Pendência">Pendência</option>
                          </select>
                        </div>

                        <div
                          className="form-group col-md-3"
                          style={{ marginTop: "26px", padding: "0" }}
                        >
                          <button
                            className="btn btn-secondary btn-sm"
                            id="btn-eraser-meus-tickets"
                            style={{ padding: ".275rem .55rem" }}
                            onClick={() => this.limparFiltro(3)}
                          >
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="right"
                            target="btn-eraser-meus-tickets"
                          >
                            Limpar filtro
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    )}

                    {this.state.loading && (
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px" }}
                      >
                        <div className="container-loading">
                          <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                            <div class="ldio-jz4kbaf040e">
                              <div></div>
                              <div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </table>
                    )}

                    {this.state.exibirMensagemTicketsSuporte && (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        Nenhum ticket neste momento.
                      </div>
                    )}

                    {!this.state.exibirMensagemTicketsSuporte &&
                      !this.state.loading && (
                        <table
                          className="table table-hover table-striped"
                          style={{ fontSize: "14px" }}
                        >
                          <thead className="thead-dark-custom thead-site">
                            <tr>
                              <th
                                style={{ maxWidth: "115px" }}
                                onClick={() => this.ordenar("codigo")}
                              >
                                Código
                              </th>
                              <th
                                style={{ maxWidth: "120px" }}
                                onClick={() => this.ordenar("situacao")}
                              >
                                Situação
                              </th>
                              <th
                                style={{ maxWidth: "270px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                Cliente
                              </th>
                              <th
                                style={{ maxWidth: "200px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                data Agenda
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Assunto
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Tipo
                              </th>
                              <th
                                style={{
                                  maxWidth: "150px",
                                  textAlign: "center",
                                }}
                                onClick={() => this.ordenar("dataEmissao")}
                              >
                                Emissão
                              </th>

                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ticketsSuporte.length > 0 &&
                              !this.state.exibirMensagemTicketsSuporte &&
                              this.state.ticketsSuporte.map((ticket, key) => (
                                <tr key={key}>
                                  <th scope="row" style={{ maxWidth: "115px" }}>
                                    {ticket.codigo}
                                  </th>
                                  <td
                                    style={{
                                      maxWidth: "120px",
                                      minWidth: "110px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        width: "100%",
                                        fontWeight: "bold",
                                        maxWidth: "150px",
                                      }}
                                    >
                                      <span
                                        className={
                                          "tag " + ticket.situacaoClass
                                        }
                                      >
                                        {ticket.situacao}
                                      </span>
                                    </span>
                                  </td>
                                  <td style={{ maxWidth: "270px" }}>
                                    <span
                                      id={"tooltip-descCliente" + ticket._id}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {ticket.clienteDescricaoResumida}
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target={
                                        "tooltip-descCliente" + ticket._id
                                      }
                                    >
                                      {ticket.contato.descricao} -{" "}
                                      {ticket.contato.fone}
                                    </UncontrolledTooltip>
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: "200px",
                                      align: "center",
                                    }}
                                  >
                                    {ticket.dataAgenda === null
                                      ? "-"
                                      : moment(ticket.dataAgenda)
                                          .utcOffset("-03:00")
                                          .format("DD/MM/YYYY HH:mm")}
                                  </td>
                                  <td style={{ maxWidth: "480px" }}>
                                    {ticket.assuntoResumido}
                                  </td>
                                  <td style={{ maxWidth: "150px" }}>
                                    {ticket.tipo}
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: "150px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {ticket.dataCriacao} <br />
                                    {ticket.horaCriacao}
                                  </td>

                                  <td>
                                    <Link to={`/tickets/detail/${ticket._id}`}>
                                      <Button
                                        color="secondary"
                                        id={"tooltip-" + ticket._id}
                                        style={{ padding: ".275rem .55rem" }}
                                      >
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target={"tooltip-" + ticket._id}
                                      >
                                        Visualizar Ticket
                                      </UncontrolledTooltip>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                  </TabPane>

                  <TabPane tabId="5">
                    {this.state.loadingContestacoes && (
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px", padding: "20px" }}
                      >
                        <div className="container-loading">
                          <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                            <div class="ldio-jz4kbaf040e">
                              <div></div>
                              <div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </table>
                    )}

                    {this.state.exibirMensagemContestacoes && (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "20px",
                        }}
                      >
                        Nenhuma contestacao neste momento.
                      </div>
                    )}

                    {!this.state.exibirMensagemContestacoes &&
                      !this.state.loadingContestacoes && (
                        <table
                          className="table table-hover table-striped"
                          style={{ fontSize: "14px" }}
                        >
                          <thead className="thead-dark-custom thead-site">
                            <tr>
                              <th style={{ maxWidth: "95px" }}>Código</th>
                              <th style={{ maxWidth: "250px", width: "250px" }}>
                                Assunto
                              </th>
                              <th style={{ maxWidth: "270px" }}>Cliente</th>
                              <th style={{ maxWidth: "70px" }}>Frações</th>
                              <th style={{ maxWidth: "250px", width: "250px" }}>
                                Contestação
                              </th>
                              <th style={{ maxWidth: "150px" }}>Emissão</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ticketsContestados.length > 0 &&
                              !this.state.exibirMensagemContestacoes &&
                              this.state.ticketsContestados.map(
                                (ticket, key) => (
                                  <tr key={key}>
                                    <th
                                      scope="row"
                                      style={{ maxWidth: "115px" }}
                                    >
                                      {ticket.codigo}
                                    </th>
                                    <td style={{ maxWidth: "270px" }}>
                                      {ticket.assuntoResumido}
                                    </td>
                                    <td style={{ maxWidth: "270px" }}>
                                      <span
                                        id={
                                          "tooltip-descCliente-contestacao" +
                                          ticket._id
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {ticket.cliente.descricao}
                                      </span>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target={
                                          "tooltip-descCliente-contestacao" +
                                          ticket._id
                                        }
                                      >
                                        {ticket.contato.descricao} -{" "}
                                        {ticket.contato.fone}
                                      </UncontrolledTooltip>
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {ticket.fracoes}
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {ticket.contestacao.mensagemResumida}
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {moment(ticket.contestacao.data)
                                        .utcOffset("-03:00")
                                        .format("DD/MM/YYYY HH:mm")}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/tickets/detail/${ticket._id}`}
                                      >
                                        <Button
                                          color="secondary"
                                          id={
                                            "tooltip-ticketContestacao-" +
                                            ticket._id
                                          }
                                          style={{
                                            padding: ".275rem .55rem",
                                            margin: "2px",
                                          }}
                                        >
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                        <UncontrolledTooltip
                                          placement="right"
                                          target={
                                            "tooltip-ticketContestacao-" +
                                            ticket._id
                                          }
                                        >
                                          Visualizar Ticket
                                        </UncontrolledTooltip>
                                      </Link>
                                      <Button
                                        color="info"
                                        id={
                                          "tooltip-ticketContestacao-analisar-" +
                                          ticket._id
                                        }
                                        style={{
                                          padding: ".275rem .55rem",
                                          margin: "2px",
                                        }}
                                        onClick={() =>
                                          this.onClickBotaoAvaliarContestacao(
                                            ticket
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target={
                                          "tooltip-ticketContestacao-analisar-" +
                                          ticket._id
                                        }
                                      >
                                        Analisar Constestação
                                      </UncontrolledTooltip>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      )}
                    <Modal
                      isOpen={this.state.modalContestacao}
                      toggle={this.toggleModalContestacao}
                      className="modal-bg"
                    >
                      <div
                        className="modal-header modal-footer-bg"
                        toggle={this.toggleModalContestacao}
                        style={{ fontSize: "15px" }}
                      >
                        Avaliar Contestação do Ticket{" "}
                        {this.state.codigoTicketConstestacao}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Fechar"
                          onClick={this.toggleModalContestacao}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>
                      <div
                        className="modal-body"
                        style={{
                          overflowY: "scroll",
                          maxHeight: "220px",
                          fontSize: "15px",
                          minHeight: "350px",
                        }}
                      >
                        <FormGroup>
                          <Label>
                            <b>
                              Motivo da contestação de{" "}
                              {this.state.clienteContestacao}:
                            </b>
                          </Label>
                          <div
                            dangerouslySetInnerHTML={this.createMarkup(
                              this.state.mensagemContestacao
                            )}
                          ></div>
                        </FormGroup>

                        <FormGroup style={{ paddingTop: "10px" }}>
                          <Label>
                            <b>Resposta</b>
                          </Label>
                          <textarea
                            rows={5}
                            className={
                              this.state.errors.mensagemContestacao
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            type="text"
                            value={this.state.mensagemAvaliacao}
                            onChange={this.onChangeMensagemAvaliacao}
                          />

                          <small>
                            Campo obrigatório se a contestação for recusada.
                          </small>

                          {this.state.errors.mensagemContestacao && (
                            <div className="is-invalid-feedback">
                              Por favor digite uma mensagem.
                            </div>
                          )}
                        </FormGroup>
                      </div>

                      <ToastConsumer>
                        {({ show, hide }) => (
                          <ModalFooter className="modal-footer-bg">
                            <Button
                              color="primary"
                              onClick={() =>
                                this.avaliarContestacao({
                                  aceita: true,
                                  toast: { show, hide },
                                })
                              }
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>{" "}
                              Aceitar
                            </Button>

                            <Button
                              color="danger"
                              onClick={() =>
                                this.avaliarContestacao({
                                  aceita: false,
                                  toast: { show, hide },
                                })
                              }
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>{" "}
                              Recusar
                            </Button>
                          </ModalFooter>
                        )}
                      </ToastConsumer>
                    </Modal>
                  </TabPane>
                  <TabPane tabId="6">
                    <div
                      style={{ padding: "15px 30px", fontSize: "14px" }}
                      className="font-tickets"
                    >
                      <div className="row">
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Cliente:
                          </label>
                          <Select
                            defaultValue={this.state.cliente}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, 6, "cliente")
                            }
                            options={this.state.clientes}
                            focus={false}
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Código
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            value={this.state.filtros.pesquisa.codigo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, 6, "codigo")
                            }
                          />
                        </div>

                        <div className="form-group col-md-2 group-tickets noprint">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Tipo
                          </label>
                          <select
                            className="form-control input-sm input-filter"
                            value={this.state.filtros.pesquisa.tipo}
                            onChange={(e) =>
                              this.handleInputFiltroChange(e, 6, "tipo")
                            }
                          >
                            <option value="">Todos</option>
                            <option value="Suporte">Suporte</option>
                            <option value="Atendimento Presencial">
                              Atendimento Presencial
                            </option>
                            <option value="Atendimento Remoto">
                              Atendimento Remoto
                            </option>
                            <option value="Atendimento Não Franqueado">
                              Atendimento Não Franqueado
                            </option>
                            <option value="Atendimento Horário Especial">
                              Atendimento Horário Especial
                            </option>
                            <option value="Desenvolvimento Sob Medida">
                              Desenvolvimento Sob Medida
                            </option>
                          </select>
                        </div>

                        <div className="form-group col-md-4 group-tickets noprint">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Período
                          </label>
                          <div className="col-lg-12 row">
                            <Col sm={5} style={{ padding: 0 }}>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                customInput={<Input />}
                                selected={this.state.finalizado.inicial}
                                onChange={(dt) => {
                                  this.onChangeDate(dt, "inicial");
                                }}
                              />
                            </Col>
                            <Col sm={1} style={{ padding: 3 }}>
                              <Label className="label-form">até</Label>
                            </Col>
                            <Col sm={5} style={{ padding: 0 }}>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                customInput={<Input />}
                                selected={this.state.finalizado.fim}
                                onChange={(dt) => {
                                  this.onChangeDate(dt, "fim");
                                }}
                              />
                            </Col>
                          </div>
                        </div>
                        <div
                          className="form-group col-md-1 noprint"
                          style={{ marginTop: "11px", padding: "0" }}
                        >
                          <button
                            className="btn btn-secondary btn-sm"
                            id="btn-eraser-todos-tickets"
                            style={{
                              padding: ".275rem .55rem",
                              margin: "10px 5px 0px 5px",
                            }}
                            onClick={() => this.getTicketsFinalizados()}
                          >
                            <i className="fa fa-filter" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="right"
                            target="btn-eraser-todos-tickets"
                          >
                            Filtrar
                          </UncontrolledTooltip>

                          <button
                            className="btn btn-danger btn-sm noprint"
                            id="btn-filter-todos-tickets"
                            style={{
                              padding: ".275rem .55rem",
                              margin: "10px 5px 0px 5px",
                            }}
                            onClick={() => this.limparFiltroSix()}
                          >
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="right"
                            target="btn-filter-todos-tickets"
                          >
                            Limpar filtro
                          </UncontrolledTooltip>
                        </div>
                      </div>
                      {this.state.loading && (
                        <table
                          className="table table-hover table-striped"
                          style={{ fontSize: "14px" }}
                        >
                          <div className="container-loading">
                            <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                              <div class="ldio-jz4kbaf040e">
                                <div></div>
                                <div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </table>
                      )}
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px" }}
                      >
                        <thead className="thead-dark-custom thead-site thead-print">
                          <tr>
                            <th style={{ maxWidth: "115px" }}>Código</th>

                            <th style={{ maxWidth: "270px" }}>Tipo</th>
                            <th>Assunto</th>
                            <th style={{ maxWidth: "150px" }}>Atendente</th>
                            <th style={{ maxWidth: "150px" }}>Finalização</th>
                            <th className="noprint">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tickets.length &&
                            this.state.ticketsPaginados.map((ticket, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <tr className="tr-grid">
                                    <th
                                      scope="row"
                                      style={{ maxWidth: "115px" }}
                                    >
                                      {ticket.codigo}
                                    </th>
                                    <td style={{ maxWidth: "270px" }}>
                                      {ticket.tipo}
                                    </td>
                                    <td style={{ maxWidth: "480px" }}>
                                      {ticket.assunto}
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {ticket.usuario.descricao}
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {moment(ticket.dataFinalizacao)
                                        .utcOffset("-03:00")
                                        .format("DD/MM/YYYY HH:mm")}
                                    </td>
                                    <td className="noprint">
                                      <Link
                                        to={`/tickets/detail/${ticket._id}`}
                                      >
                                        <Button
                                          color="secondary"
                                          id={"tooltip-" + ticket._id}
                                          style={{ padding: ".275rem .55rem" }}
                                        >
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                        <UncontrolledTooltip
                                          placement="right"
                                          target={"tooltip-" + ticket._id}
                                        >
                                          Visualizar Ticket
                                        </UncontrolledTooltip>
                                      </Link>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                      <div
                        className="noprint"
                        style={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        {this.state.paginacao.totalItens > 0 && (
                          <div className="paginator">
                            <Pagination
                              prevPageText={<i className="fa fa-angle-left" />}
                              nextPageText={<i className="fa fa-angle-right" />}
                              firstPageText={
                                <i className="fa fa-angle-double-left" />
                              }
                              lastPageText={
                                <i className="fa fa-angle-double-right" />
                              }
                              activePage={this.state.paginacao.pagina}
                              itemsCountPerPage={this.state.paginacao.limite}
                              totalItemsCount={this.state.paginacao.totalItens}
                              onChange={(pagina) => this.paginar(pagina)}
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="7">     {/* KRAUSE */}
                    {this.state.filtro !== null && (
                      <div className="row" style={{ marginTop: "10px" }}>
                        
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Cliente
                          </label>
                          <input
                            type="text"
                            className="form-control input-sm input-filter"
                            id="titulo"
                            name="titulo"
                            value={this.state.filtroPreTickets.cliente}
                            onChange={(e) =>
                              this.handleChangeInputFiltroPreTickets(e.target.value, "cliente")
                            }
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Tipo Portal
                          </label>
                          <select
                            className="form-control input-sm input-filter"
                            id="tipo"
                            name="tipo"
                            value={this.state.filtroPreTickets.tipoPortal}
                            onChange={(e) => this.handleChangeInputFiltroPreTickets(e.target.value, "tipoPortal")}
                          >
                            <option value="Todos">Todos</option>
                            <option value="Suporte">Suporte</option>
                            <option value="Consultoria">Consultoria</option>
                            <option value="Desenvolvimento">Desenvolvimento</option>
                          </select>

                        </div>
                        <div className="form-group col-md-2">
                          <label
                            for="inputCity"
                            className="label-form"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Situação
                          </label>
                          <select
                            className="form-control input-sm input-filter"
                            id="tipo"
                            name="tipo"
                            value={this.state.filtroPreTickets.situacao}
                            onChange={(e) => this.handleChangeInputFiltroPreTickets(e.target.value, "situacao")}
                          >
                            <option value="Todos">Todos</option>
                            <option value="Pendente">Pendente</option>
                            <option value="Em análise">Em análise</option>
                            <option value="Cancelado">Cancelado</option>
                          </select>

                        </div>

                       
                        <div
                          className="form-group col-md-3 d-flex"
                          style={{ marginTop: "24px", padding: "0" }}
                        >
                          <div style={{margin:"3px"}}>

                          <button
                            className="btn btn-secondary btn-sm"
                            id="btn-eraser-todos-tickets"
                            style={{ padding: ".275rem .55rem" }}
                            onClick={() => this.loadPreTickets()}
                          >
                            <i className="fa fa-filter" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="right"
                            target="btn-eraser-todos-tickets"
                          >
                            Filtrar
                          </UncontrolledTooltip>
                          
                          </div>
                          <div style={{margin:"3px"}}>

                          <button
                            className="btn btn-danger btn-sm"
                            id="btn-eraser-todos-tickets"
                            style={{ padding: ".275rem .55rem" }}
                            onClick={() => this.limparFiltroPreTickets()}
                          >
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="right"
                            target="btn-eraser-todos-tickets"
                          >
                            Limpar filtro
                          </UncontrolledTooltip>

                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.loading && (
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px" }}
                      >
                        <div className="container-loading">
                          <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                            <div class="ldio-jz4kbaf040e">
                              <div></div>
                              <div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </table>
                    )}

                    {/* {this.state.exibirMensagemTickets && (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        Nenhum ticket neste momento.
                      </div>
                    )} */}

                     {!this.state.loading && ( 
                        <table
                          className="table table-hover table-striped"
                          style={{ fontSize: "14px" }}
                        >
                          <thead className="thead-dark-custom thead-site">
                            <tr>
                              <th
                                style={{ maxWidth: "115px" }}
                                onClick={() => this.ordenar("codigo")}
                              >
                                Código
                              </th>
                              <th
                                style={{ maxWidth: "120px" }}
                                onClick={() => this.ordenar("situacao")}
                              >
                                Situação
                              </th>
                              <th
                                style={{ maxWidth: "270px" }}
                                onClick={() => this.ordenar("clienteDescricao")}
                              >
                                Cliente
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Assunto
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Tipo Portal
                              </th>
                              <th
                                style={{ maxWidth: "150px" }}
                                onClick={() => this.ordenar("usuarioDescricao")}
                              >
                                Usuário
                              </th>
                              <th
                                style={{
                                  maxWidth: "150px",
                                  textAlign: "center",
                                }}
                                onClick={() => this.ordenar("dataEmissao")}
                              >
                                Emissão
                              </th>

                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.preTickets.length > 0 &&
                              this.state.preTickets.map((ticket, key) => {
                                return (
                                  <tr key={key}>
                                    <th
                                      scope="row"
                                      style={{ maxWidth: "115px" }}
                                    >
                                      {ticket.codigo}
                                    </th>
                                    <td
                                      style={{
                                        maxWidth: "120px",
                                        minWidth: "110px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          width: "100%",
                                          fontWeight: "bold",
                                          maxWidth: "150px",
                                        }}
                                      >
                                        <span
                                          className={
                                            "tag " + ticket.situacaoClass
                                          }
                                        >
                                          {ticket.situacaoPortalCliente}
                                        </span>
                                      </span>
                                    </td>

                                    <td style={{ maxWidth: "270px" }}>
                                      <span
                                        id={
                                          "tooltip-descCliente-geral" +
                                          ticket._id
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {ticket.clienteDescricaoResumida}
                                      </span>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target={
                                          "tooltip-descCliente-geral" +
                                          ticket._id
                                        }
                                      >
                                        {ticket.contato.descricao} -{" "}
                                        {ticket.contato.fone}
                                      </UncontrolledTooltip>
                                    </td>
                                    <td style={{ maxWidth: "480px" }}>
                                      {ticket.assuntoResumido}
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {ticket.tipoPortalCliente}
                                    </td>
                                    <td style={{ maxWidth: "150px" }}>
                                      {ticket.usuarioDescricao}
                                    </td>
                                    <td
                                      style={{
                                        maxWidth: "150px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {ticket.dataCriacao} <br />
                                      {ticket.horaCriacao}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/pre-tickets/detail/${ticket._id}`}
                                      >
                                        <Button
                                          color="secondary"
                                          id={"tooltip-" + ticket._id}
                                          style={{ padding: ".275rem .55rem" }}
                                        >
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                        <UncontrolledTooltip
                                          placement="right"
                                          target={"tooltip-" + ticket._id}
                                        >
                                          Visualizar Ticket
                                        </UncontrolledTooltip>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>

)} 

                       {/* )} */}

                        

                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Cronograma;
