import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-rater/lib/react-rater.css';

const ModalAvaliacao = (props) => {
  return (
    <Modal isOpen={props.modalAvaliacao} toggle={props.toggleModalAvaliacao}>
      <ModalHeader toggle={props.toggleModalAvaliacao}>Deseja avaliar o Atendimento</ModalHeader>
      <ModalBody>

        <div>Deseja fazer uma avaliação do atendimento?</div>

      </ModalBody>
      <ModalFooter>
      <Button color="primary" onClick={props.acceptRating}>Sim</Button>
        <Button color="danger" onClick={props.notRating}>Não</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAvaliacao;